import React, { useState } from "react";
import {
  Modal,
  Button,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { neutral, primary } from "../../theme/colors";
import { ReactComponent as DownloadIcon } from "../../assets/attachment/download.svg";
import { useThemeContext } from "../../theme/ThemeContextProvider";
import {
  dropzoneStyle,
  modalBoxStyle,
  modalCardStyle,
} from "../../theme/theme";

const UploadLogoModal = ({ cellData, open, onClose, onSave }) => {
  const { mode } = useThemeContext();
  const { t } = useTranslation();

  const [logos, setLogos] = useState({
    dark: cellData?.dark_logo,
    light: cellData?.light_logo,
  });

  const createBlobUrl = (svgString) => {
    const svgBlob = new Blob([svgString], {
      type: "image/svg+xml;charset=utf-8",
    });
    return URL.createObjectURL(svgBlob);
  };

  const [logoDownloadLinks, setLogoDownloadLinks] = useState({
    dark: cellData?.dark_logo ? createBlobUrl(cellData.dark_logo) : null,
    light: cellData?.light_logo ? createBlobUrl(cellData.light_logo) : null,
  });

  const [isDraggedOver, setIsDraggedOver] = useState({
    dark: false,
    light: false,
  });

  const handleLogoChange = (event, logoType) => {
    const newLogo = event.target.files[0];
    const newLogoUrl = createBlobUrl(newLogo);
    fileToSvgCode(event.target.files[0]).then((newLogo) => {
      setLogos({
        ...logos,
        [logoType]: newLogo,
      });
    });
    setLogoDownloadLinks({
      ...logoDownloadLinks,
      [logoType]: newLogoUrl,
    });
  };

  const handleDragOver = (event, logoType: string) => {
    event.preventDefault();
    setIsDraggedOver({
      ...isDraggedOver,
      [logoType]: true,
    });
  };

  const handleDragLeave = (logoType: string) => {
    setIsDraggedOver({
      ...isDraggedOver,
      [logoType]: false,
    });
  };

  const handleDrop = (event, logoType: string) => {
    event.preventDefault();

    setIsDraggedOver({
      ...isDraggedOver,
      [logoType]: false,
    });
    const newLogo = event.dataTransfer.files[0];
    const newLogoUrl = createBlobUrl(newLogo);
    if (newLogo.type !== "image/svg+xml") {
      alert("File is not an SVG!");
    } else {
      fileToSvgCode(newLogo).then((newLogo) => {
        setLogos({
          ...logos,
          [logoType]: newLogo,
        });
      });
      setLogoDownloadLinks({
        ...logoDownloadLinks,
        [logoType]: newLogoUrl,
      });
    }
  };

  const handleDelete = (logoType: string) => {
    setLogos({
      ...logos,
      [logoType]: null,
    });
  };

  const fileToSvgCode = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        if (event.target) {
          // Resolve with the SVG code
          resolve(event.target.result);
        } else {
          reject(new Error("Event target is null."));
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      // Read the file as text
      reader.readAsText(file);
    });
  }

  const dragDropZone = (logoType: string) => {
    return (
      <>
        <Box
          onDrop={(event) => handleDrop(event, logoType)}
          onDragOver={(event) => handleDragOver(event, logoType)}
          onDragLeave={() => handleDragLeave(logoType)}
          sx={
            isDraggedOver[logoType]
              ? {
                  ...dropzoneStyle,
                  marginTop: "16px",
                  backgroundColor: `${mode === "light" ? primary[50] : primary[900]}`,
                }
              : { ...dropzoneStyle, marginTop: "16px" }
          }
        >
          <input
            type="file"
            id={"fileInput" + logoType}
            accept="image/svg+xml"
            onChange={(event) => {
              handleLogoChange(event, logoType);
            }}
            style={{ display: "none" }}
          />
          <label
            htmlFor={"fileInput" + logoType}
            style={{
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "88px",
              paddingLeft: 2,
              paddingRight: 2,
            }}
          >
            {t("ADMIN.CLIENTS.LOGO_DRAG_AND_DROP")}
            <span
              style={{
                fontWeight: "bold",
                color: primary[500],
                paddingLeft: "5px",
              }}
            >
              {t("ADMIN.CLIENTS.CLICK_TO_ADD")}
            </span>
          </label>
        </Box>
        {logos[logoType] != null && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: `1px solid ${mode === "light" ? neutral[200] : neutral[600]}`,
              borderRadius: "8px",
              padding: "8px 16px 8px 16px",
              width: "100%",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                alignSelf: "end",
              }}
            >
              <Box
                sx={{ display: "inline-flex", flexDirection: "column", ml: 1 }}
              >
                <span dangerouslySetInnerHTML={{ __html: logos[logoType] }} />
              </Box>
            </Box>
            <Box
              justifyContent="space-between"
              sx={{ verticalAlign: "center" }}
            >
              {logos[logoType] && (
                // TODO: Optional - Add better naming for the download (generates random ID)
                <a href={logoDownloadLinks[logoType]} download>
                  <IconButton>
                    <DownloadIcon />
                  </IconButton>
                </a>
              )}
              <IconButton onClick={() => handleDelete(logoType)}>
                <CloseIcon sx={{ width: "20px", height: "20px" }} />
              </IconButton>
            </Box>
          </Box>
        )}
      </>
    );
  };

  const handleSave = async () => {
    try {
      const dark_logo: string = logos.dark;
      const light_logo: string = logos.light;

      // Pass the SVG codes to the onSave function
      onSave({ light_logo, dark_logo });
      onClose();
    } catch (error) {
      console.error("Error Saving Logos:", error);
    }
  };

  const handleClose = () => {
    setLogos({
      dark: cellData?.dark_logo,
      light: cellData?.light_logo,
    });
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalBoxStyle}>
        <Card sx={modalCardStyle}>
          <CardHeader
            sx={{ paddingBottom: "24px" }}
            title={t("TABLE.UPLOAD_LOGOS")}
            action={
              <IconButton onClick={handleClose} aria-label="Close">
                <Close />
              </IconButton>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <Box sx={{ mb: "24px" }}>
              <Typography gutterBottom variant="titleLg">
                {t("TABLE.DARK_LOGO")}
              </Typography>
              {dragDropZone("dark")}
            </Box>
            <Box>
              <Typography gutterBottom variant="titleLg">
                {t("TABLE.LIGHT_LOGO")}
              </Typography>
              {dragDropZone("light")}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 3,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  width: "48%",
                  height: "48px",
                  color: mode === "light" ? neutral[700] : neutral[200],
                }}
                onClick={handleClose}
              >
                {t("GENERAL.CANCEL")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "48%", height: "48px", color: "white" }}
                onClick={handleSave}
                disabled={
                  (logos.dark && !logos.light) || (!logos.dark && logos.light) || (!logos.dark && !logos.light)
                }
              >
                {t("GENERAL.SAVE")}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

export default UploadLogoModal;
