import { FC, useEffect, useState, CSSProperties } from "react";

interface IconComponentProps {
  iconName?: string; // iconName is now optional
  folderPath: string;
  mode: "dark" | "light" | "default";
  extension: "png" | "svg";
  style?: CSSProperties;
}

const IconComponent: FC<IconComponentProps> = ({ iconName, folderPath, mode, extension, style }) => {

  const formatIconName = (name: string) => {
    return name.toLowerCase().replace(/ /g, '_');
  };

  // Provide a default path or use a placeholder if iconName is not provided
  const defaultIconPath = `/images/${folderPath}/default-${mode}.png`; // Adjust the path as needed

  // Initializing state conditionally based on the iconName
  const [icon, setIcon] = useState<string>(
    iconName ? `/images/${folderPath}/${formatIconName(iconName)}-${mode}.${extension}` : defaultIconPath
  );

  useEffect(() => {
    // Only update the icon if iconName is not null or undefined
    if (iconName) {
      setIcon(
        `/images/${folderPath}/${formatIconName(iconName)}-${mode}.${extension}`
      );
    } else {
      // Set to the default icon path or a placeholder
      setIcon(defaultIconPath);
    }
    // eslint-disable-next-line
  }, [mode, iconName, folderPath]);

  // Optionally, you can render nothing if iconName is null or undefined
  if (!iconName) {
    return null; // or return a default placeholder image if you prefer
  }

  return <img style={{...style, maxWidth: '48px'}} src={icon} alt={iconName || 'default icon'} />;
}

export default IconComponent;
