import { useMsal } from "@azure/msal-react";
import { MsalModel } from "./msal.model";

/**
 * Returns an HTTP header object with JWT token and per default "Content-Type"
 */
const useAuthenticatedFetch = () => {
  const { instance } = useMsal();

  const checkTokenExpiration = async (): Promise<string | null> => {
    const ms = localStorage.getItem("ms");
    if (!ms) {
      console.log("Do nothing about token refresh - cause user is not logged in");

      return null;
    }

    await instance.initialize();
    const msal: MsalModel = JSON.parse(ms);
    const currentAccount = instance.getAccountByHomeId(msal.homeAccountId);

    if (currentAccount) {
      // @ts-ignore
      const expiresAt = currentAccount.idTokenClaims.exp * 1000; // Convert expiration time to milliseconds
      const currentTime = Date.now();

      if (expiresAt < currentTime) {
        console.log("Token expired - trying to refresh");
        try {
          const response = await instance.acquireTokenSilent({
            account: currentAccount,
            scopes: [],
          });
          if (response.accessToken) {
            console.log("Access Token refreshed:", response);
            // save updated token in ss
            msal.idToken = response.idToken;
            localStorage.setItem("ms", JSON.stringify(msal));

            return response.idToken;
          }
        } catch (error) {
          console.error("Error refreshing token:", error);

          return null;
        }
      } else {
        // console.log("Access Token is still valid");

        return msal.idToken;
      }
    }

    return null;
  };

  return async (addContentType = true): Promise<Headers> => {
    const headers: Headers = new Headers();
    const accessToken = await checkTokenExpiration();

    if (accessToken) {
      const bearer: string = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
    }

    if (addContentType) {
      headers.append("Content-Type", "application/json");
    }

    return headers;
  };
};

export default useAuthenticatedFetch;
