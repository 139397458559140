import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAuthenticatedFetch from "../../auth/authenticated";
import AdminMailDetails from "./AdminMailDetails";
import AdminMailList from "./AdminMailList";
import {MailSearchResult} from "../../models/MailSearchResult";

const AdminMail = () => {
  const { t } = useTranslation();
  const getAccessHeader = useAuthenticatedFetch();
  // state hooks
  const [noMails, setNoMails] = useState(false);
  const [error, setError] = useState(false);
  const [selectedMailId, setSelectedMailId] = useState<string>();
  const [mailsData, setMailsData] = useState<MailSearchResult[]>([]);
  const [mailsSchema, setMailsSchema] = useState<any>(null);
  const [attachmentSchema, setAttachmentSchema] = useState<any>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  // load mails data and schemas
  useEffect(() => {
    const fetchMailsData = async () => {
      fetch("/api/mails", {
        method: "GET",
        headers: await getAccessHeader(),
      })
        .then((response) => {
          if (!response.ok) {
            console.error("Network response was not ok");
            setError(true);
          }
          return response.json();
        })
        .then((data) => {
          // handle case if 0 emails exist
          if (data.length > 0) {
            setMailsData(data);
            setSelectedMailId(data[0].id);
          } else {
            setNoMails(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching mails data:", error);
          setError(true);
        });
    };

    const fetchMailsSchema = async () => {
      fetch("/api/mails/schema", {
        method: "GET",
        headers: await getAccessHeader(false),
      })
        .then((response) => {
          if (!response.ok) {
            console.error("Network response was not ok");
            setError(true);
          }
          return response.json();
        })
        .then((data) => {
          setMailsSchema(data);
        })
        .catch((error) => {
          console.error("Error fetching mails schema data:", error);
          setError(true);
        });
    };

    const fetchAttachmentsSchema = async () => {
      fetch("/api/attachments/schema", {
        method: "GET",
        headers: await getAccessHeader(false),
      })
        .then((response) => {
          if (!response.ok) {
            console.error("Network response was not ok");
            setError(true);
          }
          return response.json();
        })
        .then((data) => {
          setAttachmentSchema(data);
        })
        .catch((error) => {
          console.error("Error fetching attachment data:", error);
          setError(true);
        });
    };

    fetchMailsData().then();
    fetchMailsSchema().then();
    fetchAttachmentsSchema().then();
    // eslint-disable-next-line
  }, []);

  const handleSelectEmail = (emailId: string) => {
    setSelectedMailId(emailId);
    const newIndex = mailsData.findIndex((mail) => mail.id === emailId);
    setActiveIndex(newIndex);
  };

  const handleDecrement = () => {
    const newIndex = Math.max(activeIndex - 1, 0);
    setSelectedMailId(mailsData[newIndex].id);
    setActiveIndex(newIndex);
  };

  const handleIncrement = () => {
    const newIndex = Math.min(activeIndex + 1, mailsData.length - 1);
    setSelectedMailId(mailsData[newIndex].id);
    setActiveIndex(newIndex);
  };

  /**
   * Callback to update the data model / view.
   * Removes the mail from the list or updates its read status
   * @param updatedValue
   */
  const handleMailDetailChange = (updatedValue: string) => {
    const copyMailsData = [...mailsData];

    if (updatedValue === "Spam") {
      copyMailsData.splice(activeIndex, 1);
      setMailsData(copyMailsData);

      // case: active email in list is the last one that got marked as spam
      let idx = activeIndex;
      if (idx === copyMailsData.length) {
        setActiveIndex(--idx);
      }
      setSelectedMailId(copyMailsData[idx].id);
    } else {
      copyMailsData[activeIndex].read_status = updatedValue;
      setMailsData(copyMailsData);
    }
  };

  return error ? (
    <Box sx={{ mt: "100px", textAlign: "center" }}>{t("GENERAL.ERROR")}</Box>
  ) : selectedMailId && mailsSchema && attachmentSchema && mailsData.length > 0 ? (
    <Box sx={{ display: "flex" }}>
      <AdminMailList
        mailsData={mailsData}
        selectedRowId={selectedMailId}
        onSelectRow={handleSelectEmail}
      />
      <AdminMailDetails
        mailId={selectedMailId}
        mailsSchema={mailsSchema}
        attachmentSchema={attachmentSchema}
        handleMailDetailChange={handleMailDetailChange}
        onDecrement={handleDecrement}
        onIncrement={handleIncrement}
        totalEmails={mailsData.length}
        activeIndex={activeIndex}
      />
    </Box>
  ) : noMails ? (
    <Box sx={{ mt: "100px", textAlign: "center" }}>{t("GENERAL.NO_DATA")}</Box>
  ) : (
    <Box sx={{ height: "100%", width: "100%", mt: "100px", textAlign: "center" }}>
      <CircularProgress />
    </Box>
  );
};

export default AdminMail;
