import {Chip, styled} from "@mui/material";
import {warning, success, neutral, primary, danger } from "../../../theme/colors";
import {typography} from "../../../theme/typography";

interface ChipProps {
    type: string;
    mode: string;
}

export const MailFromChip = styled(Chip)<ChipProps>(({ type, mode }) => {

    let backgroundColor: string = '#fff';
    let color: string = '#000';

    const colorPicker = (themeColor: any) => {
        backgroundColor = mode === 'light' ? themeColor[100] : themeColor[800]
        color = mode === 'light' ? themeColor[700] : themeColor[200]
    }

    switch (type) {
        case 'CARRIER':
            colorPicker(warning);
            break;
        case 'CLIENT':
            colorPicker(success);
            break;
        case 'SYSTEM':
            colorPicker(neutral);
            break;
        case 'OPERATOR':
            colorPicker(primary);
            break;
        case 'WAREHOUSE':
            colorPicker(danger);
            break;
        case 'ADMIN': {
            backgroundColor = neutral[700];
            color = neutral[100];
            break;
        }

    }

    return {
        backgroundColor,
        ...typography.bodyXs,
        marginBottom: '6px',
        '& .MuiChip-label': {
            color
        }
    };
});
