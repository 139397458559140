import { Card, Typography } from "@mui/material";
import {styled} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import React from "react";
import {primary} from "../../../theme/colors";
import {useThemeContext} from "../../../theme/ThemeContextProvider";
import {ReactComponent as AcceptedIcon} from '../../../assets/claim/accepted.svg';
import {ReactComponent as DeliveredIcon} from '../../../assets/claim/delivered.svg';
import {ReactComponent as FoundIcon} from '../../../assets/claim/found.svg';
import {ReactComponent as RejectedIcon} from '../../../assets/claim/rejected.svg';
import {ReactComponent as StoppedIcon} from '../../../assets/claim/stopped.svg';
import {ReactComponent as AcceptedDarkIcon} from '../../../assets/claim/acceptedDark.svg';
import {ReactComponent as DeliveredDarkIcon} from '../../../assets/claim/deliveredDark.svg';
import {ReactComponent as FoundDarkIcon} from '../../../assets/claim/foundDark.svg';
import {ReactComponent as RejectedDarkIcon} from '../../../assets/claim/rejectedDark.svg';
import {ReactComponent as StoppedDarkIcon} from '../../../assets/claim/stoppedDark.svg';
import {ReactComponent as RadioDefaultIcon} from '../../../assets/claim/radio_default.svg';
import {ReactComponent as RadioSelectedIcon} from '../../../assets/claim/radio_selected.svg';

// MUI Overrides
const CustomCard = styled(Card)({
    display: 'flex',
    flex: 'auto',
    alignItems: 'center',
    marginBottom: '24px',
    padding: '16px 16px',
    cursor: 'pointer',
    boxShadow: 'none',
    width: '267px',
    height: '64px',
    borderRadius: '4px',
})

interface ResolveResolutionCardProps {
    type: string;
    index: number;
    /**
     * Callback for selected type
     * @param type
     */
    onSelected: (type: string) => void;
    isSelected: boolean;
}

const ResolveResolutionCard: React.FC<ResolveResolutionCardProps> = ({type, index, onSelected, isSelected}) => {
    const { t } = useTranslation();
    const {mode} = useThemeContext();

    const handleChange = () => {
        onSelected(type);
    };

    const getIcon = (key: string) => {
        switch(key) {
            case 'ACCEPTED': return mode === 'light' ? <AcceptedIcon/> : <AcceptedDarkIcon/>;
            case 'DELIVERED': return mode === 'light' ? <DeliveredIcon/> : <DeliveredDarkIcon/>;
            case 'FOUND': return mode === 'light' ? <FoundIcon/> : <FoundDarkIcon/>;
            case 'REJECTED': return mode === 'light' ? <RejectedIcon/> : <RejectedDarkIcon/>;
            case 'STOPPED': return mode === 'light' ? <StoppedIcon/> : <StoppedDarkIcon/>;
        }
    }
    return (
            <CustomCard variant="outlined" onClick={() => handleChange()} tabIndex={index}
                        sx={{
                            marginLeft: index % 2 === 1 ? '16px' : '0',
                            backgroundColor: mode === 'light' ? (isSelected ? primary[100] : 'unset')
                                                              : (isSelected ? primary[800] : 'unset'),
                            border: mode === 'light' ? (isSelected ? '2px solid ' + primary[300] : '1px solid rgba(0, 0, 0, 0.12)')
                                                     : (isSelected ? '2px solid ' + primary.dark : '1px solid #636B7429')
                        }}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter')
                                handleChange();
                        }}>
                {getIcon(type)}
                <Typography variant="titleLg" sx={{paddingLeft: '16px', marginRight: 'auto'}}>{t("RESOLVE_MODAL." + type)}</Typography>
                {isSelected ? <RadioSelectedIcon style={{width: '24px', height: '24px'}}></RadioSelectedIcon> : <RadioDefaultIcon style={{width: '24px', height: '24px'}}></RadioDefaultIcon>}
            </CustomCard>
    );

}

export default ResolveResolutionCard;
