// ----------------------------------------------------------------------

// SETUP COLORS

export const primary = {
    50: '#F1FAFE',
    100: '#E3F2FB',
    200: '#C0E7F7',
    300: '#89D4F0',
    400: '#47BCE5',
    500: '#23A5D4',
    600: '#1586B4',
    700: '#126B92',
    800: '#135A79',
    900: '#154B65',
    950: '#0E3143',
    light: '#E3F2FB',
    main: '#23A5D4',
    dark: '#126B92'
};

export const neutral = {
    50: '#F5F6F6',
    100: '#E5E7E8',
    200: '#CDD0D4',
    300: '#AAB0B6',
    400: '#9FA6AD',
    500: '#636B74',
    600: '#565C64',
    700: '#4A4E54',
    800: '#414449',
    900: '#393B40',
    950: '#232529',
}

export const danger = {
    50: '#FFF0F3',
    100: '#FFDEE4',
    200: '#FFC2CE',
    300: '#FF98AB',
    400: '#FF5C7B',
    500: '#FF2A52',
    600: '#F80A37',
    700: '#D1042B',
    800: '#AC0827',
    900: '#8E0E26',
    950: '#4E0110',
}

export const secondary = {
    light: '#E5E7E8',
    main: '#636B74',
    dark: '#4A4E54'
};

export const success = {
    50: '#F1FCFB',
    100: '#CFF8F3',
    200: '#9EF1E9',
    300: '#66E2DA',
    400: '#33C2BE',
    500: '#1DAFAD',
    600: '#158A8C',
    700: '#156E70',
    800: '#15585A',
    900: '#164A4B',
    950: '#062A2D',
    light: '#CFF8F3',
    main: '#1DAFAD',
    dark: '#156E70'
};

export const warning = {
    50: '#FDF7ED',
    100: '#F9E8CC',
    200: '#F3CE94',
    300: '#EDB05C',
    400: '#EA9A3E',
    500: '#E2751E',
    600: '#C75518',
    700: '#A63A17',
    800: '#872F19',
    900: '#6F2718',
    950: '#401208',
    light: '#F9E8CC',
    main: '#E2751E',
    dark: '#A63A17'
};

export const error = {
    light: '#FFDEE4',
    main: '#FF2A52',
    dark: '#D1042B'
};

export const common = {
    white: '#FFFFFF',
    black: '#000000'
}
