import { Box } from "@mui/material";
import SearchMailList from "./SearchMailList";
import SearchMailContent from "./SearchMailContent";
import React, { useEffect, useState } from "react";
import { MailSearchResult}  from "../../models/MailSearchResult";
import useAuthenticatedFetch from "../../auth/authenticated";
import { useTranslation } from "react-i18next";
import { ActionMail } from "../../models/Action";

interface SearchMailResultProps {
  mailsData: MailSearchResult[];
}

const SearchMailResult: React.FC<SearchMailResultProps> = ({ mailsData }) => {
  const { t } = useTranslation();
  const getAccessHeader = useAuthenticatedFetch();

  const [selectedMailId, setSelectedMailId] = useState<string>(mailsData[0].id);
  const [mailDetails, setMailDetails] = useState<ActionMail>();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState(false);

  // load mail data when active mail changes via click on mail in list
  useEffect(() => {
    const fetchMailDetails = async () => {
      // reset feedbacks
      setFetchError(false);
      setIsLoadingData(true);

      const response = await fetch(`/api/mails/${selectedMailId}`, {
        method: "GET",
        headers: await getAccessHeader(false),
      });

      if (!response.ok) {
        console.error("Network response was not ok");
        setFetchError(true);
        setIsLoadingData(false);
      } else {
        const data = await response.json();
        setMailDetails(data);
        setIsLoadingData(false);
      }
    };

    if (selectedMailId) {
      fetchMailDetails().then();
    }
    // eslint-disable-next-line
  }, [selectedMailId]);

  /**
   * Callback for row selection from List
   * @param email
   */
  const handleSelectEmail = (email: MailSearchResult) => {
    setSelectedMailId(email.id);
  };

  return (
    <Box sx={{ display: "flex" }}>
      {mailsData && selectedMailId && (
        <SearchMailList
          mailsData={mailsData}
          selectedRowId={selectedMailId}
          onSelectRow={handleSelectEmail}
        ></SearchMailList>
      )}

      {fetchError && (
        <Box sx={{ mt: "100px", textAlign: "center", width: "100%" }}>
          {t("GENERAL.ERROR")}
        </Box>
      )}

      {mailDetails && (
        <SearchMailContent
          mailDetails={mailDetails}
          isLoadingData={isLoadingData}
        ></SearchMailContent>
      )}
    </Box>
  );
};

export default SearchMailResult;
