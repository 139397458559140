import {Card, CardContent, Chip, styled, TextField} from '@mui/material';
import {typography} from "../../theme/typography";
import {neutral, primary} from "../../theme/colors";
import {useThemeContext} from "../../theme/ThemeContextProvider";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

interface MailCardProps {
    chips: string[];
    handleChipDelete: (chip: string) => void;
    label: string;
    value: string;
    onChange: (value: string) => void;
    onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
    error: boolean;
    field: 'to' | 'cc'; // New field prop to specify whether it's for "to" or "cc"
}

const MailCard: React.FC<MailCardProps> = ({
                                               chips,
                                               handleChipDelete,
                                               label,
                                               value,
                                               onChange,
                                               onKeyDown,
                                               error,
                                               field,
                                           }) => {

    const {mode} = useThemeContext();

    const MailChip = styled(Chip)({
        backgroundColor: mode === "light" ? primary[100] : primary[800],
        border: "none",
        color: mode === "light" ? primary[700] : primary[50],
    });

    return (
        <Card sx={{ mb: 3 }}>
            <CardContent>
                <TextField
                    sx={{
                        '& .MuiInputBase-input': {
                            ...typography.titleMd,
                        },
                        '& .MuiInput-underline:before': {
                            borderBottom: !value ? '1px dashed' : '1px solid',
                            borderBottomColor: mode === 'light' ? neutral[200] : neutral[600]
                        },
                        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                            borderBottomColor: mode === 'light' ? neutral[200] : neutral[600],
                        },
                    }}
                    label={label}
                    variant='standard'
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onKeyDown={onKeyDown}
                    fullWidth
                    error={error}
                    InputProps={{
                        style: {
                            fontWeight: 'bold',
                        },
                    }}
                />
                {chips.map(chip => (
                    <MailChip
                        key={chip}
                        label={chip}
                        onDelete={() => handleChipDelete(chip)}
                        sx={{ marginRight: 1, marginTop: 1 }}
                        deleteIcon={<CloseIcon sx={{ width: "20px", height: "20px", fill: mode === 'light' ? primary[700] : primary[50]}} />}
                    />
                ))}
            </CardContent>
        </Card>
    );
};

export default MailCard;
