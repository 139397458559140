import {
  Box,
  Button,
  Typography,
  Modal,
  Card,
  CardHeader,
  CardContent,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {danger, neutral} from "../../../theme/colors";
import { useThemeContext } from "../../../theme/ThemeContextProvider";
import CloseIcon from "@mui/icons-material/Close";
import {modalBoxStyle, modalCardStyle} from "../../../theme/theme";
import React from "react";

const buttonStyles = {
  width: "267px",
  height: "48px",
  backgroundColor: danger[700],
  color: "white",
  "&:hover": {
    backgroundColor: danger[800],
  }
};

interface AdminMailSpamModalProps {
  open: boolean;
  /**
   * Callback function to handle modal close
   * @param event - Event object from the close event
   */
  onClose: (event?: any) => void;
  /**
   * Callback to parent
   */
  onMarkAsSpam: () => Promise<void>;
}

const AdminMailSpamModal: React.FC<AdminMailSpamModalProps> = ({ open, onClose, onMarkAsSpam }) => {
  const { t } = useTranslation();
  const { mode } = useThemeContext();

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalBoxStyle}>
        <Card sx={modalCardStyle}>
          <CardHeader
            sx={{ paddingBottom: "8px" }}
            title={
              <Typography variant="h5">{t("ADMIN.MAILS.SPAM")}</Typography>
            }
            action={
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            }
          ></CardHeader>

          <CardContent>
            <Typography variant="bodyLg">
              {t("ADMIN.MAILS.SPAM_TEXT")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 4,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  width: "267px",
                  height: "48px",
                  color: mode === "light" ? neutral[700] : neutral[200],
                }}
                onClick={onClose}
              >
                {t("GENERAL.CANCEL")}
              </Button>
              <Button
                variant="contained"
                sx={buttonStyles}
                onClick={onMarkAsSpam}
              >
                {t("ADMIN.MAILS.MARK_AS_SPAM")}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

export default AdminMailSpamModal;
