export enum REJECTION_CODES {
    GENERAL_G01 = 'GENERAL_G01',
    PACKAGING_G02 = 'PACKAGING_G02',
    DEADLINE_G03 = 'DEADLINE_G03',
    CONFIRMED_SAFEPLACE_G04 = 'CONFIRMED_SAFEPLACE_G04',
    DELEVERY_AS_AGREED_G05 = 'DELEVERY_AS_AGREED_G05',
    NO_PICKUP_G06 = 'NO_PICKUP_G06'
}

export const RC_LOST_IN_TRANSIT: REJECTION_CODES[] = [
    REJECTION_CODES.GENERAL_G01,
    REJECTION_CODES.DEADLINE_G03,
    REJECTION_CODES.CONFIRMED_SAFEPLACE_G04,
    REJECTION_CODES.DELEVERY_AS_AGREED_G05,
    REJECTION_CODES.NO_PICKUP_G06
];

export const RC_DELIVERY_DISPUTE: REJECTION_CODES[] = [
    REJECTION_CODES.GENERAL_G01,
    REJECTION_CODES.DEADLINE_G03,
    REJECTION_CODES.CONFIRMED_SAFEPLACE_G04,
    REJECTION_CODES.DELEVERY_AS_AGREED_G05,
];

export const RC_DAMAGED: REJECTION_CODES[] = [
    REJECTION_CODES.GENERAL_G01,
    REJECTION_CODES.PACKAGING_G02,
    REJECTION_CODES.DEADLINE_G03,
    REJECTION_CODES.CONFIRMED_SAFEPLACE_G04,
    REJECTION_CODES.DELEVERY_AS_AGREED_G05,
];
