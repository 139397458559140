import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, CardContent, CardHeader, CircularProgress, IconButton, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css'; // Support for annotations (links) in PDFs
import 'react-pdf/dist/Page/TextLayer.css'; // Support for text layer
import { Rnd } from 'react-rnd';
import { useTranslation } from "react-i18next";
import { neutral } from "../../../theme/colors";
import { useThemeContext } from "../../../theme/ThemeContextProvider";

// For React-PDF to work, PDF.js worker needs to be provided via external CDN
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type AttachmentPreviewProps = {
    attachment: string,
    mimeType: string,
    filename: string,
    onClose: () => void
}

const AttachmentPreviewModal: React.FC<AttachmentPreviewProps> = ({ attachment, mimeType, filename, onClose }) => {
    const { t } = useTranslation();
    const { mode } = useThemeContext();
    const textStyle = { color: mode === 'light' ? neutral[950] : neutral[50] };

    const [size, setSize] = useState({
        width: window.innerWidth * 0.6,
        height: window.innerHeight * 0.9
    });
    const [textContent, setTextContent] = useState<string>('');
    const [numPages, setNumPages] = useState<number | null>(null);

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    }

    useEffect(() => {
        // load text/* mimeType file content
        if (mimeType.startsWith("text/")) {
            fetch(attachment)
                .then(response => response.text())
                .then(data => setTextContent(data))
                .catch(console.error);
        }
        // eslint-disable-next-line
    }, []);

    if (!attachment) return null;

    return (
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor="rgba(0,0,0,0.5)"
        zIndex="modal"
      >
        <Rnd
          default={{
            x: window.innerWidth / 2 - size.width / 2,
            y: window.innerHeight / 2 - size.height / 2,
            width: size.width,
            height: size.height,
          }}
          onResizeStop={(e, direction, ref) => {
            setSize({
              width: parseInt(ref.style.width),
              height: parseInt(ref.style.height),
            });
          }}
          minWidth={window.innerWidth * 0.3}
          minHeight={window.innerHeight * 0.3}
        >
          <Card
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardHeader
              action={
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              }
              title={
                <Typography variant="h5" sx={textStyle}>
                  {t("DASHBOARD.ACTIONS.ATTACHMENT_PREVIEW") + " " + filename}
                </Typography>
              }
              sx={{ cursor: "move", paddingBottom: "0" }}
            />
            <CardContent
              sx={{ flexGrow: 1, overflow: "auto", padding: "0 20px 20px 20px" }}
            >
              {mimeType === "application/pdf" ? (
                <Document
                  file={attachment}
                  onLoadError={console.error}
                  onLoadSuccess={onDocumentLoadSuccess}
                  error={t('GENERAL.ERROR')}
                  loading={<CircularProgress />}
                >
                  {Array.from(new Array(numPages), (el, idx) => (
                    <Box key={`box_${idx + 1}`}>
                      <Typography
                        variant="bodySm"
                        sx={{ color: mode === 'light' ? neutral[950] : neutral[50], p: "15px 0", display: "block" }}
                      >
                        {t("DASHBOARD.ACTIONS.PAGE_COUNT", {index: idx + 1, numPages: numPages})}
                      </Typography>

                      <Page
                        pageNumber={idx + 1}
                        width={size.width - 40}
                      />
                    </Box>
                  ))}
                </Document>
              ) : mimeType.startsWith("text/") ? (
                <pre>{textContent}</pre>
              ) : mimeType.startsWith("image/") ? (
                <img
                  src={attachment}
                  alt={filename}
                  style={{ width: "100%", height: "auto" }}
                />
              ) : (
                <object
                  data={attachment}
                  type={mimeType}
                  style={{ width: size.width, height: "auto" }}
                >
                  <p>{t("DASHBOARD.ACTIONS.NO_ATTACHMENT_PREVIEW")}</p>
                </object>
              )}
            </CardContent>
          </Card>
        </Rnd>
      </Box>
    );
};

export default AttachmentPreviewModal;
