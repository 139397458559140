import {Navigate, Outlet} from "react-router-dom";
import React from "react";

interface ProtectedRouteProps {
    /**
     * Flag if route can be accessed.
     */
    isAllowed: boolean;
    /**
     * Redirect route if isAllowed is false. Optional. Default is /login
     */
    redirectPath: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ isAllowed, redirectPath='/login'}) => {
    if (!isAllowed) {
        console.log('  >> Redirect cause not authenticated or not having the permission for the requested route.');
        return <Navigate to={redirectPath} replace />;
    }

    // render route
    return <Outlet />;
};
