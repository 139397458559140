import { Alert, Box, IconButton, LinearProgress, Snackbar, Typography, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { AUTO_HIDE_DURATION_ERROR } from "../Common";
import { ReactComponent as CheckCircleIcon } from '../assets/check-circle.svg';
import { ReactComponent as CloseIconWhite } from '../assets/close-white.svg';
import claimBackground from "../assets/gradient.jpg";
import { ReactComponent as ErrorIcon } from '../assets/minus-circle.svg';
import useAuthenticatedFetch from "../auth/authenticated";
import { ClaimWrapper, CustomHeaderButton } from '../components/claim/ClaimHeader';
import MailContent from "../components/mail/MailContent";
import MailDetails from "../components/mail/MailDetails";
import { FEEDBACK } from "../models/Feedback";
import { Attachment, Mail, SendMail } from "../models/SendMail";
import { common } from "../theme/colors";
import { useSearchParams } from "react-router-dom";

const NewMail = () => {
    const getAccessHeader = useAuthenticatedFetch();
    const { t } = useTranslation();

    const [showFeedback, setShowFeedback] = useState<FEEDBACK>(FEEDBACK.NONE);
    const [mailIsPending, setMailIsPending] = useState<boolean>(false);
    const [sendMailDisabled, setSendMailDisabled] = useState<boolean>(false);

    const [queryParams] = useSearchParams();
    const [mailContent, setMailContent] = useState<string>('');
    const [mailDetails, setMailDetails] = useState<Mail>({
        subject: '',
        attachments: [],
        mailElements: {
            chipsTo: [],
            chipsCc: [],
        }
    });

    /**
     * Maps BE response to Attachment model
     * @param files
     */
    const mapAttachmentsData = (files: any[]): Attachment[] => {
        return files.map((f) => {
            return {
                file_name: f.filename,
                size: f.size,
                file_id: f.id,
                mimetype: f.mimetype
            }
        });
    }

    useEffect(() => {
        const fetchMailData = async () => {
            try {
                const response = await fetch(`/api/mails/draft?claim_id=${queryParams.get('id')}&counterpart=${queryParams.get('counterpart')}&mail_type=${queryParams.get('mailType')}&topic=${queryParams.get('topic')}`, {
                    method: 'GET',
                    headers: await getAccessHeader(false),
                });

                const data = await response.json();

            if (response.ok) {
                setMailContent(data.body);
                setMailDetails({
                    subject: data.subject,
                    attachments: mapAttachmentsData(data.attachments),
                    mailElements: {
                        chipsTo: data.mail_to || [],
                        chipsCc: data.mail_cc || [],
                    }
                });
            } else {
                console.error('Failed to fetch mail data:', data.error);
                setShowFeedback(FEEDBACK.ERROR);
            }
        } catch (error) {
            console.error('Error fetching mail data:', error);
        }
    };

        fetchMailData().then();
        // eslint-disable-next-line
    }, [queryParams]);

    const handleSend = async () => {
        setMailIsPending(true);

        const mailData: SendMail = {
            mail_body: mailContent,
            mail_to: mailDetails.mailElements.chipsTo,
            mail_cc: mailDetails.mailElements.chipsCc,
            subject: mailDetails.subject,
            claim_id: queryParams.get('id') || '',
            counterpart: queryParams.get('counterpart') || '',
            attachments: mailDetails.attachments
        }

        try {
            const response = await fetch(`/api/mails`, {
                method: 'POST',
                headers: await getAccessHeader(),
                body: JSON.stringify(mailData),
            });

            if (response.ok) {
                console.log('Successfully send email!');
                setMailIsPending(false);
                setShowFeedback(FEEDBACK.SUCCESS);
                // close tab automatically
                setTimeout(() => window.close(), 5000);
            } else {
                console.log('Error:', response);
                setMailIsPending(false);
                setShowFeedback(FEEDBACK.ERROR);
            }
        } catch (error) {
            console.log('Error', error);
            setMailIsPending(false);
            setShowFeedback(FEEDBACK.ERROR);
        }
    };

    const handleFeedbackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        // ignore clicks that are not on the close icon of the alert
        if (reason === 'clickaway') {
            return;
        }

        setShowFeedback(FEEDBACK.NONE);
    };

    return (
        <Box sx={{display:'block', width:'100%', marginBottom: "96px"}}>
            {/*CLAIM HEADER*/}
            <Box sx={{
                pt: 2,
                pb: 2,
                backgroundImage: `url('${claimBackground}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: 'flex'
                }}
                 className="wrapper">
                <ClaimWrapper sx={{
                    justifyContent: 'space-between'
                }}>
                    <Box sx={{
                        display: 'inline-flex',
                        alignItems: 'center'
                    }}>
                        <Typography variant="h1" sx={{
                            color: common['white'],
                            mr: 1
                        }}>{t('NEW_MAIL.TITLE_NEW')}</Typography>
                        <Typography variant="h2" sx={{
                            color: common['white'],
                            mr: 1
                        }}>{t('NEW_MAIL.TITLE_MAIL')} </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <CustomHeaderButton variant="outlined"
                            size='large'
                            disabled={mailIsPending || sendMailDisabled || mailDetails.mailElements.chipsTo.length === 0}
                            onClick={handleSend}>
                            {t('NEW_MAIL.SEND')}
                        </CustomHeaderButton>
                    </Box>
                </ClaimWrapper>
            </Box>

            {/* While Send Mail Request is pending */}
            {mailIsPending &&
                <Box sx={{ p: '50px' }}>
                    <LinearProgress />
                </Box>
            }

            {/* Initial state */}
            {!mailIsPending &&
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}
                     className="wrapper">
                    <Box sx={{ flex: '1', marginRight: 2 }}>
                        <MailDetails updateMailDetails={setMailDetails} mailDetails={mailDetails} disableSendMail={setSendMailDisabled} />
                    </Box>
                    <Box flex="2">
                        <MailContent setMailContent={setMailContent} mailContent={mailContent} />
                    </Box>
                </Box>
            }

            {/* Request Feedback */}
            {showFeedback !== FEEDBACK.NONE &&
                <Snackbar open={true}
                    autoHideDuration={showFeedback === FEEDBACK.SUCCESS ? 5000 : AUTO_HIDE_DURATION_ERROR}
                    onClose={handleFeedbackClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert
                        variant="filled"
                        severity={showFeedback === FEEDBACK.SUCCESS ? 'success' : 'error'}
                        icon={showFeedback === FEEDBACK.SUCCESS ? <CheckCircleIcon /> : <ErrorIcon />}
                        sx={{ color: common['white'] }}
                        action={
                            <IconButton sx={{ pt: '5px' }} onClick={handleFeedbackClose}>
                                <CloseIconWhite />
                            </IconButton>
                        }
                    >
                        {showFeedback === FEEDBACK.ERROR ? t("GENERAL.ERROR") : t("NEW_MAIL.SUCCESS")}
                    </Alert>
                </Snackbar>
            }
        </Box>
    );
};

export default NewMail;
