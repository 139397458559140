import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import NoResult from "../../assets/NoResults.png";
import SadSmileyIcon from "../../assets/sadSmiley.png";
import { SEARCH_TYPE_CLAIM } from "../../Common";

interface NoSearchResultsProps {
    searchMode: string;
}

const NoSearchResults: React.FC<NoSearchResultsProps> = ({ searchMode }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "100%"
      }}
    >
      <img
        src={NoResult}
        alt="No results logo"
        style={{
          width: "calc((100% / 12) *5)",
          maxWidth: "728px",
          maxHeight: "360px",
        }}
      />
      <div style={{ display: "block", width: "50%" }}>
        <img
          src={SadSmileyIcon}
          alt="Sad smiley icon"
          style={{ width: "46px", height: "46px" }}
        />
        <Typography variant="h1"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          {t("SEARCH.NO_RESULTS") +
            " " +
            (searchMode === SEARCH_TYPE_CLAIM
              ? t("SEARCH.IN_CLAIMS")
              : t("SEARCH.IN_MAILS"))}
        </Typography>
        <ul style={{ paddingLeft: "25px" }}>
          <li>
            <Typography variant="bodyLg">
              {searchMode === SEARCH_TYPE_CLAIM
                ? t("SEARCH.HINT_CLAIM")
                : t("SEARCH.HINT_MAILS")}
            </Typography>
          </li>
          <li>
            <Typography variant="bodyLg">{t("SEARCH.HINT_COMMON")}</Typography>
          </li>
          <li>
            <Typography variant="bodyLg">
              {t("SEARCH.HINT_COMMON_2")}
            </Typography>
          </li>
          <li>
            <Typography variant="bodyLg">
              {searchMode === SEARCH_TYPE_CLAIM
                ? t("SEARCH.HINT_CLAIM_2")
                : t("SEARCH.HINT_MAILS_2")}
            </Typography>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NoSearchResults;
