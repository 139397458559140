import { AuthenticationResult } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react';
import { Box, Button, CardContent, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import backgroundVideo from "../assets/videos/fiegeBackground.mp4";
import { loginRequest } from "../auth/authConfig";
import { MsalModel } from "../auth/msal.model";
import React, { createRef, CSSProperties, useState } from "react";
import { typography } from "../theme/typography";

interface LoginPageProps {
    /**
     * Callback to log in to App after MS login
     */
    callback: (redirectAfterLogin: boolean) => Promise<void>;
    /**
     * Displays an error message and changes the button label
     */
    userNotKnown: boolean;
}

const LoginPage: React.FC<LoginPageProps> = ({ callback, userNotKnown }) => {
    const waveEmoji = '\u{1F44B}';
    const fistEmoji = '\u{1F44A}';

    const { t } = useTranslation();
    // MS auth
    const { instance } = useMsal();
    // Background video
    const videoRef= createRef<HTMLVideoElement>();
    const videoStyle: CSSProperties = {
        zIndex: -1,
        width:'100%',
        height:'100%',
        position: 'absolute',
        objectFit: 'fill'
    }
    const [clicked, setClicked] = useState(false);

    /**
     * Callback that opens the MSAL login popup for MS authentication
     */
    const loginMS = async () => {
        try {
            const loginResponse: AuthenticationResult = await instance.loginPopup(loginRequest);

            const msal: MsalModel = {
                localAccountId: loginResponse.account.localAccountId,
                homeAccountId: loginResponse.account.homeAccountId,
                username: loginResponse.account.username,
                idToken: loginResponse.idToken
            }

            // Note: Don´t use "msal" as key since logout popup from MS will clear all those keys from SS automatically
            localStorage.setItem('ms', JSON.stringify(msal));

            // edge case: user isAuthenticated via an MS account but not known for the app
            // - the isAuthenticated useEffect will not re-trigger, therefore we inform parent to re-login via explicit callback
            if (callback) {
                callback(true).then();
            }
        } catch (error) { }
    }

    const setPlayBack = () => {
        if (videoRef.current)
            videoRef.current.playbackRate = 0.2;
    };

    return (
        <Box style={{width:'100%', position: 'relative'}}>
            <video ref={videoRef} onCanPlay={() => setPlayBack()} autoPlay loop muted style={{...videoStyle}}>
                <source src={backgroundVideo} type='video/mp4'/>
            </video>

            <Box sx={{width: '100%', height:'100%', display:'flex'}}>
                <div style={{width: 660, margin: 'auto'}}>
                    <CardContent>
                        <div onClick={() => setClicked(true)} onMouseLeave={() => setClicked(false)} style={{fontSize: '2.5rem', cursor: 'grab', width: 'fit-content'}}>{clicked ? fistEmoji : waveEmoji}</div>
                        <Typography color='white' variant="h2" >{t('LOGIN_PAGE.WELCOME')}</Typography>
                        <Typography color='white' variant="h1" >FIEGE Carrier Claims Management</Typography>
                        <Button
                            sx={{mt: 3, color: 'white', height:'48px', border:"3px solid white", borderRadius:'1px', ...typography.buttonLg}}
                            color="primary"
                            fullWidth
                            onClick={loginMS}>
                            {userNotKnown ? t('LOGIN_PAGE.BUTTON_LOGOUT') : t('LOGIN_PAGE.BUTTON')}
                        </Button>

                        {userNotKnown && <Typography sx={{color: "white"}} variant="body2" mt={2}>{t('LOGIN_PAGE.ERROR')}</Typography>}
                    </CardContent>
                </div>
            </Box>
        </Box>
    );
};

export default LoginPage;
