import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {useThemeContext} from "../../theme/ThemeContextProvider";
import {Avatar, Container, Menu, MenuItem, Select, Typography} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {ReactComponent as MoonIcon} from '../../assets/moon.svg';
import {ReactComponent as LanguageIcon} from '../../assets/language.svg';
import {ReactComponent as AdminViewIcon} from '../../assets/admin_view.svg';
import {ReactComponent as OperatorViewIcon} from '../../assets/operator_view.svg';
import {common, neutral} from "../../theme/colors";
import {IOSSwitch} from "../../theme/theme";
import i18n from "i18next";
import theme from "../../theme/MuiTheme";
import {User} from "../../models/User";

interface HeaderMenuProps {
    user: User;
    /**
     * Callback function to log out user from app
     */
    logoutCallback: () => void;
}

const HeaderMenu: React.FC<HeaderMenuProps> = ({user, logoutCallback}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const isOnAdminView = location.pathname === '/admin';
    const navigate = useNavigate();
    const {mode, toggleColorMode} = useThemeContext();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClose = () => {
        // delay closing to see the toggle switch animation
        // setTimeout(() => {
            setAnchorEl(null);
       // }, 600);
    };

    const handleRedirect = () => {
        handleClose();
        navigate(isOnAdminView ? '/claim' : '/admin');
    }

    const showHeaderMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const switchLanguage = (lang: string) => {
      i18n.changeLanguage(lang).then( () => {
        localStorage.setItem('i18nextLng', i18n.language);
      });

      // handleClose();
    };

    const switchTheme = () => {
        toggleColorMode();
        // handleClose();
    }

    return (
        <>
            <Container onClick={showHeaderMenu} sx={{px: '0 !important', width: "auto"}}>
                <Typography variant="titleMd"
                            sx={{cursor: 'pointer',
                            [theme.breakpoints.down('md')]: {
                                display: 'none',
                                textAlign: 'end'
                            },}}>
                    {user.firstName} {user.lastName}
                </Typography>
                <Avatar sx={{display: "inline-flex", cursor: "pointer", ml: "8px", "&:hover": { opacity: "0.7" }}}>
                    {user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase()}
                </Avatar>
            </Container>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{ mt: "6px" }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                MenuListProps={{
                    sx: { py: 0,
                          minWidth: 218,
                          backgroundColor: mode === 'light' ? common['white'] : neutral[900],
                          border: '1px solid ' + (mode === 'light' ? neutral[200] : neutral[600])
                    }
                }}
            >
                { user.isAdmin && user.isOperator &&
                    <MenuItem onClick={handleRedirect} className={'header-menu-item'}
                              sx={{borderBottom: '1px solid ' + (mode === 'light' ? neutral[200] : neutral[600])}}>
                        { isOnAdminView ? <OperatorViewIcon className='icon' />
                                        : <AdminViewIcon className='icon' />
                        }
                        <Typography variant='bodyMd'>{t(isOnAdminView ? 'HEADER.MENU.OPERATOR_VIEW' : 'HEADER.MENU.ADMIN_VIEW')}</Typography>
                    </MenuItem>
                }

                <MenuItem className='header-menu-item'>
                    <LanguageIcon className='icon' style={{width: "27px", height: "27px"}} />
                    <Select
                        value={i18n.language}
                        disableUnderline
                        variant="standard"
                        IconComponent={ArrowDownIcon}
                        onChange={(event) => switchLanguage(event.target.value)}
                        sx={{ width: "100%" }}
                    >
                        { Object.keys(i18n.store.data).map((l: string) => (
                            <MenuItem key={'lang_' + l} value={l}>
                                <Typography variant='bodyMd'>{t('HEADER.MENU.'+l)}</Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </MenuItem>

                <MenuItem onClick={switchTheme} className='header-menu-item'>
                    <MoonIcon className='icon' />
                    <Typography variant='bodyMd' sx={{pr: '20px'}}>{t('HEADER.MENU.DARK_MODE')}</Typography>
                    <IOSSwitch
                        sx={{marginLeft: 'auto'}}
                        checked={mode === 'dark'}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </MenuItem>

                <MenuItem onClick={logoutCallback} className='header-menu-item'
                          sx={{borderTop: '1px solid ' + (mode === 'light' ? neutral[200] : neutral[600])}}>
                    <LogoutIcon sx={{fill: '#9FA6AD'}} className='icon' />
                    <Typography variant='bodyMd'>{t('HEADER.MENU.LOGOUT')}</Typography>
                </MenuItem>
            </Menu>
        </>
    );
};

export default HeaderMenu;
