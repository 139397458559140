import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { common, danger, neutral } from "../../../theme/colors";
import { useThemeContext } from "../../../theme/ThemeContextProvider";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { keyboardArrowIconStyle, modalBoxStyle, modalCardStyle } from "../../../theme/theme";

interface AutomateModalProps {
    isOpen: boolean;
    /**
     * Callback function to handle modal close
     * @param event - Event object from the close event
     */
    onClose: (event?: any) => void;
    /**
     * Callback function to handle save
     * @param claimStatusId
     */
    onSave: (claimStatusId: string) => void;
    claimStatusSchema: any;
    currentStatus: string;
}

const AutomateModal: React.FC<AutomateModalProps> = ({
  isOpen,
  onClose,
  onSave,
  claimStatusSchema,
  currentStatus,
}) => {
  const { t } = useTranslation();
  const { mode } = useThemeContext();

  // state hooks
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>("");

  const resetAndClose = () => {
    setSelectedValue(currentStatus);
    onClose();
  };

  const handleSave = () => {
    resetAndClose();
    onSave(selectedValue);
  };

  /**
   * React to status changes done on the ClaimHeader
   */
  useEffect(() => {
    setSelectedValue(currentStatus);
  }, [currentStatus]);

  return (
    <Modal open={isOpen} onClose={resetAndClose}>
      <Box sx={modalBoxStyle}>
        <Card sx={modalCardStyle}>
          <CardHeader
            sx={{ paddingBottom: "8px" }}
            title={
              <Typography variant="h5">{t("AUTOMATE_MODAL.TITLE")}</Typography>
            }
            action={
              <IconButton onClick={resetAndClose}>
                <CloseIcon />
              </IconButton>
            }
          ></CardHeader>
          <CardContent>
            <Box sx={{ marginBottom: "24px" }}>
              <Typography variant="bodyMd">
                {t("AUTOMATE_MODAL.SUB_TITLE")}
              </Typography>
            </Box>

            <FormControl
              variant="standard"
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "24px",
              }}
            >
              <Typography variant="titleLg">
                {t("AUTOMATE_MODAL.STATUS")}
              </Typography>

              <Select
                open={openDropdown}
                onOpen={() => setOpenDropdown(true)}
                onClose={() => setOpenDropdown(false)}
                sx={{
                  marginTop: "8px",
                  padding: "16px",
                  backgroundColor: danger[600],
                  textAlign: "center",
                }}
                disableUnderline
                value={selectedValue}
                onChange={(event) =>
                  setSelectedValue(event.target.value as string)
                }
                inputProps={{ IconComponent: () => null }}
                renderValue={() => (
                  <Chip
                    style={{
                      border: "2px dashed",
                      color: common["white"],
                      borderColor: common["white"],
                      paddingTop: "2px",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                    label={
                      <>
                        {t("STATUS." + claimStatusSchema[selectedValue])}
                        {openDropdown ? (
                          <KeyboardArrowUpIcon
                            sx={keyboardArrowIconStyle}
                            fontSize="large"
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            sx={keyboardArrowIconStyle}
                            fontSize="large"
                          />
                        )}
                      </>
                    }
                    variant="outlined"
                  />
                )}
              >
                {Object.entries(
                  claimStatusSchema as {
                    [key: string]: string;
                  },
                ).sort(([, a], [, b]) => a.localeCompare(b))
                 .map(([id, value]) => (
                  <MenuItem key={id} value={id}>
                    {t("STATUS." + value)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 4,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  width: "267px",
                  height: "48px",
                  color: mode === "light" ? neutral[700] : neutral[200],
                }}
                onClick={resetAndClose}
              >
                {t("GENERAL.CANCEL")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "267px", height: "48px", color: "white" }}
                onClick={handleSave}
              >
                {t("DASHBOARD.HEADER.AUTOMATE")}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

export default AutomateModal;
