// ResolveModal.tsx
import CloseIcon from '@mui/icons-material/Close';
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    IconButton,
    InputLabel, LinearProgress,
    MenuItem,
    Modal,
    Select,
    Snackbar,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AUTO_HIDE_DURATION_ERROR, AUTO_HIDE_DURATION_SUCCESS } from "../../../Common";
import { ReactComponent as CheckCircleIcon } from '../../../assets/check-circle.svg';
import { ReactComponent as CloseIconWhite } from '../../../assets/close-white.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/minus-circle.svg';
import useAuthenticatedFetch from "../../../auth/authenticated";
import { useThemeContext } from "../../../theme/ThemeContextProvider";
import { common, neutral } from "../../../theme/colors";
import { FEEDBACK } from "../../../models/Feedback";
import { RC_DAMAGED, RC_DELIVERY_DISPUTE, RC_LOST_IN_TRANSIT, REJECTION_CODES } from "../../../models/RejectionCodes";
import { SC_DAMAGED, SC_DELIVERY_DISPUTE, SC_LOST_IN_TRANSIT, STOP_CODES } from "../../../models/StopCodes";
import ResolveResolutionCard from "./ResolveResolutionCard";
import { modalBoxStyle, modalCardStyle } from "../../../theme/theme";
import { styled } from "@mui/material/styles";
import { secondaryFont } from "../../../theme/typography";
import { Claim } from "../../../models/Claim";

interface Codes {
    rejectionCodes: REJECTION_CODES[];
    stopCodes: STOP_CODES[];
}

interface ResolveModalProps {
    isOpen: boolean;
    /**
     * Callback function to handle modal close
     * @param event - Event object from the close event
     */
    onClose: (event?: any) => void;
    schema: any;
    claim: Claim;
}

const ResolveModal: React.FC<ResolveModalProps> = ({ isOpen, onClose, schema, claim }) => {
    // constants
    const { t } = useTranslation();
    const { mode } = useThemeContext();

    const options: Codes = { rejectionCodes: [], stopCodes: [] };
    const getAccessHeader = useAuthenticatedFetch();

    // set dropdown options depending on claim category
    switch (claim.category) {
        case 'LOST_IN_TRANSIT': {
            options.rejectionCodes = RC_LOST_IN_TRANSIT;
            options.stopCodes = SC_LOST_IN_TRANSIT;
            break;
        }
        case 'DELIVERY_DISPUTE': {
            options.rejectionCodes = RC_DELIVERY_DISPUTE;
            options.stopCodes = SC_DELIVERY_DISPUTE;
            break;
        }
        case 'DAMAGED': {
            options.rejectionCodes = RC_DAMAGED;
            options.stopCodes = SC_DAMAGED;
            break;
        }
    }

    // styles
    const textStyle = { color: mode === 'light' ? neutral[950] : neutral[50] };

    const labelStyle = {
        display: "block",
        color: mode === "light" ? neutral[700] : neutral[200],
    };

    const CustomSelect = styled(Select)(({ value }) => ({
        "&.MuiInputBase-root.MuiInput-underline::before": {
            borderBottom: value === "" ? "1px dashed" : "1px solid",
            borderBottomColor: mode === "light" ? neutral[200] : neutral[600],
        },
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: 1.5,
        fontFamily: secondaryFont,
        color: mode === "light" ? neutral[950] : neutral[50],
    }));

    // states
    const [resolution, setResolution] = useState<string>("");
    const [reason, setReason] = useState<string>("");
    const [showReasons, setShowReasons] = useState<boolean>(false);
    const [isResolving, setIsResolving] = useState<boolean>(false);
    const [showFeedback, setShowFeedback] = useState<FEEDBACK>(FEEDBACK.NONE);

    // callbacks
    const handleResolutionCallback = (type: string) => {
        setResolution(type);
        // two resolution types require reasons
        setShowReasons(type === 'REJECTED' || type === 'STOPPED');
        // always empty reasons dropdown content on resolution change
        if (type !== resolution) {
            setReason('');
        }
    };

    const handleReasonChange = (event: any) => {
        setReason(event.target.value);
    };

    const handleFeedbackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        // ignore clicks that are not on the close icon of the alert
        if (reason === 'clickaway') {
            return;
        }

        setShowFeedback(FEEDBACK.NONE);
    };

    // Resolving claim
    const handleResolve = async () => {
        try {
            setIsResolving(true);
            const response = await fetch(`/api/claims/${claim.id}`, {
                method: 'PUT',
                headers: await getAccessHeader(),
                body: JSON.stringify({
                    "resolution": resolution,
                    "rejection_code": resolution === 'REJECTED' ? reason : null,
                    "stop_code": resolution === 'STOPPED' ? reason : null
                })
            });
            const result = await response.json();

            if (!response.ok || !result.success) {
                setIsResolving(false);
                setShowFeedback(FEEDBACK.ERROR);
            } else {
                setShowFeedback(FEEDBACK.SUCCESS);
                setIsResolving(false);
                // show feedback for a second and reload the page to get the next possible claim data from queue
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        } catch (error: any) {
            console.error('Error on resolving claim:', error);
            setIsResolving(false);
            setShowFeedback(FEEDBACK.ERROR);
        }
    };

    /**
     *  Reset all internal states when modal closes
     */
    const resetAndClose = () => {
        setReason('');
        setShowReasons(false);
        setResolution('');
        setIsResolving(false);
        setShowFeedback(FEEDBACK.NONE);

        onClose();
    }


    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box sx={modalBoxStyle}>
                <Card sx={modalCardStyle}>
                    <CardHeader
                        sx={{ paddingBottom: '8px' }}
                        title={<Typography variant="h5" sx={textStyle}>{t("RESOLVE_MODAL.HEADLINE")}</Typography>}
                        action={<IconButton tabIndex={1} onClick={resetAndClose} disabled={isResolving}><CloseIcon /></IconButton>} />

                    {!isResolving &&
                        <Typography variant="bodyMd" sx={{ pt: 0, paddingLeft: '16px', color: textStyle.color }}>{t("RESOLVE_MODAL.DESC")}</Typography>
                    }

                    {!isResolving &&
                        <CardContent>
                            <Typography variant="titleLg" sx={{ display: "block", marginBottom: '8px' }}>{t("RESOLVE_MODAL.RESOLUTION")}</Typography>
                            <Box sx={{
                                display: 'flex',
                                flexWrap: 'wrap'
                            }}>
                                {/* Resolutions */}
                                {schema.resolution.constraints.options.map((type: string, index: number) => (
                                    <ResolveResolutionCard type={type}
                                        onSelected={handleResolutionCallback}
                                        key={index}
                                        index={index + 2}
                                        isSelected={resolution === type} />
                                ))}
                            </Box>
                            {/* Reasons */}
                            {showReasons &&
                                <>
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel id="Reasons">
                                            <Typography sx={labelStyle}>
                                                {t("RESOLVE_MODAL.REASON")}
                                            </Typography>
                                        </InputLabel>
                                        <CustomSelect labelId="Reasons"
                                            onChange={handleReasonChange}
                                            value={reason}
                                            tabIndex={schema.resolution.constraints.options.length + 2}
                                            IconComponent={ArrowDownIcon}
                                        >
                                            {
                                                options[resolution === 'REJECTED' ? 'rejectionCodes' : 'stopCodes'].map((type: string, index: number) => (
                                                    <MenuItem value={type}
                                                        key={resolution === 'REJECTED' ? 'rc_' + index : 'sc_' + index}>
                                                        {t("RESOLVE_MODAL." + type)}
                                                    </MenuItem>
                                                ))
                                            }
                                        </CustomSelect>
                                    </FormControl>
                                </>
                            }
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }}>
                                <Button variant="outlined"
                                    color="primary"
                                    sx={{ width: '267px', height: '48px', color: mode === 'light' ? neutral[700] : neutral[200] }}
                                    tabIndex={8}
                                    onClick={resetAndClose}
                                >
                                    {t("GENERAL.CANCEL")}
                                </Button>
                                <Button variant="contained"
                                    color="primary"
                                    sx={{
                                        width: '267px',
                                        height: '48px'
                                    }}
                                    tabIndex={9}
                                    onClick={handleResolve}
                                    disabled={resolution === '' || (showReasons && reason === '')}
                                >
                                    {t("RESOLVE_MODAL.RESOLVE")}
                                </Button>
                            </Box>
                        </CardContent>
                    }

                    {/* While Resolve Request is pending */}
                    {isResolving &&
                        <Box sx={{ padding: '16px' }}>
                            <LinearProgress />
                        </Box>
                    }

                    {/* Request Feedback */}
                    {showFeedback !== FEEDBACK.NONE &&
                        <Snackbar open={true}
                            autoHideDuration={showFeedback === FEEDBACK.SUCCESS ? AUTO_HIDE_DURATION_SUCCESS : AUTO_HIDE_DURATION_ERROR}
                            onClose={handleFeedbackClose}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        >
                            <Alert
                                variant="filled"
                                severity={showFeedback === FEEDBACK.SUCCESS ? 'success' : 'error'}
                                icon={showFeedback === FEEDBACK.SUCCESS ? <CheckCircleIcon /> : <ErrorIcon />}
                                sx={{ color: common['white'] }}
                                action={
                                    <IconButton sx={{ pt: '5px' }} onClick={handleFeedbackClose}>
                                        <CloseIconWhite />
                                    </IconButton>
                                }
                            >
                                {showFeedback === FEEDBACK.SUCCESS ? t("RESOLVE_MODAL.SUCCESS") : t("RESOLVE_MODAL.ERROR")}
                            </Alert>
                        </Snackbar>
                    }

                    {/* While get next claim Request is pending */}
                    {showFeedback === FEEDBACK.SUCCESS &&
                        <Typography variant="bodyLg" sx={{ display: 'block', paddingBottom: '10px', textAlign: 'center' }}>{t("LOADING.NEXT_CLAIM")}</Typography>
                    }

                </Card>
            </Box>
        </Modal>
    );
};

export default ResolveModal;
