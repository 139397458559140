import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ALPHA_NUMERIC,
  ALPHA_NUMERIC_HYPHEN,
  ALPHANUMERIC_WITH_SPECIAL_CHARS,
  AUTO_HIDE_DURATION_ERROR,
  AUTO_HIDE_DURATION_SUCCESS,
  formatDate,
  NUMBERS_WITH_DECIMAL,
  NUMBERS_WITH_SLASHES_OR_HYPHENS,
  ZIP_CODE,
} from "../../Common";
import { ReactComponent as CheckCircleIcon } from "../../assets/check-circle.svg";
import { ReactComponent as CarrierIcon } from "../../assets/claim/carrier.svg";
import { ReactComponent as ClientIcon } from "../../assets/claim/client.svg";
import { ReactComponent as NoteIcon } from "../../assets/claim/note.svg";
import { ReactComponent as RecipientIcon } from "../../assets/claim/recipient.svg";
import { ReactComponent as ValueIcon } from "../../assets/claim/value.svg";
import { ReactComponent as ShipmentIcon } from "../../assets/claim/shipment.svg";
import { ReactComponent as CloseIconWhite } from "../../assets/close-white.svg";
import { ReactComponent as ErrorIcon } from "../../assets/minus-circle.svg";
import useAuthenticatedFetch from "../../auth/authenticated";
import theme from "../../theme/MuiTheme";
import { useThemeContext } from "../../theme/ThemeContextProvider";
import { common, danger, neutral } from "../../theme/colors";
import { typography } from "../../theme/typography";
import { FEEDBACK } from "../../models/Feedback";
import IconComponent from "../ui/IconComponent";
import InputField from "../ui/InputField";
import {Claim, ShipmentSequence} from "../../models/Claim";


/**
 * Returns a huma-readable string from the shipment sequence JSON.
 * @param data
 */
const formatShipmentSequence = (data: ShipmentSequence[]): string => {
  return data.map(item => {
    return `Timestamp: ${formatDate(item.timestamp)}\nCategory: ${item.category}\nStatus: ${item.status}\nSequence: ${item.sequence}\nSub-status: ${item.sub_status || 'N/A'}\nLocation: ${item.location || 'N/A'}\n`;
  }).join('\n');
};

const CustomCard = styled(Card)({
  marginBottom: "24px",
  padding: "16px 24px",
});

const CustomCardContent = styled(CardContent)({
  padding: 0,
  "&:last-child": {
    paddingBottom: 0,
  },
});

const CustomCardHeader = styled(CardHeader)({
  padding: "0 0 10px 0",
  "& .MuiCardHeader-avatar": {
    marginRight: "8px",
  },
});

interface ClaimDetailsProps {
  claim: Claim;
  updateClaim: Function;
  schema: any;
}

const ClaimDetails: React.FC<ClaimDetailsProps> = ({ claim, updateClaim, schema }) => {
  const { mode } = useThemeContext();
  const { t } = useTranslation();
  const [showFeedback, setShowFeedback] = useState<FEEDBACK>(FEEDBACK.NONE);
  const getAccessHeader = useAuthenticatedFetch();

  // styles
  const CustomSelect = styled(Select)(({ value, name }) => ({
    "& .MuiInputBase-input": {
      ...typography.titleMd,
    },
    "&.MuiInputBase-root.MuiInput-underline::before": {
      borderBottom: value === "" ? "1px dashed" : "1px solid",
      borderBottomColor:
        name === "required" && value === ""
          ? danger[500]
          : mode === "light"
            ? neutral[200]
            : neutral[600],
    },
  }));

  const LogoSelect = styled(Select)(() => ({
    "& .MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input":
      {
        paddingRight: "0px",
      },
  }));

  const CustomChip = styled(Chip)({
    borderStyle: "dashed",
    borderWidth: "1px",
    width: "107px",
    height: "18px",
    borderColor: mode === "light" ? neutral[300] : neutral[400],
    backgroundColor: mode === "light" ? neutral[50] : neutral[800],
  });

  // dropdowns changing indicator
  const [isChanging, setIsChanging] = useState({
    category: false,
    carrier_id: false,
    client_id: false,
    recipient_type: false,
    recipient_country: false,
    damage_currency: false,
    refund_currency: false,
    created_by: false,
    shipment_status: false
  });

  const handleFeedbackClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    // ignore clicks that are not on the close icon of the alert
    if (reason === "clickaway") {
      return;
    }

    setShowFeedback(FEEDBACK.NONE);
  };

  const handleUpdate = async (inputEntity: string, updatedValue: any) => {
    // map to null value expected by BE
    if (updatedValue === "unknown") {
      updatedValue = null;
    }

    setShowFeedback(FEEDBACK.NONE);

    try {
      // mark as disabled state
      setIsChanging((prevState) => ({
        ...prevState,
        [inputEntity]: true,
      }));

      const response = await fetch(`/api/claims/${claim.id}`, {
        method: "PUT",
        headers: await getAccessHeader(),
        body: JSON.stringify({
          [inputEntity]: updatedValue,
        }),
      });

      const result = await response.json();

      if (result.success) {
        // update property of nested carrier or client object
        if (inputEntity === "carrier_id" || inputEntity === "client_id") {
          const entity = inputEntity === "carrier_id" ? "carrier" : "client";

          updateClaim((currentClaim) => ({
            ...currentClaim,
            // when client changes a new claim_id_tenant gets created in BE - needed for the Claim Header
            ...(entity === "client" && {
              claim_id_tenant: result.claim_id_tenant,
            }),
            // update logo data when client or carrier changes
            [entity]: {
              ...currentClaim[entity],
              logo: result[entity].logo
                ? JSON.parse(result[entity].logo)
                : null,
              id: result[entity].id,
              short_name: result[entity].short_name,
              // needed for the Claim Header
              ...(entity === "client" && {
                abbreviation: result[entity].abbreviation,
              }),
            },
          }));
        }
        // update property of nested recipient object
        else if (inputEntity.startsWith("recipient")) {
          updateClaim((currentClaim) => ({
            ...currentClaim,
            recipient: {
              ...currentClaim.recipient,
              [inputEntity]: updatedValue,
            },
          }));
        }
        // update status property of nested shipment object
        else if (inputEntity.startsWith("shipment")) {
          updateClaim((currentClaim) => ({
            ...currentClaim,
            shipment: {
              ...currentClaim.shipment,
              shipment_status: {
                ...currentClaim.shipment.shipment_status,
                status: updatedValue,
              }
            },
          }));
        }
        // default: update property of main object
        else {
          updateClaim((currentClaim) => ({
            ...currentClaim,
            [inputEntity]: updatedValue,
          }));
        }

        // reset disabled state
        setIsChanging((prevState) => ({
          ...prevState,
          [inputEntity]: false,
        }));

        setShowFeedback(FEEDBACK.SUCCESS);

        return "Successful";
      } else {
        // reset disabled state
        setIsChanging((prevState) => ({
          ...prevState,
          [inputEntity]: false,
        }));

        setShowFeedback(FEEDBACK.ERROR);

        return result.error;
      }
    } catch (error: any) {
      // reset disabled state
      setIsChanging((prevState) => ({
        ...prevState,
        [inputEntity]: false,
      }));

      setShowFeedback(FEEDBACK.ERROR);
      console.log("Error:", error.message);

      return `Error`;
    }
  };

  const logoOutput = (type: string) => {
    return claim[type].logo ? (
      <div
        title={claim[type].short_name}
        dangerouslySetInnerHTML={{
          __html:
            mode === "dark"
              ? claim[type].logo.dark_logo
              : claim[type].logo.light_logo,
        }}
      />
    ) : (
      claim[type].short_name
    );
  };

  if (!claim) {
    return <Typography variant="h6">{t("DASHBOARD.NO_DATA")}</Typography>;
  }

  return (
    <Box
      flex={3}
      sx={{
        height: "100vh",
        overflowY: "scroll",
        scrollbarWidth: "none" /* Firefox */,
        "&::-webkit-scrollbar": { display: "none" },
        paddingTop: "24px",
        [theme.breakpoints.down("md")]: {
          overflow: "visible",
          height: "100%",
          paddingRight: "0px",
        },
        [theme.breakpoints.up("md")]: {
          paddingRight: "16px"
        },
        [theme.breakpoints.up("lg")]: {
          paddingRight: "24px"
        },
        [theme.breakpoints.up("xl")]: {
          paddingRight: "32px"
        }
      }}
    >
      {/*CATEGORY*/}
      <CustomCard>
        <FormControl variant="standard" sx={{ width: "100%" }}>
          <Select
            sx={{ padding: "0" }}
            disableUnderline
            disabled={isChanging.category}
            value={claim.category || "unknown"}
            onChange={(event) => handleUpdate("category", event.target.value)}
            renderValue={() =>
              isChanging.category ? (
                <CircularProgress size={25} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <IconComponent
                    iconName={claim.category ? claim.category : "Unknown"}
                    folderPath="claim_category"
                    mode={mode}
                    extension="png"
                  />
                  <Typography sx={{ pl: "8px" }} variant="h4">
                    {t("DASHBOARD.DETAILS." + claim.category)}
                  </Typography>
                </div>
              )
            }
            IconComponent={ArrowDownIcon}
          >
            {schema.category.constraints.nullable && (
              <MenuItem value="unknown">{t("DASHBOARD.DETAILS.null")}</MenuItem>
            )}

            {schema.category.constraints.options.map((item) => (
              <MenuItem key={item} value={item}>
                {t("DASHBOARD.DETAILS." + item)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CustomCard>
      {/*CATEGORY*/}

      {/*CARRIERS*/}
      <CustomCard>
        <CustomCardContent>
          <CustomCardHeader
            avatar={<CarrierIcon />}
            action={
              <LogoSelect
                disableUnderline
                disabled={isChanging.carrier_id}
                variant="standard"
                value={claim.carrier.id || "unknown"}
                onChange={(event) =>
                  handleUpdate("carrier_id", event.target.value)
                }
                label={t("GENERAL.SELECT_OPTION")}
                inputProps={{ IconComponent: () => null }}
                renderValue={(selectedValue) =>
                  isChanging.carrier_id ? (
                    <CircularProgress size={25} />
                  ) : selectedValue === "unknown" ? (
                    <CustomChip />
                  ) : (
                    logoOutput("carrier")
                  )
                }
              >
                {schema.carrier_id.constraints.nullable && (
                  <MenuItem value="unknown">
                    {t("DASHBOARD.DETAILS.null")}
                  </MenuItem>
                )}

                {Object.entries(
                  schema.carrier_id.constraints.options as {
                    [key: string]: string;
                  },
                ).map(([id, name]) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </LogoSelect>
            }
            title={
              <Typography variant="h4">
                {t("DASHBOARD.DETAILS.CARRIER")}
              </Typography>
            }
            sx={{ pl: 0, pt: 0 }}
          />
          <InputField
            isRequired
            regex={ALPHA_NUMERIC}
            isUppercase
            label={t("DASHBOARD.DETAILS.TRACKING_ID")}
            marginBottom="16px"
            initialValue={claim.carrier.tracking_id || ""}
            onSave={(value) => handleUpdate("tracking_id", value)}
          />
        </CustomCardContent>
      </CustomCard>
      {/*CARRIERS*/}

      {/*CLIENTS*/}
      <CustomCard>
        <CustomCardContent>
          <CustomCardHeader
            avatar={<ClientIcon />}
            action={
              <LogoSelect
                disableUnderline
                disabled={isChanging.client_id}
                variant="standard"
                value={claim.client.id || "unknown"}
                onChange={(event) =>
                  handleUpdate("client_id", event.target.value)
                }
                label={t("GENERAL.SELECT_OPTION")}
                inputProps={{ IconComponent: () => null }}
                renderValue={(selectedValue) =>
                  isChanging.client_id ? (
                    <CircularProgress size={25} />
                  ) : selectedValue === "unknown" ? (
                    <CustomChip />
                  ) : (
                    logoOutput("client")
                  )
                }
              >
                {/*{schema.client_id.constraints.nullable && (*/}
                {/*  <MenuItem value="unknown">*/}
                {/*    {t("DASHBOARD.DETAILS.null")}*/}
                {/*  </MenuItem>*/}
                {/*)}*/}

                {Object.entries(
                  schema.client_id.constraints.options as {
                    [key: string]: string;
                  },
                ).map(([id, name]) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </LogoSelect>
            }
            title={
              <Typography variant="h4">
                {t("DASHBOARD.DETAILS.CLIENT")}
              </Typography>
            }
            sx={{ pl: 0, pt: 0 }}
          />
          <InputField
            isRequired
            regex={ALPHA_NUMERIC}
            isUppercase
            label={t("DASHBOARD.DETAILS.ORDER_ID")}
            initialValue={claim.client.order_id || ""}
            marginBottom="16px"
            onSave={(value) => handleUpdate("order_id", value)}
          />
        </CustomCardContent>
      </CustomCard>
      {/*CLIENTS*/}

      {/*NOTE*/}
      <CustomCard>
        <CustomCardContent>
          <CustomCardHeader
            avatar={<NoteIcon />}
            title={
              <Typography variant="h4">
                {t("DASHBOARD.DETAILS.NOTE_TITLE")}
              </Typography>
            }
            sx={{ pl: 0, pt: 0 }}
          />
          <InputField
            label={t("DASHBOARD.DETAILS.NOTE")}
            initialValue={claim.notes || ""}
            marginBottom="16px"
            useSpellCheck={true}
            maxRows={10}
            onSave={(value) => handleUpdate("notes", value)}
          />
        </CustomCardContent>
      </CustomCard>
      {/*NOTE*/}

      {/*RECIPIENT CARD*/}
      <CustomCard>
        <CustomCardContent>
          <CustomCardHeader
            avatar={<RecipientIcon />}
            title={
              <Typography variant="h4">
                {t("DASHBOARD.DETAILS.RECIPIENT")}
              </Typography>
            }
            sx={{ pl: 0, pt: 0 }}
          />

          <FormControl
            variant="standard"
            sx={{ width: "100%", marginBottom: "16px" }}
          >
            <InputLabel variant="standard" id="recipient_type">
              {t("DASHBOARD.DETAILS.RECIPIENT_TYPE")}
            </InputLabel>
            <CustomSelect
              name="required"
              labelId="recipient_type"
              disabled={isChanging.recipient_type}
              value={claim.recipient.recipient_type || ""}
              onChange={(event) =>
                handleUpdate("recipient_type", event.target.value)
              }
              label={t("GENERAL.SELECT_OPTION")}
              IconComponent={ArrowDownIcon}
              renderValue={() =>
                isChanging.recipient_type ? (
                  <CircularProgress size="18px" />
                ) : (
                  t("DASHBOARD.DETAILS." + claim.recipient.recipient_type)
                )
              }
            >
              {schema.recipient_type.constraints.nullable && (
                <MenuItem value="unknown">
                  {t("DASHBOARD.DETAILS.null")}
                </MenuItem>
              )}

              {schema.recipient_type.constraints.options.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {t("DASHBOARD.DETAILS." + item)}
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>

          <InputField
            isRequired
            marginBottom="16px"
            label={t("DASHBOARD.DETAILS.RECIPIENT_ADDRESS")}
            initialValue={claim.recipient.recipient_address || ""}
            maxRows={10}
            onSave={(value) => handleUpdate("recipient_address", value)}
          />

          <Box sx={{ display: "flex" }}>
            <InputField
              isRequired
              regex={ZIP_CODE}
              isUppercase
              marginBottom="16px"
              marginRight="24px"
              width="50%"
              label={t("DASHBOARD.DETAILS.RECIPIENT_ZIP")}
              initialValue={claim.recipient.recipient_zip || ""}
              onSave={(value) => handleUpdate("recipient_zip", value)}
            />

            <FormControl variant="standard" sx={{ width: "50%" }}>
              <InputLabel id="recipient_country" variant="standard">
                {t("DASHBOARD.DETAILS.RECIPIENT_COUNTRY")}
              </InputLabel>
              <CustomSelect
                name="required"
                labelId="recipient_country"
                disabled={isChanging.recipient_country}
                value={claim.recipient.recipient_country || ""}
                onChange={(event) =>
                  handleUpdate("recipient_country", event.target.value)
                }
                label={t("GENERAL.SELECT_OPTION")}
                IconComponent={ArrowDownIcon}
                renderValue={() =>
                  isChanging.recipient_country ? (
                    <CircularProgress size="18px" />
                  ) : (
                    t("ISO." + claim.recipient.recipient_country)
                  )
                }
              >
                {schema.recipient_country.constraints.nullable && (
                  <MenuItem value="unknown">
                    {t("DASHBOARD.DETAILS.null")}
                  </MenuItem>
                )}

                {schema.recipient_country.constraints.options.map((item) => (
                  <MenuItem key={"recipient_country_" + item} value={item}>
                    {t("ISO." + item)}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </Box>
        </CustomCardContent>
      </CustomCard>
      {/*RECIPIENT CARD*/}

      {/*CLAIM*/}
      <CustomCard>
        <CustomCardContent>
          <CustomCardHeader
            avatar={<ValueIcon />}
            title={
              <Typography variant="h4">
                {t("DASHBOARD.DETAILS.CLAIM")}
              </Typography>
            }
            sx={{ pl: 0, pt: 0 }}
          />

          <InputField
            isEditable={schema.affected_articles.constraints.is_editable}
            marginBottom="16px"
            regex={ALPHANUMERIC_WITH_SPECIAL_CHARS}
            label={t("DASHBOARD.DETAILS.AFFECTED_ARTICLES")}
            initialValue={claim.affected_articles || ""}
            isArray
            onSave={(value) => handleUpdate("affected_articles", value)}
          />
          <InputField
            isEditable={schema.imei.constraints.is_editable}
            marginBottom="16px"
            regex={NUMBERS_WITH_SLASHES_OR_HYPHENS}
            label={t("DASHBOARD.DETAILS.IMEI")}
            initialValue={claim.imei || ""}
            isArray
            onSave={(value) => handleUpdate("imei", value)}
          />
          <InputField
            isEditable={schema.serial_number.constraints.is_editable}
            marginBottom="16px"
            regex={ALPHANUMERIC_WITH_SPECIAL_CHARS}
            label={t("DASHBOARD.DETAILS.SERIAL_NUMBER")}
            initialValue={claim.serial_number || ""}
            isArray
            onSave={(value) => handleUpdate("serial_number", value)}
          />

          <InputField
            isEditable={schema.missing_data.constraints.is_editable}
            marginBottom="16px"
            label={t("DASHBOARD.DETAILS.MISSING_DATA")}
            initialValue={claim.missing_data || ""}
            onSave={(value) => handleUpdate("missing_data", value)}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              marginBottom: "16px",
            }}
          >
            <InputField
              isRequired
              regex={NUMBERS_WITH_DECIMAL}
              marginRight="16px"
              label={t("DASHBOARD.DETAILS.CLAIM_DAMAGE_VALUE")}
              initialValue={claim.damage_value || ""}
              onSave={(value) => handleUpdate("damage_value", value)}
            />

            <FormControl variant="standard" sx={{ width: "120px" }}>
              <InputLabel id="damage_currency" variant="standard">
                {t("DASHBOARD.DETAILS.CLAIM_DAMAGE_CURRENCY")}
              </InputLabel>
              <CustomSelect
                name="required"
                labelId="damage_currency"
                disabled={isChanging.damage_currency}
                value={claim.damage_currency || ""}
                onChange={(event) =>
                  handleUpdate("damage_currency", event.target.value)
                }
                label={t("GENERAL.SELECT_OPTION")}
                IconComponent={ArrowDownIcon}
                renderValue={() =>
                  isChanging.damage_currency ? (
                    <CircularProgress size="18px" />
                  ) : (
                    claim.damage_currency // we render the short version of currency
                  )
                }
              >
                {schema.damage_currency.constraints.nullable && (
                  <MenuItem value="unknown">
                    {t("DASHBOARD.DETAILS.null")}
                  </MenuItem>
                )}

                {schema.damage_currency.constraints.options.map((item) => (
                  <MenuItem key={"damage_currency_" + item} value={item}>
                    {t("CURRENCY." + item)}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              marginBottom: "16px",
            }}
          >
            <InputField
              marginRight="16px"
              regex={NUMBERS_WITH_DECIMAL}
              label={t("DASHBOARD.DETAILS.CLAIM_REFUND_VALUE")}
              initialValue={claim.refund_value || ""}
              onSave={(value) => handleUpdate("refund_value", value)}
            />

            <FormControl variant="standard" sx={{ width: "120px" }}>
              <InputLabel id="refund_currency" variant="standard">
                {t("DASHBOARD.DETAILS.CLAIM_REFUND_CURRENCY")}
              </InputLabel>
              <CustomSelect
                labelId="refund_currency"
                disabled={isChanging.refund_currency}
                value={claim.refund_currency || ""}
                onChange={(event) =>
                  handleUpdate("refund_currency", event.target.value)
                }
                label={t("GENERAL.SELECT_OPTION")}
                IconComponent={ArrowDownIcon}
                renderValue={() =>
                  isChanging.refund_currency ? (
                    <CircularProgress size="18px" />
                  ) : (
                    claim.refund_currency // we render the short version of currency
                  )
                }
              >
                {schema.refund_currency.constraints.nullable && (
                  <MenuItem value="unknown">
                    {t("DASHBOARD.DETAILS.null")}
                  </MenuItem>
                )}

                {schema.refund_currency.constraints.options.map((item) => (
                  <MenuItem key={"refund_currency" + item} value={item}>
                    {t("CURRENCY." + item)}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </Box>

          <InputField
            marginBottom="16px"
            regex={ALPHA_NUMERIC_HYPHEN}
            isUppercase
            label={t("DASHBOARD.DETAILS.CLAIM_ID_CARRIER")}
            initialValue={claim.carrier.claim_id_carrier || ""}
            onSave={(value) => handleUpdate("claim_id_carrier", value)}
          />

          <InputField
            marginBottom="16px"
            regex={ALPHA_NUMERIC_HYPHEN}
            isUppercase
            label={t("DASHBOARD.DETAILS.CLAIM_ID_CLIENT")}
            initialValue={claim.client.claim_id_client || ""}
            onSave={(value) => handleUpdate("claim_id_client", value)}
          />

          <InputField
            marginBottom="16px"
            label={t("DASHBOARD.DETAILS.CLIENT_CLAIM_CATEGORY")}
            initialValue={claim.client.client_claim_category || ""}
            useSpellCheck={true}
            maxRows={10}
            onSave={(value) => handleUpdate("client_claim_category", value)}
          />

          <InputField
            marginBottom="16px"
            label={t("DASHBOARD.DETAILS.CLIENT_SALES_CHANNEL")}
            initialValue={claim.client.client_sales_channel || ""}
            useSpellCheck={true}
            maxRows={10}
            onSave={(value) => handleUpdate("client_sales_channel", value)}
          />

          <FormControl
            variant="standard"
            sx={{ width: "100%", marginBottom: "16px" }}
          >
            <InputLabel variant="standard" id="creator">
              {t("DASHBOARD.DETAILS.CLIENT_CREATOR")}
            </InputLabel>
            <CustomSelect
              name="required"
              labelId="creator"
              disabled={isChanging.created_by}
              value={claim.created_by || ""}
              onChange={(event) =>
                handleUpdate("created_by", event.target.value)
              }
              IconComponent={ArrowDownIcon}
              renderValue={() =>
                isChanging.created_by ? (
                  <CircularProgress size="18px" />
                ) : (
                  t("DASHBOARD.DETAILS." + claim.created_by)
                )
              }
            >
              {schema.created_by.constraints.nullable && (
                <MenuItem value="unknown">
                  {t("DASHBOARD.DETAILS.null")}
                </MenuItem>
              )}

              {schema.created_by.constraints.options.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {t("DASHBOARD.DETAILS." + item)}
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
        </CustomCardContent>
      </CustomCard>
      {/*END CLAIM*/}

      {/*SHIPMENT INFO*/}
      <CustomCard>
        <CustomCardContent>
          <CustomCardHeader
              avatar={<ShipmentIcon />}
              title={
                <Typography variant="h4">
                  {t("DASHBOARD.DETAILS.SHIPMENT_INFO")}
                </Typography>
              }
              sx={{ pl: 0, pt: 0 }}
          />
          <FormControl
              variant="standard"
              sx={{ width: "100%", marginBottom: "16px" }}
          >
            <InputLabel variant="standard" id="shipment_status">
              {t("DASHBOARD.DETAILS.SHIPMENT_STATUS")}
            </InputLabel>
            <CustomSelect
                labelId="shipment_status"
                disabled={isChanging.shipment_status}
                value={claim?.shipment?.shipment_status.status || ""}
                onChange={(event) =>
                    handleUpdate("shipment_status", event.target.value)
                }
                IconComponent={ArrowDownIcon}
                renderValue={() =>
                    isChanging.shipment_status ? (
                        <CircularProgress size="18px" />
                    ) : (
                        t("DASHBOARD.DETAILS.SHIPMENT_" + claim?.shipment?.shipment_status.status)
                    )
                }
            >
              {claim?.shipment?.shipment_status?.status_options?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {t("DASHBOARD.DETAILS.SHIPMENT_" + item)}
                  </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>

          <InputField
              isEditable={false}
              marginBottom="16px"
              label={t("DASHBOARD.DETAILS.SHIPMENT_LAST_CHECK")}
              initialValue={claim?.shipment?.last_api_check ? formatDate(claim.shipment.last_api_check) : ""}
          />

          <InputField
              isEditable={false}
              marginBottom="16px"
              maxRows={6}
              label={t("DASHBOARD.DETAILS.SHIPMENT_EVENT_SEQUENCE")}
              initialValue={claim?.shipment?.event_sequence ? formatShipmentSequence(claim.shipment.event_sequence) : ""}
          />
        </CustomCardContent>
      </CustomCard>
      {/*END SHIPMENT INFO*/}

      {/* Request Feedback */}
      {showFeedback !== FEEDBACK.NONE && (
        <Snackbar
          open={true}
          autoHideDuration={
            showFeedback === FEEDBACK.SUCCESS
              ? AUTO_HIDE_DURATION_SUCCESS
              : AUTO_HIDE_DURATION_ERROR
          }
          onClose={handleFeedbackClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            variant="filled"
            severity={showFeedback === FEEDBACK.SUCCESS ? "success" : "error"}
            icon={
              showFeedback === FEEDBACK.SUCCESS ? (
                <CheckCircleIcon />
              ) : (
                <ErrorIcon />
              )
            }
            sx={{ color: common["white"] }}
            action={
              <IconButton sx={{ pt: "5px" }} onClick={handleFeedbackClose}>
                <CloseIconWhite />
              </IconButton>
            }
          >
            {showFeedback === FEEDBACK.ERROR
              ? t("GENERAL.ERROR")
              : t("GENERAL.SAVED")}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default ClaimDetails;
