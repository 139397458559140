import { createTheme, responsiveFontSizes } from "@mui/material";
import { purple } from "@mui/material/colors";

const muiTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: '#008e93',
        },
        secondary: {
            main: '#47bce5',
        },
        error: {
            main: '#f80a37',
        },
        success: {
            main: purple[500],
        },
        warning: {
            main: '#f80a37',
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                outlinedPrimary: {
                    borderRadius: 20,
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.size === "large" && {
                        width: 100,
                        height: 100,
                    }),
                }),
            },
            defaultProps: {
                size: "medium",
            },
            variants: [
                {
                    props: { variant: "circular" },
                    style: {
                        borderRadius: 10,
                    },
                },
            ],
        },
    },
});

export default responsiveFontSizes(muiTheme);
