import React from 'react';
import { Box, Card, CardContent, CircularProgress } from "@mui/material";
import MailTextEditor from "./MailTextEditor";

interface MailContentProps {
    /**
     * Email text (HTML string)
     */
    mailContent: string;
    /**
     * useState callback to parent
     */
    setMailContent: Function;
}

const MailContent: React.FC<MailContentProps> = ({mailContent, setMailContent}) => {

    const handleContentChange = (content: string) => {
        setMailContent(content);
    };

    return (
        <div>
            <Card sx={{width:'100%'}}>
                <CardContent>
                    <Box>
                        {mailContent ?
                            <MailTextEditor handleContentChange={handleContentChange} mailContent={mailContent}/>
                          : <CircularProgress sx={{m: 10}} />
                        }
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
}

export default MailContent;
