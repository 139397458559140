import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SelectCell from "./SelectCell";
import { Box } from "@mui/material";
import { formatDate, formatUtcDateToIsoString } from "../../Common";
import { IOSSwitch } from "../../theme/theme";
import UploadLogoModal from "./UploadLogoModal";
import { useTranslation } from "react-i18next";
import { typography } from "../../theme/typography";

/**
 * Defines custom cell renderers based on column type from BE schema
 */
const CellRenderer = ({
  rowId,
  cellData,
  column,
  isEditMode,
  isDetailPanel,
  fieldErrors,
  onCellValueChange,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  const handleChange = (value) => {
    onCellValueChange(rowId, column.accessorKey, value);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSaveLogos = (logos) => {
    handleChange(logos);
  };

  /**
   * Translates item for certain schema keys.
   * @param accessorKey
   * @param item
   */
  const translate = (accessorKey: string, item: string): string => {
    // null as selection is a case
    if (item === null) {
      return item;
    }

    if (accessorKey === "resolution") {
      return t("RESOLVE_MODAL." + item);
    }

    if (accessorKey === "created_by") {
      return t("DASHBOARD.DETAILS." + item);
    }

    if (accessorKey === "recipient_type") {
      return t("DASHBOARD.DETAILS." + item);
    }

    if (accessorKey === "stop_code") {
      return t("RESOLVE_MODAL." + item);
    }

    if (accessorKey === "rejection_code") {
      return t("RESOLVE_MODAL." + item);
    }

    if (accessorKey === "claim_status_id") {
      return t("STATUS." + item);
    }

    if (
      accessorKey === "mail_counterpart" ||
      accessorKey === "mail_topic" ||
      accessorKey === "mail_type"
    ) {
      return t("ADMIN.MAIL_TYPE." + item);
    }

    if (accessorKey === "country" || accessorKey === "recipient_country") {
      return t("ISO." + item.toUpperCase());
    }

    if (accessorKey === "language") {
      return t("LANGUAGE." + item);
    }

    if (
      accessorKey === "damage_currency" ||
      accessorKey === "refund_currency"
    ) {
      return t("CURRENCY." + item);
    }

    if (accessorKey === "category") {
      return t("DASHBOARD.DETAILS." + item);
    }

    return item;
  };

  /**
   * Defines the type property for the <input> element.
   * @param schemaType - Type according to BE schema
   */
  const setTypeOfInput = (schemaType: string): string => {
    switch (schemaType) {
      case "Integer":
        return "number";
      case "Float":
        return "number";
      case "Text":
        return "text";
      case "ARRAY":
        return "text";
      case "DateTime":
        return "date";
      default:
        return schemaType; // should throw error on purpose if BE schema adds a new type that FE does not know
    }
  };

  /**
   * Returns comma separated and sorted string.
   * @param cellData - String array of values
   */
  const mergeAndSortMultiple = (cellData: string[]): string => {
    return cellData
      .filter((value) => column.options[value] !== undefined) // Filter out values not present in options
      .map((value) => column.options[value]) // Map values to their corresponding labels
      .sort((a, b) => a.localeCompare(b))
      .join(", ");
  };

  switch (column.type) {
    case "Text":
    case "Integer":
    case "ARRAY":
    case "Float":
      return isEditMode || isDetailPanel ? (
        // expanded row or expanded for edit mode
        <TextField
          disabled={!isEditMode || !column.isEditable}
          fullWidth
          label={column.header}
          variant="standard"
          size="small"
          type={setTypeOfInput(column.type)}
          error={!!fieldErrors[column.accessorKey]}
          value={cellData ? cellData : ""}
          multiline={column.maxLength === 20000}
          rows={5}
          onChange={(event) => handleChange(event.target.value)}
        />
      ) : (
        // Columns data when not expanded
        <Typography>{cellData}</Typography>
      );

    case "DateTime":
      // expanded row or expanded for edit mode: Render Date input
      return isEditMode || isDetailPanel ? (
        <TextField
          disabled={!isEditMode || !column.isEditable}
          fullWidth
          label={column.header}
          variant="standard"
          size="small"
          type={setTypeOfInput(column.type)}
          error={!!fieldErrors[column.accessorKey]}
          value={cellData ? formatUtcDateToIsoString(cellData) : " "}
          onChange={(event) => handleChange(event.target.value)}
        />
      ) : (
        // Columns data when not expanded: Render date as readable string
        <Typography>{formatDate(cellData)}</Typography>
      );

    case "Enum":
      // expanded row or expanded for edit mode: Render array as Select
      return isEditMode || isDetailPanel ? (
        <SelectCell
          disabled={!isEditMode || !column.isEditable}
          label={column.header}
          value={cellData}
          options={column.options || []}
          error={!!fieldErrors[column.accessorKey]}
          onChange={(value) => handleChange(value)}
          nullable={column.nullable}
          accessorKey={column.accessorKey}
        />
      ) : (
        // Columns data when not expanded: Render the selected option
        <Typography>{translate(column.accessorKey, cellData)}</Typography>
      );

    case "UUID":
      // expanded row or expanded for edit mode: Render Map as Select
      return isEditMode || isDetailPanel ? (
        <SelectCell
          disabled={!isEditMode || !column.isEditable}
          label={column.header}
          value={cellData}
          options={column.options || []}
          error={!!fieldErrors[column.accessorKey]}
          onChange={(value) => handleChange(value)}
          nullable={column.nullable}
          multiple={column.accessorKey === "user_roles" || column.accessorKey === "client_carriers"}
          accessorKey={column.accessorKey}
        />
      ) : (
        // Columns data when not expanded: Render the selected option
        <>
          {column.accessorKey === "user_roles" || column.accessorKey === "client_carriers" ? ( // data that has multiple selection
            <Typography>{mergeAndSortMultiple(cellData)}</Typography>
          ) : (
            <Typography>
              {translate(column.accessorKey, column.options[cellData])}
            </Typography>
          )}
        </>
      );

    case "JSON":
      // Reserved for client and carrier logo - position in expanded defined via backend response order
      return (
        <Box sx={{ pt: 2, pb: 2 }}>
          <Typography sx={{ ...typography.titleMd, pr: 2, pb: 2 }}>
            {column.header}
          </Typography>
          <span
            style={{ marginRight: "16px" }}
            dangerouslySetInnerHTML={{ __html: cellData?.light_logo }}
          />
          <span dangerouslySetInnerHTML={{ __html: cellData?.dark_logo }} />
          <Typography
            style={{
              ...typography.titleMd,
              display: "block",
              marginTop: "16px",
              textDecoration: "underline",
              color: !isEditMode ? "gray" : "#47BCE5",
              cursor: !isEditMode ? "default" : "pointer",
            }}
            onClick={!isEditMode ? undefined : handleOpenModal}
          >
            {(cellData?.light_logo && cellData?.dark_logo) ? t("TABLE.EDIT_LOGOS") : t("TABLE.UPLOAD_LOGOS")}
          </Typography>

          <UploadLogoModal
            cellData={cellData}
            open={openModal}
            onClose={handleCloseModal}
            onSave={handleSaveLogos}
          />
        </Box>
      );

    case "Boolean":
      // Render Toggle Switches - add headline for expanded row
      return (
        <>
          {isDetailPanel && (
            <Typography variant="bodyMd">{column.header}</Typography>
          )}
          <IOSSwitch
            sx={{ ml: isDetailPanel ? "20px" : "0" }}
            disabled={!isEditMode || !column.isEditable}
            checked={cellData}
            onChange={(event) => handleChange(event.target.checked)}
          />
        </>
      );

    default:
      return null;
  }
};

export default CellRenderer;
