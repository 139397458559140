import React, { useState, ChangeEvent, KeyboardEvent, useEffect } from "react";
import {
  Container,
  InputBase,
  Menu,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { common, neutral, primary } from "../../theme/colors";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ReactComponent as ClaimsIcon } from "../../assets/table/inbox-in.svg";
import { ReactComponent as MailIcon } from "../../assets/table/mail.svg";
import { useThemeContext } from "../../theme/ThemeContextProvider";
import { SEARCH_TYPE_CLAIM, SEARCH_TYPE_MAILS } from "../../Common";
import {User} from "../../models/User";

// styles
const SearchContainer = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  width: "100%",
  display: "flex",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.palette.mode === "light" ? neutral[100] : neutral[800],
  padding: "0 10px",
  borderRadius: "0 8px 8px 0",
  cursor: "pointer",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 2),
    border: "2px solid ",
    borderColor: theme.palette.mode === "light" ? neutral[100] : neutral[800],
  },
}));

const DropdownOption = styled(MenuItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.mode === "light" ? primary[100] : primary[800],
  }
}));

interface HeaderSearchProps {
  user: User;
}

const HeaderSearch: React.FC<HeaderSearchProps> = ({ user }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mode } = useThemeContext();
  const location = useLocation();

  const [searchValue, setSearchValue] = useState("");
  const [searchMode, setSearchMode] = useState(SEARCH_TYPE_CLAIM);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const ArrowIcon = isMenuOpen ? ArrowUpIcon : ArrowDownIcon;

  const SearchDropdownWrapper = {
    display: "flex",
    alignItems: "center",
    backgroundColor: mode === "light" ? neutral[100] : neutral[800],
    borderRadius: "8px 0 0 8px",
    minWidth: "100px",
    p: "0 !important",
    width: "fit-content",
    cursor: "pointer",
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      triggerSearch();
    }
  };

  const handleDropdownOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option: string) => {
    setSearchMode(option);
    handleDropdownClose();
  };

  const triggerSearch = () => {
    const newUrl = `/claims/search?q=${encodeURIComponent(searchValue)}&type=${searchMode}`;

    if (window.location.pathname.includes("/search")) {
      navigate(newUrl, { replace: true });
    } else {
      window.open(newUrl, "_blank");
    }
  };

  // case for search page that opens in new tab - set last selected mode
  useEffect(() => {
    const lastSearchMode = new URLSearchParams(location.search).get("type");
    setSearchMode(!lastSearchMode ? SEARCH_TYPE_CLAIM : lastSearchMode);
    // eslint-disable-next-line
  }, []);

  return (
    <SearchContainer>
      {/* Admin has search type dropdown */}
      { user.isAdmin && (
        <>
          <Container onClick={handleDropdownOpen} sx={SearchDropdownWrapper}>
            <Typography
              variant={"titleSm"}
              sx={{
                color: mode === "light" ? neutral[700] : neutral[200],
                textTransform: "uppercase",
                pl: "16px",
                pr: "2px",
              }}
            >
              {searchMode}
            </Typography>
            <ArrowIcon />
          </Container>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleDropdownClose}
            sx={{ mt: "6px" }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            MenuListProps={{
              sx: {
                py: 0,
                minWidth: 163,
                backgroundColor:
                  mode === "light" ? common["white"] : neutral[900],
                border:
                  "1px solid " +
                  (mode === "light" ? neutral[200] : neutral[600]),
              },
            }}
          >
            <DropdownOption
              onClick={() => handleOptionSelect(SEARCH_TYPE_CLAIM)}
              sx={{
                height: "44px",
                color: mode === "light" ? neutral[950] : neutral[50],
                borderBottom:
                  "1px solid " +
                  (mode === "light" ? neutral[200] : neutral[600]),
                backgroundColor: searchMode === SEARCH_TYPE_CLAIM
                    ? mode === "light"
                        ? primary[200]
                        : primary[800]
                    : "transparent",
              }}
            >
              <ClaimsIcon />
              <Typography variant="bodyMd" sx={{ pl: "15px" }}>
                {t("HEADER.CLAIMS")}
              </Typography>
            </DropdownOption>

            <DropdownOption
              onClick={() => handleOptionSelect(SEARCH_TYPE_MAILS)}
              sx={{
                height: "44px",
                color: mode === "light" ? neutral[950] : neutral[50],
                backgroundColor: searchMode === SEARCH_TYPE_MAILS
                    ? mode === "light"
                        ? primary[200]
                        : primary[800]
                    : "transparent",
              }}
            >
              <MailIcon />
              <Typography variant="bodyMd" sx={{ pl: "15px" }}>
                {t("HEADER.MAILS")}
              </Typography>
            </DropdownOption>
          </Menu>
        </>
      )}

      {/* Operator has only search in claims */}
      <StyledInputBase
        type="text"
        placeholder={t("HEADER.SEARCH")}
        aria-label="search"
        value={searchValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        sx={{ color: mode === "light" ? neutral[700] : neutral[200] }}
      />
      <SearchIconWrapper onClick={triggerSearch}>
        <SearchIcon />
      </SearchIconWrapper>
    </SearchContainer>
  );
};

export default HeaderSearch;
