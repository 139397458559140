import {
    AppBar,
    Box, Chip,
    styled,
    Toolbar
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {ReactComponent as DarkLogo} from '../../assets/logo-dark.svg';
import {ReactComponent as LightLogo} from '../../assets/logo-light.svg';
import {useThemeContext} from "../../theme/ThemeContextProvider";
import "../../css/App.css";
import HeaderMenu from "./HeaderMenu";
import {neutral} from "../../theme/colors";
import HeaderSearch from "./HeaderSearch";
import {User} from "../../models/User";
import React from "react";

const StyledToolbar = styled(Toolbar)({
    display: "flex",
    justifyContent: "space-between"
});

const Icons = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    gap: "20px"
}));

interface HeaderProps {
    user: User | null;
    /**
     * Callback function to log out user from app
     */
    onLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({ user, onLogout }) => {
    const {mode} = useThemeContext();
    const {t} = useTranslation();

    return (
        <AppBar position="static" className="wrapper">
            <StyledToolbar>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    {mode === 'dark' ? <DarkLogo/> : <LightLogo/>}
                    {user?.isAdmin &&
                        <Chip label={t('HEADER.ADMIN')} sx={{
                            border: "2px dashed",
                            color: mode === 'light' ? neutral[950] : neutral[50],
                            borderColor: mode === 'light' ? neutral[950] : neutral[50],
                            paddingTop: '2px',
                            background: "transparent",
                            fontSize: "16px",
                            fontWeight: 500,
                            marginLeft: "24px"
                        }}/>
                    }
                </Box>
                {user &&
                    <Icons>
                        <HeaderSearch user={user} />
                        <HeaderMenu user={user} logoutCallback={onLogout}></HeaderMenu>
                    </Icons>
                }
            </StyledToolbar>
        </AppBar>
    );
};

export default Header;
