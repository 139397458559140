import {Box} from "@mui/material";
import '../../css/App.css'
import {
    Editor,
    EditorProvider,
    Toolbar,
    BtnBold,
    BtnItalic,
    BtnUnderline, BtnBulletList, BtnUndo, BtnRedo
} from "react-simple-wysiwyg";
import {secondaryFont} from "../../theme/typography";
import React from "react";

const btnEditor = {
    color: "inherit",
    background: 'none',
};

const editorContent = {
    fontFamily: secondaryFont,
    fontSize: "18px",
    fontWeight: 400,
}

interface MailTextEditorProps {
    /**
     * Email text (HTML string)
     */
    mailContent: string;
    /**
     * useState callback to parent
     */
    handleContentChange: (content: string) => void;
}

const MailTextEditor: React.FC<MailTextEditorProps> = ({mailContent, handleContentChange}) => {

    return (
        <Box sx={{p: 2}}>
                <EditorProvider>
                    <Editor style={editorContent}
                            containerProps={{style: {border: 'none'}}}
                            value={mailContent}
                            onChange={(event) => {handleContentChange(event.target.value)}}>
                        <Toolbar
                            style={{
                                backgroundColor: 'inherit',
                                borderBottom: 'none',
                                textAlign: 'right',
                                justifyContent: 'flex-end'
                            }}>
                            <BtnBold style={btnEditor}/>
                            <BtnItalic style={btnEditor}/>
                            <BtnUnderline style={btnEditor}/>
                            <BtnBulletList style={btnEditor}/>
                            <BtnRedo style={btnEditor}/>
                            <BtnUndo style={btnEditor}/>
                        </Toolbar>
                    </Editor>
                </EditorProvider>
        </Box>
    )
}

export default MailTextEditor;
