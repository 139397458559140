import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

import deTranslation from './translation_de.json';
import enTranslation from './translation_en.json';

const resources = {
    en: { translation: enTranslation },
    de: { translation: deTranslation }
};

// load last selected language
const lang = localStorage.getItem('i18nextLng');

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: lang ? lang : 'en',
        resources,
        debug: process.env.NODE_ENV !== "production",
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        }
    }).then();

export default i18n;
