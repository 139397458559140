import {
  Alert,
  Box,
  Button,
  Chip,
  IconButton,
  Snackbar,
  Tooltip,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnFiltersState,
  type MRT_SortingState,
} from "material-react-table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AUTO_HIDE_DURATION_ERROR,
  AUTO_HIDE_DURATION_SUCCESS,
  NEW_UNIQUE_ROW_ID,
} from "../../Common";
import { ReactComponent as CheckCircleIcon } from "../../assets/check-circle.svg";
import { ReactComponent as CloseIconWhite } from "../../assets/close-white.svg";
import { ReactComponent as ErrorIcon } from "../../assets/minus-circle.svg";
import { ReactComponent as AddIcon } from "../../assets/table/add.svg";
import { ReactComponent as CloseIcon } from "../../assets/table/close.svg";
import { ReactComponent as DeleteIcon } from "../../assets/table/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/table/edit.svg";
import { ReactComponent as LaunchIcon } from "../../assets/table/external-link.svg";
import { ReactComponent as SaveIcon } from "../../assets/table/save.svg";
import { ReactComponent as CopyIdIcon } from "../../assets/idCopy.svg";
import useAuthenticatedFetch from "../../auth/authenticated";
import { common, neutral } from "../../theme/colors";
import { FEEDBACK } from "../../models/Feedback";
import CellRenderer from "./CellRenderer";
import { DetailPanel } from "./DetailPanel";
import { generateColumns } from "./GenerateColumns";
import handleCancelRow from "./handleCancelRow";
import handleCreateRow from "./handleCreateRow";
import handleEditRow from "./handleEditRow";
import { useThemeContext } from "../../theme/ThemeContextProvider";

const Table = ({
  initialData,
  columnData,
  endpoint,
  showCreateNewButton,
  showExtraActions,
  id,
}) => {
  const getAccessHeader = useAuthenticatedFetch();
  const { t } = useTranslation();
  const { mode } = useThemeContext();
  const tableBackgroundStyle = {
    backgroundColor: mode === "light" ? common["white"] : neutral[900],
  };

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [flattenedData, setFlattenedData] = useState<any>([]);
  const [initialFlattenedData, setInitialFlattenedData] = useState<any>([]);
  const [editableRowId, setEditableRowId] = useState<number | null>(null);
  const [editedFields, setEditedFields] = useState({});
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});

  const [showFeedback, setShowFeedback] = useState<FEEDBACK>(FEEDBACK.NONE);
  const [showFeedbackMessage, setShowFeedbackMessage] = useState<string>("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    // Flatten the structure of each object in the array
    generateRows(initialData);
  }, [initialData]);

  const generateRows = (initialData) => {
    // Flatten the data by iterating over each item in the initialData array
    const flattenedData = initialData.map((item) => {
      const flattenedItem = {};

      // Copy all properties from item.entry to the flattenedItem object
      for (const key in item.entry) {
        flattenedItem[key] = item.entry[key];
      }

      // Copy all properties from item.on_delete_info to the flattenedItem object
      for (const key in item.on_delete_info) {
        flattenedItem[key] = item.on_delete_info[key];
      }

      return flattenedItem;
    });

    setFlattenedData(flattenedData);

    // Set the initial flattened data state to the same array for resetting purposes after editing
    setInitialFlattenedData(flattenedData);
  };


  const handleEditRowClick = (row) => {
    handleEditRow(row, editableRowId, setEditableRowId, table);
  };

  const handleCancelRowClick = (row) => {
    setFieldErrors({});
    handleCancelRow(
      row,
      initialFlattenedData,
      setFlattenedData,
      setEditableRowId,
      setEditedFields,
      table,
    );
  };

  const handleCreateRowClick = () => {
    setFieldErrors({});
    handleCreateRow(
      setFlattenedData,
      setEditableRowId,
      columns,
      table,
    );
  };

  const validateRow = (row) => {
    const errors: { [key: string]: string } = {};

    columns.forEach((column) => {
      // Check if the field is non-nullable and empty
      if (
        !column.nullable &&
        column.type !== "Boolean" &&
        !row.original[column.accessorKey]
      ) {
        errors[column.accessorKey] = column.accessorKey;
      }
    });

    setFieldErrors(errors);

    return errors;
  };

  const handleSaveRowClick = (row) => {
    const invalidColumns = validateRow(row);

    if (Object.keys(invalidColumns).length > 0) {
      setShowFeedback(FEEDBACK.ERROR);
      setShowFeedbackMessage("GENERAL.ERROR_VALIDATION");
      console.log("Validation errors:", invalidColumns);
      return;
    }

    // update data model
    handleSaveRow(
      row,
      endpoint,
      editedFields,
      setEditedFields,
      setEditableRowId,
      table,
      setShowFeedback,
      setShowFeedbackMessage,
    );
  };

  const handleSaveRow = async (
    row,
    endpoint,
    editedFields,
    setEditedFields,
    setEditableRowId,
    table,
    setShowFeedback,
    setShowFeedbackMessage,
  ) => {
    try {
      let response;
      // Row is being edited, perform PUT request
      if (row.original.id !== NEW_UNIQUE_ROW_ID) {
        // Include the id field from the original row along with editedFields
        const editedFieldsWithId = { ...editedFields, id: row.original.id };

        const url = `${endpoint}/${row.original.id}`;

        response = await fetch(url, {
          method: "PUT",
          headers: await getAccessHeader(),
          body: JSON.stringify(editedFieldsWithId),
        });

        const result = await response.json();

        if (result.success) {
          // Update the new editedFields in the FlattenedData and InitialFlattenedData so admin see data persistent
          setFlattenedData((prevRowData) =>
            prevRowData.map((prevRow) =>
              prevRow.id === row.original.id
                ? { ...prevRow, ...editedFields }
                : prevRow,
            ),
          );
          setInitialFlattenedData(flattenedData);
          setShowFeedback(FEEDBACK.SUCCESS);
          setShowFeedbackMessage("GENERAL.SAVED");
          setEditedFields({});
        } else {
          setShowFeedback(FEEDBACK.ERROR);
          setShowFeedbackMessage("GENERAL.ERROR");
          console.error("Failed to save row:", result.error);
          // close opened edited row to get old values back into view
          handleCancelRowClick(row);
        }
      } else {
        // New row, perform POST request
        response = await fetch(endpoint, {
          method: "POST",
          headers: await getAccessHeader(),
          body: JSON.stringify(editedFields),
        });

        const result = await response.json();

        if (result.success) {
          row.original.id = result.id;
          setShowFeedback(FEEDBACK.SUCCESS);
          setShowFeedbackMessage("GENERAL.SAVED");
          setEditedFields({});
        } else {
          setShowFeedback(FEEDBACK.ERROR);
          setShowFeedbackMessage("GENERAL.ERROR");
          console.error("Failed to save row:", result.error);
          // remove temp new row from view
          handleCancelRowClick(row);
        }
      }

      // reset and close
      table.setCreatingRow(null);
      setEditableRowId(null);
      table.setExpanded({ [row.id]: false });
    } catch (error) {
      console.error("Error saving row:", error);
    }
  };

  // update data model
  const handleDeleteRowClick = (row) => {
    handleDeleteRow(
      row.original.id,
      endpoint,
      setShowFeedback,
      setShowFeedbackMessage,
      setFlattenedData,
    ).then(() => setInitialFlattenedData(flattenedData));
  };

  const handleDeleteRow = async (
    rowId,
    endpoint,
    setShowFeedback,
    setShowFeedbackMessage,
    setFlattenedData,
  ) => {
    try {
      const response = await fetch(`${endpoint}/${rowId}`, {
        method: "DELETE",
        headers: await getAccessHeader(false),
      });

      const result = await response.json();

      if (result.success) {
        setFlattenedData((prevRowData) =>
          prevRowData.filter((row) => row.id !== rowId),
        );

        setShowFeedback(FEEDBACK.SUCCESS);
        setShowFeedbackMessage("GENERAL.SAVED");
      } else {
        setShowFeedback(FEEDBACK.ERROR);
        setShowFeedbackMessage("GENERAL.ERROR");
        console.error(`Failed to delete row with ID ${rowId}.`);
        return;
      }
    } catch (error) {
      setShowFeedback(FEEDBACK.ERROR);
      setShowFeedbackMessage("GENERAL.ERROR");
      console.error("Error deleting row:", error);
    }
  };

  const handleDragEnd =
    (
      table,
      flattenedData,
      setFlattenedData,
      endpoint,
      setShowFeedback,
      setShowFeedbackMessage,
    ) =>
    async () => {
      const { draggingRow, hoveredRow } = table.getState();

      if (hoveredRow && draggingRow) {
        const newFlattenedData = [...flattenedData];
        const draggedRow = newFlattenedData.splice(draggingRow.index, 1)[0];
        newFlattenedData.splice(hoveredRow.index, 0, draggedRow);

        try {
          const response = await fetch(
            `${endpoint}/${hoveredRow.original.id}`,
            {
              method: "PUT",
              headers: await getAccessHeader(),
              body: JSON.stringify({
                order_number: hoveredRow.index,
                id: draggedRow.id,
              }),
            },
          );

          const result = await response.json();

          if (result.success) {
            setFlattenedData(newFlattenedData);
            setShowFeedback(FEEDBACK.SUCCESS);
            setShowFeedbackMessage("GENERAL.SAVED");
          } else {
            setShowFeedback("GENERAL.ERROR");
            setShowFeedbackMessage("GENERAL.ERROR");
            console.error("Failed to save new order", result.error);
          }
        } catch (error) {
          setShowFeedback(FEEDBACK.ERROR);
          setShowFeedbackMessage("GENERAL.ERROR");
          console.error(`Error updating row order number: ${error}`);
        }
      }
    };

  const handleOpenClaimView = (row) => {
    window.open("/claims/" + row.original.id, "_blank");
  };

  const handleCopyClaimId = (row) => {
    navigator.clipboard.writeText(row.original.id).then();
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  /**
   * Only used on mail templates table. Visualizes the enabled status.
   * @param row
   */
  const renderEnabledStatus = (row) => {
    const enabled: boolean = row.original.enabled;
    return (
      <Tooltip title={t(enabled ? "TABLE.ACTIVE" : "TABLE.INACTIVE")}>
        <Chip
          color={enabled ? "success" : "error"}
          size="small"
          sx={{ marginTop: "12px", marginRight: "8px", display: "block", height: "16px" }}
        />
      </Tooltip>
    );
  };

  const handleCellValueChange = (rowId, columnName, value) => {
    setFieldErrors((prevErrors) => {
      if (columnName in prevErrors) {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[columnName];
        return updatedErrors;
      }
      return prevErrors;
    });

    setEditedFields((prevState) => ({
      ...prevState,
      [columnName]: value,
    }));

    // Update flattenedData
    setFlattenedData((prevData) => {
      return prevData.map((row) => {
        // Check if the row ID matches the edited row
        if (row.id === rowId) {
          // Clone the row object and update the edited field value
          return {
            ...row,
            [columnName]: value,
          };
        } else {
          return row; // Return unchanged row
        }
      });
    });
  };

  const handleFeedbackClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    // ignore clicks that are not on the close icon of the alert
    if (reason === "clickaway") {
      return;
    }

    setShowFeedback(FEEDBACK.NONE);
  };

  const columnsToExclude = ["order_number", "id"];
  const columns = generateColumns(columnData, columnsToExclude, t, id);

  // Define the initial table state - like page size and other properties
  const initialStateOptions = {
    columnVisibility: {},
    pagination: { pageSize: 100, pageIndex: 0 },
  };

  // columns that has to be hidden
  columns.forEach((column) => {
    if (column.visibleInShowHideMenu === false) {
      initialStateOptions.columnVisibility[column.accessorKey] = false;
    }
  });

  const table = useMaterialReactTable({
    // Mapping columns and adding a custom Cell renderer for each column
    columns: columns.map((column) => ({
      ...column,
      Cell: ({ row }) => (
          <CellRenderer
              fieldErrors={fieldErrors}
              rowId={row.original.id}
              cellData={row.original[column.accessorKey]}
              column={column}
              isEditMode={editableRowId === row.original.id}
              isDetailPanel={false}
              onCellValueChange={handleCellValueChange}
          />
      ),
    })),
    // Data for the table rows
    data: flattenedData,
    // Initial state options like sorting, pagination, etc.
    initialState: initialStateOptions,
    // Enable drag and drop ordering only for the mails table
    enableRowOrdering: id === "mails",
    // Disable sorting for mail templates to avoid interfering with drag & drop ordering
    enableSorting: id !== "mails",
    enableFilters: id !== "mails",  // Disable filters for mail templates
    enableGlobalFilter: false,      // Disable global search filtering
    enableRowNumbers: true,         // Enable row numbers for the table
    rowNumberDisplayMode: "original",  // Show the original order of rows
    createDisplayMode: "row",       // Row-based display mode for new row creation
    editDisplayMode: "row",         // Row-based display mode for editing
    enableEditing: true,            // Enable editing functionality in the table
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",         // Set minimum table height
        ...tableBackgroundStyle,    // Apply custom table background style
      },
    },
    muiTableHeadCellProps: {
      sx: {
        ...tableBackgroundStyle,    // Apply custom table background style to header
      },
    },
    muiBottomToolbarProps: {
      sx: {
        ...tableBackgroundStyle,    // Apply custom background style to bottom toolbar
      },
    },
    muiTopToolbarProps: {
      sx: {
        ...tableBackgroundStyle,    // Apply custom background style to top toolbar
      },
    },
    muiTableBodyRowProps: {
      sx: {
        ...tableBackgroundStyle,    // Apply custom background style to body rows
        boxShadow: "unset",         // Remove row box shadow
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: "1.1px 2.7px 3.8px -1.2px #BBBBBB42"  // Custom box shadow for the table
      }},
    enablePagination: true,          // Enable pagination for table rows
    autoResetPageIndex: false,       // Disable auto-reset of page index on changes
    onColumnFiltersChange: setColumnFilters, // Function to handle filter changes
    onSortingChange: setSorting,     // Function to handle sorting changes

    // Render row actions (edit, delete, etc.) conditionally based on the row being edited
    renderRowActions: ({ row }) => (
        <Box sx={{ display: "flex" }}>
          {editableRowId === row.original.id ? (
              <>
                <Tooltip title={t("GENERAL.CANCEL")}>
                  <IconButton onClick={() => handleCancelRowClick(row)}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("GENERAL.SAVE")}>
                  <IconButton onClick={() => handleSaveRowClick(row)}>
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
              </>
          ) : (
              <>
                {showExtraActions && (
                    <>
                      <Tooltip title={t("TABLE.OPEN_CLAIM_VIEW")}>
                        <IconButton onClick={() => handleOpenClaimView(row)}>
                          <LaunchIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                          title={
                            copied
                                ? t("DASHBOARD.INPUT_COPIED")
                                : t("TABLE.COPY_CLAIM_ID")
                          }
                      >
                        <IconButton onClick={() => handleCopyClaimId(row)}>
                          <CopyIdIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                )}
                {id === 'mails' && (renderEnabledStatus(row))}
                <Tooltip title={t("GENERAL.EDIT")}>
                  <IconButton onClick={() => handleEditRowClick(row)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title={!row.original.is_deletable ? t("TABLE.NOT_DELETABLE_INFO") : t("GENERAL.DELETE")}>
                <span>
                  <IconButton
                      disabled={!row.original.is_deletable}
                      onClick={() => handleDeleteRowClick(row)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
                </Tooltip>
              </>
          )}
        </Box>
    ),

    // Position the actions column at the end of the table
    positionActionsColumn: "last",

    // Render custom toolbar actions (like adding a new row)
    renderTopToolbarCustomActions: () =>
        showCreateNewButton ? (
            <Button
                color="primary"
                startIcon={<AddIcon />}
                disabled={editableRowId === NEW_UNIQUE_ROW_ID}  // Disable the button if a new row is being created
                onClick={() => handleCreateRowClick()}
            >
              {t("ADMIN.BUTTON_ADD_NEW")}
            </Button>
        ) : null,

    // Disable expand all functionality
    enableExpandAll: false,

    // Position expand/collapse icons at the last column
    positionExpandColumn: "last",

    // Render a detail panel when a row is expanded
    renderDetailPanel: ({ row }) =>
        DetailPanel(
            row,
            columns,
            editableRowId === row.original.id,
            fieldErrors,
            handleCellValueChange,
        ),

    // Handle row dragging for ordering purposes
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: handleDragEnd(
          table,
          flattenedData,
          setFlattenedData,
          endpoint,
          setShowFeedback,
          setShowFeedbackMessage,
      ),
    }),

    // Define the state for filters and sorting
    state: {
      columnFilters,
      sorting,
    },

    // Localization for the table actions and tooltips
    localization: {
      actions: t("TABLE.ACTIONS"),
      expand: t("TABLE.EXPAND"),
      collapse: t("TABLE.COLLAPSE"),
      rowsPerPage: t("TABLE.ROWS_PER_PAGE"),
      goToFirstPage: t("TABLE.GO_TO_FIRST_PAGE"),
      goToLastPage: t("TABLE.GO_TO_LAST_PAGE"),
      goToNextPage: t("TABLE.GO_TO_NEXT_PAGE"),
      goToPreviousPage: t("TABLE.GO_TO_PREVIOUS_PAGE"),
      showHideColumns: t("TABLE.SHOW_HIDE_COLUMNS"),
      showHideFilters: t("TABLE.SHOW_HIDE_FILTERS"),
      showHideSearch: t("TABLE.SHOW_HIDE_SEARCH"),
      sortByColumnAsc: t("TABLE.SORT_BY_COLUMN_ASC"),
      sortByColumnDesc: t("TABLE.SORTED_BY_COLUMN_ASC"),
      sortedByColumnAsc: t("TABLE.SORT_BY_COLUMN_DESC"),
      sortedByColumnDesc: t("TABLE.SORTED_BY_COLUMN_DESC"),
      toggleDensity: t("TABLE.TOGGLE_DENSITY"),
      toggleFullScreen: t("TABLE.TOGGLE_FULL_SCREEN"),
      columnActions: t("TABLE.COLUMN_ACTIONS"),
      clearSort: t("TABLE.CLEAR_SORT"),
      clearFilter: t("TABLE.CLEAR_FILTER"),
      filterByColumn: t("TABLE.FILTER_BY_COLUMN"),
      showAllColumns: t("TABLE.SHOW_ALL_COLUMNS"),
      hideColumn: t("TABLE.HIDE_COLUMN"),
    },
  });

  return (
    <Box>
      <MaterialReactTable table={table} />
      {showFeedback !== FEEDBACK.NONE && (
        <Snackbar
          open={true}
          autoHideDuration={
            showFeedback === FEEDBACK.SUCCESS
              ? AUTO_HIDE_DURATION_SUCCESS
              : AUTO_HIDE_DURATION_ERROR
          }
          onClose={handleFeedbackClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            variant="filled"
            severity={showFeedback === FEEDBACK.SUCCESS ? "success" : "error"}
            icon={
              showFeedback === FEEDBACK.SUCCESS ? (
                <CheckCircleIcon />
              ) : (
                <ErrorIcon />
              )
            }
            sx={{ color: common["white"] }}
            action={
              <IconButton sx={{ pt: "5px" }} onClick={handleFeedbackClose}>
                <CloseIconWhite />
              </IconButton>
            }
          >
            {t(showFeedbackMessage)}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default Table;
