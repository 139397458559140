import { useState, useEffect } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    ToggleButton,
    ToggleButtonGroup,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    CircularProgress
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useAuthenticatedFetch from "../../auth/authenticated";

interface Client {
  name: string;
  id: string;
}

const AdminReports = () => {
    const {t} = useTranslation();
    const getAccessHeader = useAuthenticatedFetch();

    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [claimStatus, setClaimStatus] = useState<string | null>('open');
    const [client, setClient] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [clients, setClients] = useState<Client[]>([]);

    useEffect(() => {
      const fetchTenantClients = async () => {
          try {
              const response = await fetch('/api/tenant/clients', {
                  method: 'GET',
                  headers: await getAccessHeader(),
              });
  
              if (!response.ok) {
                  console.error('Network response was not ok');
                  return;
              }
  
              const data = await response.json();
              const transformedClients : Client[] = Object.entries(data).map(([id, name]) => ({
                  id: id,
                  name: name as string,
              }));
              
              setClients(transformedClients);
          } catch (error) {
              console.error('Fetch error:', error);
          }
      };
  
      fetchTenantClients().then();
  }, []);

    const handleDownloadCSV = async () => {
        setLoading(true);

        try {
            const response = await fetch('/api/claims/report', {
                method: 'POST',
                headers: await getAccessHeader(),
                body: JSON.stringify({
                    start_date: startDate,
                    end_date: endDate,
                    client_id: client,
                    type_of_claims: claimStatus,
                }),
            });

            if (!response.ok) {
                console.error('Network response was not ok');
            }

            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = 'Report_' + claimStatus + '-claims_' + startDate + '_' + endDate + '.csv';
            document.body.appendChild(a);
            a.click();

            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('There was an error downloading the CSV:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h5">{t("ADMIN.REPORTS.HEADLINE")}</Typography>
        <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <FormControl sx={{ width: "300px" }}>
            <InputLabel>
              {t("ADMIN.REPORTS.SELECT_CLIENT_TO_REPORT")}
            </InputLabel>
            <Select
              value={client || ""}
              onChange={(e) => setClient(e.target.value)}
              label={t("ADMIN.REPORTS.SELECT_CLIENT_TO_REPORT")}
            >
              {clients.map((client) => (
                <MenuItem key={client.id} value={client.id}>
                  {client.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box>
            <TextField
              sx={{ width: "300px", marginRight: "16px" }}
              label={t("ADMIN.REPORTS.START_DATE")}
              type="date"
              value={startDate || ""}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              sx={{ width: "300px" }}
              label={t("ADMIN.REPORTS.END_DATE")}
              type="date"
              value={endDate || ""}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <ToggleButtonGroup
            value={claimStatus}
            exclusive
            onChange={(event, newStatus) => setClaimStatus(newStatus)}
            aria-label="claim status"
          >
            <ToggleButton value="closed" aria-label="closed claims">
              {t("ADMIN.REPORTS.CLOSED_CLAIMS")}
            </ToggleButton>
            <ToggleButton value="open" aria-label="open claims">
              {t("ADMIN.REPORTS.OPEN_CLAIMS")}
            </ToggleButton>
          </ToggleButtonGroup>

          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <Button
              sx={{ width: "200px" }}
              variant="contained"
              color="primary"
              onClick={handleDownloadCSV}
              disabled={!startDate || !endDate || !client || !claimStatus}
            >
              {t("ADMIN.REPORTS.DOWNLOAD_CSV")}
            </Button>
          )}
        </Box>
      </Box>
    );
};

export default AdminReports;
