import {CircularProgress, Typography} from "@mui/material";
import {useThemeContext} from "../../theme/ThemeContextProvider";
import LoadingLight from "../../assets/claim/ConfettiLight.svg";
import LoadingDark from "../../assets/claim/ConfettiDark.svg";
import SmileyIcon from '../../assets/claim/SmileyFace.png';
import {useTranslation} from "react-i18next";

const LoadingScreen = () => {
    // constants
    const { t } = useTranslation();
    const { mode } = useThemeContext();
    const background = mode === 'light' ? `url(${LoadingLight})` : `url(${LoadingDark})`;

    return (
        <div style={{
            height:'inherit',
            width:'100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: background,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>

            <div style={{display:'block', textAlign:'center'}}>
                <img src={SmileyIcon} alt="Smiley Face" style={{width:'auto', height:'auto'}}/>
                <Typography variant="h1">{t('LOADING.CLAIM_HEADLINE_1')}</Typography>
                <Typography variant="h2">{t('LOADING.CLAIM_HEADLINE_2')}</Typography>
                <CircularProgress sx={{mt: '50px'}} />
            </div>
        </div>
    )
}
export default LoadingScreen;
