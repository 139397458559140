import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";

const SelectCell = ({
  label,
  value,
  options,
  onChange,
  error,
  nullable,
  multiple = false,
  accessorKey,
  disabled = false,
}) => {
  const { t } = useTranslation();

  /**
   * Translates option item for certain schema keys
   * @param item
   */
  const translate = (item: string): string => {
    if (accessorKey === "resolution") {
      return t("RESOLVE_MODAL." + item);
    }

    if (accessorKey === "created_by") {
      return t("DASHBOARD.DETAILS." + item);
    }

    if (accessorKey === "recipient_type") {
      return t("DASHBOARD.DETAILS." + item);
    }

    if (accessorKey === "stop_code") {
      return t("RESOLVE_MODAL." + item);
    }

    if (accessorKey === "rejection_code") {
      return t("RESOLVE_MODAL." + item);
    }

    if (accessorKey === "claim_status_id") {
      return t("STATUS." + item);
    }

    if (
      accessorKey === "mail_counterpart" ||
      accessorKey === "mail_topic" ||
      accessorKey === "mail_type"
    ) {
      return t("ADMIN.MAIL_TYPE." + item);
    }

    if (accessorKey === "country" || accessorKey === "recipient_country") {
      return t("ISO." + item.toUpperCase());
    }

    if (accessorKey === "language") {
      return t("LANGUAGE." + item);
    }

    if (
      accessorKey === "damage_currency" ||
      accessorKey === "refund_currency"
    ) {
      return t("CURRENCY." + item);
    }

    if (accessorKey === "category") {
      return t("DASHBOARD.DETAILS." + item);
    }

    return item;
  };

  return (
    <FormControl variant="standard" sx={{ minWidth: 120, width: "100%" }}>
      <InputLabel>{label}</InputLabel>
      <Select
        disabled={disabled}
        IconComponent={ArrowDownIcon}
        label={label}
        value={value ? value : (multiple ? [] : "")}
        error={error}
        multiple={multiple}
        renderValue={() =>
          Array.isArray(options) ? (
            <>{translate(value)}</>
          ) : (
            <>
              {multiple ? (
                <>
                  {value
                    .filter((value) => options[value] !== undefined) // Filter out values not present in options
                    .map((value) => options[value]) // Map values to their corresponding labels
                    .sort((a, b) => a.localeCompare(b))
                    .join(", ")}
                </>
              ) : (
                <>{translate(options[value])}</>
              )}
            </>
          )
        }
        onChange={(e) => onChange(e.target.value)}
      >
        {nullable && <MenuItem value="">{"-"}</MenuItem>}
        {Array.isArray(options)
          ? options.map((option, index) => (
              <MenuItem key={index} value={option}>
                {translate(option)}
              </MenuItem>
            ))
          : Object.entries(options).map(([key, item]) => (
              <MenuItem key={key} value={key}>
                {multiple ? (
                  <>
                    <Checkbox checked={value.indexOf(key) > -1} />
                    <ListItemText primary={item as string} />
                  </>
                ) : (
                  translate(item as string)
                )}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
};

export default SelectCell;
