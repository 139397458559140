import { useTranslation } from "react-i18next";
import { useThemeContext } from "../theme/ThemeContextProvider";
import LightIcon from "../assets/VisualEmptyLight.png";
import DarkIcon from "../assets/VisualEmptyDark.png";
import FireIcon from "../assets/fire.png";
import {Typography} from "@mui/material";

const NoNewClaims = () => {
  const { mode } = useThemeContext();
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        padding: "0 16px",
      }}
    >
      <img
        src={mode === "dark" ? DarkIcon : LightIcon}
        alt="404 logo"
        style={{ width: "calc((100% / 12) *5)" }}
      />
      <div style={{ display: "block", width: "50%" }}>
        <img
          src={FireIcon}
          alt="fire icon"
          style={{ width: "46px", height: "61px" }}
        />
        <Typography variant="h1">{t("NO_NEW_CLAIMS.TITLE")}</Typography>
        <Typography variant="h2">
          {t("NO_NEW_CLAIMS.TEXT")}
        </Typography>
      </div>
    </div>
  );
};

export default NoNewClaims;
