import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Modal,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { modalBoxStyle, modalCardStyle } from "../../../theme/theme";
import {User} from "../../../models/User";

interface InactivityModalProps {
  user: User | null;
  /**
   * Callback function to log out user from app
   */
  inactiveCallback: () => void;
}

const InactivityModal: React.FC<InactivityModalProps> = ({ user, inactiveCallback }) => {
  // constants
  const MAX_MIN_INACTIVITY: number = 90; // minutes after the claim will be unassigned via logout due to inactivity
  const SHOW_INFO_BEFORE_MIN: number = 30; // minutes before MAX_MIN_INACTIVITY threshold is reached - show info modal
  const INTERVAL_TIME_MS: number = 10000; // check-time interval for inactivity - every 10s
  // storage keys
  const START_TIME = "startTime";
  const END_TIME = "endTime";

  const { t } = useTranslation();

  // state hooks
  const [intervalId, setIntervalId] = useState<any>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [pauseTime, setPauseTime] = useState<string>("");

  // Holds a ref to access the current render state inside the setInterval callback which runs outside the React render loop
  const showModalRef = useRef(showModal);

  // start after login / stop after logout
  useEffect(() => {
    if (user) {
      startActivity();
    } else {
      stopActivity();
    }
    // eslint-disable-next-line
  }, [user]);

  // any click counts as user activity
  useEffect(() => {
    if (intervalId) {
      document.body.addEventListener("click", setActivityTimer);
    } else {
      document.body.removeEventListener("click", setActivityTimer);
    }

    // remove on unmount necessary
    return () => document.body.removeEventListener("click", setActivityTimer);
    // eslint-disable-next-line
  }, [intervalId]);

  // Update the modal ref whenever state changes
  useEffect(() => {
    showModalRef.current = showModal;
  }, [showModal]);

  /**
   * Login callback. Starts interval.
   */
  const startActivity = () => {
    const item = localStorage.getItem(START_TIME);
    // write start time only once to local storage - multiple tabs case
    if (!item) {
      setActivityTimer();
    }
    const id = setInterval(checkPassedTime, INTERVAL_TIME_MS);
    setIntervalId(id);
  };

  /**
   * Logout callback. Clear interval.
   */
  const stopActivity = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
      // used as indicator for logout between tabs
      localStorage.removeItem(START_TIME);
    }
  };

  /**
   * User interaction callback. Resets passed time.
   */
  const setActivityTimer = useCallback(() => {
    const now: number = Date.now();
    // reset - indicator between tabs
    localStorage.setItem(START_TIME, JSON.stringify(now));
    localStorage.removeItem(END_TIME);
    setShowModal(false);
  }, []);

  /**
   * Interval callback
   */
  const checkPassedTime = () => {
    const end = localStorage.getItem(END_TIME);
    const start = localStorage.getItem(START_TIME);

    // case: logout was called in another tab (SSO)
    if (!start) {
      stopActivity();
      window.location.reload();
    } else {
      const diff: number = Date.now() - JSON.parse(start);
      const minutes: number = Math.floor(diff / 1000 / 60);

      // max time reached logout from app
      if (minutes >= MAX_MIN_INACTIVITY && end) {
        // setShowModal(false); // We keep the info visible why logout happened.
        inactiveCallback();

        return;
      }

      // multiple tabs case: activity was performed in other tab
      if (showModalRef.current && !end) {
        setShowModal(false);

        return;
      }

      // show info modal
      if (minutes >= MAX_MIN_INACTIVITY - SHOW_INFO_BEFORE_MIN) {
        // case: end time was calculated in other tab already - just sync end time text
        if (end) {
          setPauseTime(end);
        } else {
          // otherwise: calculate and save end time only once
          const currentDate = new Date();
          currentDate.setMinutes(
            currentDate.getMinutes() + SHOW_INFO_BEFORE_MIN,
            currentDate.getSeconds() + INTERVAL_TIME_MS / 1000,
          );

          localStorage.setItem(END_TIME, currentDate.toLocaleTimeString());
          setPauseTime(currentDate.toLocaleTimeString());
        }

        // show info modal
        setShowModal(true);
      }
    }
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <Box sx={modalBoxStyle}>
        <Card sx={modalCardStyle}>
          <CardHeader
            sx={{ paddingBottom: "8px" }}
            title={
              <Typography variant="h5">
                {t("INACTIVITY_MODAL.TITLE")}
              </Typography>
            }
          ></CardHeader>
          <CardContent>
            <Box sx={{ marginBottom: "24px" }}>
              <Typography variant="bodyMd">
                {t("INACTIVITY_MODAL.SUB_TITLE")}
              </Typography>
              <Typography
                variant="titleLg"
                sx={{ marginTop: "24px", display: "block" }}
              >
                {t("INACTIVITY_MODAL.INFO") + " " + pauseTime}
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "100%", height: "48px", color: "white" }}
              onClick={() => setShowModal(false)}
            >
              {t("INACTIVITY_MODAL.BUTTON")}
            </Button>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

export default InactivityModal;
