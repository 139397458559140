import {NEW_UNIQUE_ROW_ID} from "../../Common";

const handleCreateRow = (setFlattenedData, setEditableRowId, columns, table) => {
    // unique new id is needed
    const newRowId = NEW_UNIQUE_ROW_ID;

    // Create a new row object with default values for all columns
    const newRow = { id: newRowId };

    newRow['is_deletable'] = true;
    columns.forEach(column => {
        // some column type expect booleans - like for toggle switches
        if (column.type === 'Boolean') {
            newRow[column.accessorKey] = false;
        } else {
            newRow[column.accessorKey] = '';
        }
    });

    // Prepend the new row to the flattened data
    setFlattenedData(prevFlattenedData => [newRow, ...prevFlattenedData]);

    // Set the newly created row as the editable row
    setEditableRowId(newRowId);

    // Set the editing row in the table state to indicate that the row is being edited
    table.setEditingRow(newRow);

    // expand the new row
    table.setExpanded({ [newRowId]: true });
};

export default handleCreateRow;
