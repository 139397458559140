import {NEW_UNIQUE_ROW_ID} from "../../Common";

/**
 * Handles the cancellation of a row edit operation - when a user decides to cancel changes to a row.
 */
const handleCancelRow = async (row, initialFlattenedData, setFlattenedData, setEditableRowId, setEditedFields, table) => {
    // Filter out the new row that was not saved
    const filteredData = initialFlattenedData.filter(row => row.id !== NEW_UNIQUE_ROW_ID);

    // Update the table data state to exclude the new row
    setFlattenedData(filteredData);

    // Clear the edited fields state
    setEditedFields(null);

    // Exit creating mode
    await table.setCreatingRow(null);

    // Clear the ID of the currently editable row
    setEditableRowId(null);

    // Collapse the expanded state for the current row
    await table.setExpanded({ [row.id]: false });
};

export default handleCancelRow;
