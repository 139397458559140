export enum STOP_CODES {
    MISSING_POV = 'MISSING_POV',
    MISSING_TRACKING = 'MISSING_TRACKING',
    MISSING_INFO = 'MISSING_INFO',
    MISSING_DONR = 'MISSING_DONR',
    MISSING_IMAGE = 'MISSING_IMAGE',
}

export const SC_LOST_IN_TRANSIT: STOP_CODES[] = [
    STOP_CODES.MISSING_POV,
    STOP_CODES.MISSING_TRACKING,
    STOP_CODES.MISSING_INFO
];

export const SC_DELIVERY_DISPUTE: STOP_CODES[] = [
    STOP_CODES.MISSING_DONR,
    STOP_CODES.MISSING_POV,
    STOP_CODES.MISSING_TRACKING,
    STOP_CODES.MISSING_INFO
];

export const SC_DAMAGED: STOP_CODES[] = [
    STOP_CODES.MISSING_IMAGE,
    STOP_CODES.MISSING_POV,
    STOP_CODES.MISSING_TRACKING,
    STOP_CODES.MISSING_INFO
];
