// Place for common used constants throughout the app

/**
 * Formats UTC string to browsers used locale readable string
 * @param inputDate. UTC string.
 * @param withHour. Display also hour. Default is true
 */
export const formatDate = (inputDate: string, withHour: boolean = true): string => {
  if (!inputDate) {
    return '-';
  }

  const date: Date = new Date(inputDate);
  // respect timezone offset
  const clientTimezoneOffsetInMinutes: number = date.getTimezoneOffset();
  date.setMinutes(date.getMinutes() - clientTimezoneOffsetInMinutes);

  let options: Intl.DateTimeFormatOptions;
  if (withHour) {
    options = {
      day: "numeric",
      month: "short",
      hour: "numeric",
      minute: "numeric",
    };
  } else {
    options = {
      day: "numeric",
      month: "short",
      year: "numeric",
    }
  }

  // use browser locale
  return date.toLocaleDateString(navigator.language, options);
};

/**
 * Formats UTC string to ISO string
 * @param utcDate. UTC string.
 * @param withoutTime. "YYYY-MM-DD" or "YYYY-MM-DDThh:mm". Default is true.
 */
export const formatUtcDateToIsoString = (utcDate: string, withoutTime: boolean = true): string => {
  const date = new Date(utcDate);
  return date.toISOString().slice(0, withoutTime ? 10 : 16);
};

/**
 * Validates if param is a valid email pattern
 * @param email
 */
export const isValidEmail = (email: string): boolean => {
  return EMAIL_REGEX.test(email);
};

/**
 * Regex: Numbers, capital letter, hyphen and whitespace. Max length 10.
 */
export const ZIP_CODE: RegExp = /^[A-Z0-9 -]{0,10}$/;

/**
 * Regex: Numbers, capital letters, hyphen, unlimited length.
 */
export const ALPHA_NUMERIC_HYPHEN: RegExp = /^[A-Z0-9-]+$/;

/**
 * Regex: Numbers, capital letters, unlimited length.
 */
export const ALPHA_NUMERIC: RegExp = /^[A-Z0-9]+$/;

/**
 * Regex: Only numbers with 2 decimal places after "," or "."
 */
export const NUMBERS_WITH_DECIMAL: RegExp = /^\d+([.,]\d{0,2})?$/;

/**
 * Regex: Whole numbers with slashes, hyphens.
 */
export const NUMBERS_WITH_SLASHES_OR_HYPHENS: RegExp = /^\d+([/-]\d+)*$/;
/**
 * Regex: Case sensitive combination of numbers and letters with hyphen, underscore, dot.
 */
export const ALPHANUMERIC_WITH_SPECIAL_CHARS: RegExp = /^[a-zA-Z0-9._-]+$/;

/**
 * Regex: Advanced Email pattern.
 */
export const EMAIL_REGEX: RegExp = /^[^\s@]+@([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]+$/;

/**
 * Hide duration for alerts success
 */
export const AUTO_HIDE_DURATION_SUCCESS: number = 4000;

/**
 * Hide duration for alerts error
 */
export const AUTO_HIDE_DURATION_ERROR: number = 6000;

/**
 * Table component temporary row ID
 */
export const NEW_UNIQUE_ROW_ID: number = 0;

/**
 * Search Mode readonly for Claims
 */
export const SEARCH_TYPE_CLAIM: string = "claims";

/**
 * Search Mode readonly for Mails
 */
export const SEARCH_TYPE_MAILS: string = "mails";
