import React, {useEffect, useState} from 'react';
import {Box, Card, CardContent, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {Attachment} from "../../models/SendMail";
import {danger, neutral, primary} from "../../theme/colors";
import {useThemeContext} from "../../theme/ThemeContextProvider";
import {useTranslation} from "react-i18next";
import {dropzoneStyle} from "../../theme/theme";

interface MailFileUploadProps {
    /**
     * useState callback to parent
     */
    onAttachmentChange: (detailName: string, newValue: Attachment[]) => void;
    /**
     * useState callback to parent
     */
    disableSendMail: Function;
    /**
     * Input data
     */
    attachments: Attachment[];
}

const MailFileUpload: React.FC<MailFileUploadProps> = ({ onAttachmentChange, disableSendMail, attachments }) => {
    const {mode} = useThemeContext();
    const { t } = useTranslation();
    const MAX_UPLOAD_LIMIT: number = 5242880; // in bytes => equals 5MB

    // states
    const [isDragOver, setIsDragOver] = useState(false);

    // Threshold reached check
    useEffect(() => {
        disableSendMail(currentFileSize > MAX_UPLOAD_LIMIT);
        // eslint-disable-next-line
    }, [attachments]);

    const handleDrop = (event) => {
        event.preventDefault();
        handleUploadFiles(event.dataTransfer.files).then();
        setIsDragOver(false);
    };

    const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            handleUploadFiles(e.target.files).then();
        }
    };

    const handleUploadFiles = async (files: FileList) => {
        const base64s: string[] = await convertMultipleFilesToBase64(files);
        const fileList: File[] = Array.from(files);

        const newFiles: Attachment[] = fileList.map((file: File, index: number) => ({
            file_name: file.name,
            size: file.size,
            file_content: base64s[index]
        }));

        // Concatenate new files with previous files and call the callback
        const updatedFiles: Attachment[] = [...attachments, ...newFiles];
        onAttachmentChange('attachments', updatedFiles);
    };

    const calculateFileSize = () : number => {
        let currentFileSize: number = 0;
        attachments.forEach( a => currentFileSize += a.size);

        return currentFileSize;
    }

    /**
     * Convert multiple files async to Base64
     * @param files
     */
    const convertMultipleFilesToBase64 = async (files: FileList) => {
        const promises = Array.from(files).map(convertFileToBase64);
        // note: Promise.all maintains the order of the promises it is given regardless of when they resolve.
        return await Promise.all(promises);
    }

    /**
     * Convert one file async to Base64
     * @param file
     */
    const convertFileToBase64 = (file: File) : Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                // use regex to remove data url part from string
                let encoded = (reader.result?.toString() || '').replace(/^data:(.*,)?/, '');
                // and ensure that "padding characters" is done correctly
                if ((encoded.length % 4) > 0) {
                    encoded += '='.repeat(4 - (encoded.length % 4));
                }

                resolve(encoded);
            }
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = () => {
        setIsDragOver(false);
    };

    const handleDeleteFile = (fileName: string) => {
        const newFileSet: Attachment[] = attachments.filter( a => a.file_name !== fileName );
        // call the callback with an updated file set
        onAttachmentChange('attachments', newFileSet);
    };

    const formatFileSize = (sizeInBytes: number) => {
        const kilobyte = 1024;
        const megabyte = kilobyte * 1024;

        if (sizeInBytes < kilobyte) {
            return sizeInBytes + ' B';
        } else if (sizeInBytes < megabyte) {
            return (sizeInBytes / kilobyte).toFixed(2) + ' KB';
        } else {
            return (sizeInBytes / megabyte).toFixed(2) + ' MB';
        }
    };

    const currentFileSize: number = calculateFileSize();

    return (
        <Card>
            <CardContent>
                <Box
                    onDrop={(event) => handleDrop(event)}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    sx={isDragOver ? { ...dropzoneStyle, backgroundColor: `${mode === "light" ? primary[50] : primary[900]}`} : dropzoneStyle}
                >
                    <input
                        type="file"
                        accept="image/*,video/*,.pdf,.doc,.docx"
                        multiple
                        onChange={handleFileInputChange}
                        style={{ display: "none" }}
                        id="fileInput"
                    />
                    <label htmlFor="fileInput"
                           style={{
                               display: "flex",
                               cursor: "pointer",
                               alignItems: "center",
                               justifyContent: "center",
                               width: "100%",
                               height: "120px", paddingLeft: 2, paddingRight: 2 }}>
                        {t('NEW_MAIL.DRAG_AND_DROP')}<span style={{ fontWeight: "bold", color: "#23A5D4", paddingLeft:"5px"}}>{t('NEW_MAIL.CLICK_TO_ADD')}</span>
                    </label>
                </Box>

                <Box>
                    {attachments.map((file, index) => (
                        <Box sx={{ display: 'flex', alignItems:'center', border: `1px solid ${mode === "light" ? neutral[200] : neutral[600]}`, borderRadius: '8px', p:1, width: '100%', justifyContent: 'space-between', mt: 2 }} key={index}>
                            <Box sx={{ display: 'inline-flex', alignItems:'center' }}>
                                <Box sx={{ display: 'inline-flex', flexDirection: 'column', ml: 1 }}>
                                    <Typography title={file.file_name} variant='titleLg'>{file.file_name}</Typography>
                                    <Typography variant='bodyLg'>{formatFileSize(file.size)}</Typography>
                                </Box>
                            </Box>
                            <IconButton onClick={() => handleDeleteFile(file.file_name)} sx={{ ml: 1 }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    ))}
                </Box>
                <Typography variant='bodySm' sx={{ display: 'block', mt: '10px', color: currentFileSize > MAX_UPLOAD_LIMIT ? danger[600] : (mode === 'light' ? neutral[700] : neutral[200]) }}>
                    {formatFileSize(currentFileSize) +' / ' + formatFileSize(MAX_UPLOAD_LIMIT)}
                </Typography>
            </CardContent>
        </Card>
    );
};



export default MailFileUpload;
