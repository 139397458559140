import {PaletteMode, styled, Switch, SwitchProps} from "@mui/material";
import {typography} from './typography';
import {common, error, neutral, primary, secondary, success, warning} from "./colors";
import React from "react";

// Usage not recognized by IDE but needed for build !
declare module '@mui/material/styles' {
    interface TypographyVariants {
        titleLg: React.CSSProperties;
        titleMd: React.CSSProperties;
        titleSm: React.CSSProperties;
        bodyLg: React.CSSProperties;
        bodyMd: React.CSSProperties;
        bodySm: React.CSSProperties;
        bodyXs: React.CSSProperties;
        buttonLg: React.CSSProperties;
        special: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        titleLg?: React.CSSProperties;
        titleMd?: React.CSSProperties;
        titleSm?: React.CSSProperties;
        bodyLg?: React.CSSProperties;
        bodyMd?: React.CSSProperties;
        bodySm?: React.CSSProperties;
        bodyXs?: React.CSSProperties;
        buttonLg?: React.CSSProperties;
        special?: React.CSSProperties;
    }
}

// Usage not recognized by IDE but needed for build !
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        titleLg: true;
        titleMd: true;
        titleSm: true;
        bodyLg: true;
        bodyMd: true;
        bodySm: true;
        bodyXs: true;
        buttonLg: true;
        special: true;
    }
}

export const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
        mode,
        ...(mode === "light"
            ? {
                // palette values for light mode
                primary: {
                    main: primary["main"]
                },
                secondary: {
                    main: secondary["main"]
                },
                error: {
                    main: error["main"]
                },
                success: {
                    main: success["main"]
                },
                warning: {
                    main: warning["main"]
                },
                text: {
                    //main: neutral['950'],
                    //primary: neutral['950']
                },
                background: {
                    default: neutral['50'],
                    paper: common['white']
                },
            }
            : {
                // palette values for dark mode
                primary: {
                    main: primary["main"]
                },
                secondary: {
                    main: secondary["main"]
                },
                error: {
                    main: error["main"]
                },
                success: {
                    main: success["main"]
                },
                warning: {
                    main: warning["main"]
                },
                background: {
                    default: neutral['950'],
                    paper: neutral['900']
                },
                text: {
                    //main: neutral['050'],
                    //primary: neutral['050']
                },
            }),
    },
    typography,
    shape: {
        borderRadius: 8
    },
    breakpoints: {
        values: {
            xs: 0, // Default to 0
            sm: 768, // 768px - 1023px
            md: 1024, // 1024px - 1680px
            lg: 1680, // 1680px - 1920px
            xl: 1920, // +1920px
        },
    },
});


export const getThemedComponents = (mode: PaletteMode) => ({
    components: {
        ...(mode === 'light'
            ? {
                MuiAppBar: {
                    styleOverrides: {
                        colorPrimary: {
                            backgroundColor: 'white',
                            boxShadow: "none",
                            backgroundImage: "none"
                        }
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        colorPrimary: {
                            borderRadius: 12,
                            elevation: 13
                        },
                        root: {
                            boxShadow: '5.3px 13.3px 18.8px -1.2px rgba(187, 187, 187, 0.29), 2.4px 6.1px 8.6px -0.8px rgba(187, 187, 187, 0.23), 1.1px 2.8px 3.9px -0.4px rgba(187, 187, 187, 0.17), 0.3px 0.8px 1.1px 0px rgba(187, 187, 187, 0.12)', // Adjust the shadow as needed
                        },
                    },
                },
                MuiCardHeader: {
                    styleOverrides: {
                        action: {
                            margin: 0
                        }
                    }
                },
                MuiToolbar: {
                    styleOverrides: {
                        root: {
                            paddingLeft: 0,
                            paddingRight: 0,
                            '@media (min-width: 600px)': {
                                paddingLeft: 0,
                                paddingRight: 0,
                            }

                        },

                    }
                },
                MuiSvgIcon: {
                    styleOverrides: {
                        root: {
                            fill: neutral['500']
                        },
                    },
                },
                MuiTableRow:{
                    styleOverrides: {
                        root: {
                            borderRadius: 12,
                            boxShadow: '5.3px 13.3px 18.8px -1.2px rgba(187, 187, 187, 0.29), 2.4px 6.1px 8.6px -0.8px rgba(187, 187, 187, 0.23), 1.1px 2.8px 3.9px -0.4px rgba(187, 187, 187, 0.17), 0.3px 0.8px 1.1px 0px rgba(187, 187, 187, 0.12)', // Adjust the shadow as needed
                        }
                    }
                },
                MuiTimelineItem: {
                    styleOverrides: {
                        root: {
                            minHeight: '60px'
                        }
                    }
                },
                MuiButton: {
                    styleOverrides: {
                        outlined: {
                            boxShadow: "unset",
                            color: neutral[700],
                            borderColor: neutral[200],
                            backgroundColor: neutral[200],
                            '&:hover': {
                                boxShadow: "unset",
                                color: neutral[700],
                                borderColor: neutral[300],
                                backgroundColor: neutral[300],
                            },
                            '&:disabled': {
                                opacity: "70%",
                                boxShadow: "unset",
                                color: neutral[700],
                                borderColor: neutral[200],
                                backgroundColor: neutral[200]
                            },
                        },
                        contained: {
                            boxShadow: "unset",
                            color: common["white"],
                            borderColor: primary[500],
                            backgroundColor: primary[500],
                            '&:hover': {
                                boxShadow: "unset",
                                color: common["white"],
                                borderColor: primary[700],
                                backgroundColor: primary[700],
                            },
                            '&:disabled': {
                                opacity: "70%",
                                boxShadow: "unset",
                                color: common["white"],
                                borderColor: primary[500],
                                backgroundColor: primary[500]
                            },
                        },
                    }
                }
            }
            : {
                MuiAppBar: {
                    styleOverrides: {
                        colorPrimary: {
                            backgroundColor: 'black',
                            boxShadow: "none",
                            backgroundImage: "none",
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            background: neutral['900'],
                            borderRadius: 12,
                            boxShadow: '5.3px 13.3px 18.8px -1.2px rgba(0, 0, 0, 0.29), 2.4px 6.1px 8.6px -0.8px rgba(0, 0, 0, 0.23), 1.1px 2.8px 3.9px -0.4px rgba(0, 0, 0, 0.17), 0.3px 0.8px 1.1px 0px rgba(0, 0, 0, 0.12)', // Adjust the shadow as needed
                        }
                    },
                },
                MuiCardHeader: {
                    styleOverrides: {
                        action: {
                            margin: 0
                        }
                    }
                },
                MuiToolbar: {
                    styleOverrides: {
                        root: {

                            paddingLeft: 0,
                            paddingRight: 0,
                            '@media (min-width: 600px)': {
                                paddingLeft: 0,
                                paddingRight: 0,
                            }

                        },

                    }
                },
                MuiSvgIcon: {
                    styleOverrides: {
                        root: {
                            fill: neutral['400']
                        },
                    },
                },
                MuiTableRow:{
                    styleOverrides: {
                        root: {
                            background: neutral['900'],
                            borderRadius: 12,
                            boxShadow: '5.3px 13.3px 18.8px -1.2px rgba(0, 0, 0, 0.29), 2.4px 6.1px 8.6px -0.8px rgba(0, 0, 0, 0.23), 1.1px 2.8px 3.9px -0.4px rgba(0, 0, 0, 0.17), 0.3px 0.8px 1.1px 0px rgba(0, 0, 0, 0.12)', // Adjust the shadow as needed
                        }
                    }
                },
                MuiTimelineItem: {
                    styleOverrides: {
                        root: {
                            minHeight: '60px'
                        }
                    }
                },
                MuiButton: {
                    styleOverrides: {
                        outlined: {
                            boxShadow: "unset",
                            color: neutral[200],
                            borderColor: neutral[600],
                            backgroundColor: neutral[600],
                            '&:hover': {
                                boxShadow: "unset",
                                color: neutral[200],
                                borderColor: neutral[700],
                                backgroundColor: neutral[700],
                            },
                            '&:disabled': {
                                opacity: "70%",
                                boxShadow: "unset",
                                color: neutral[200],
                                borderColor: neutral[600],
                                backgroundColor: neutral[600]
                            },
                        },
                        contained: {
                            boxShadow: "unset",
                            color: common["white"],
                            borderColor: primary[600],
                            backgroundColor: primary[600],
                            '&:hover': {
                                boxShadow: "unset",
                                color: common["white"],
                                borderColor: primary[700],
                                backgroundColor: primary[700],
                            },
                            '&:disabled': {
                                opacity: "70%",
                                boxShadow: "unset",
                                color: common["white"],
                                borderColor: primary[600],
                                backgroundColor: primary[600]
                            },
                        },
                    }
                }
            }),
    },
});

export const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? primary[800] : primary[600],
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' :  neutral[600],
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export const keyboardArrowIconStyle = {
    paddingLeft: "5px",
    fill: "#fff",
    verticalAlign: "middle",
    width: "24px",
};

export const modalBoxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
};

export const modalCardStyle = {
    width: "615px",
    p: 1,
    boxShadow: "none",
};

export const dropzoneStyle = {
    border: '2px dashed #23A5D4',
    borderRadius: '8px',
    textAlign: 'center',
    cursor: 'pointer'
};

export const mailListTableWrapperStyle = {
    marginRight: "32px",
    flex: "1",
    maxHeight: "1000px", // match to right side
    height: "100%", // adjusts height of card if table height is smaller
    overflow: "auto", // enable scrolling
}
