import { Button, CircularProgress } from "@mui/material";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CarrierIcon } from '../assets/table/carrier.svg';
import { ReactComponent as ClientIcon } from '../assets/table/client.svg';
import { ReactComponent as ClaimsIcon } from '../assets/table/inbox-in.svg';
import { ReactComponent as MailIcon } from '../assets/table/mail.svg';
import { ReactComponent as TenantIcon } from '../assets/table/tenant.svg';
import { ReactComponent as UserIcon } from '../assets/table/user.svg';
import { ReactComponent as WarehouseIcon } from '../assets/table/warehouse.svg';
import { ReactComponent as ReportIcon } from '../assets/table/report.svg';
import useAuthenticatedFetch from "../auth/authenticated";
import AdminMail from "../components/adminMail/AdminMail";
import { ColumnData, rowData } from "../models/Table";
import Table from "../components/table/ReactMaterialTable";
import { User } from "../models/User";
import { neutral, primary } from "../theme/colors";
import theme from "../theme/MuiTheme";
import AdminReports from "../components/adminReports/AdminReports";

interface TabData {
    label: string;
    endpoint: string;
    id: string;
    icon: any;
    tabOrder: number;
}

interface TabPerRole {
    role: string;
    ids: string[];
}

interface DashboardAdminProps {
    user: User;
}

/**
 * Returns an ordered Tab Data model based on user roles.
 * @param user
 */
const defineViewTabsByUserRoles = (user: User) : TabData[] => {
    // data pool
    const pool: TabData[] = [
        { label: 'Tenants', endpoint: `/api/tenants`, id: 'tenants', icon: <TenantIcon />, tabOrder: 0 }, // for super admin only available
        { label: 'Mail templates', endpoint: `/api/mail_templates`, id: 'mails', icon: <MailIcon />, tabOrder: 1 },
        { label: 'Users', endpoint: `/api/users`, id: 'users', icon: <UserIcon />, tabOrder: 2 },
        { label: 'Carriers', endpoint: `/api/carriers`, id: 'carriers', icon: <CarrierIcon />, tabOrder: 3 },
        { label: 'Clients', endpoint: `/api/clients`, id: 'clients', icon: <ClientIcon />, tabOrder: 4 },
        { label: 'Warehouses', endpoint: `/api/warehouses`, id: 'warehouses', icon: <WarehouseIcon />, tabOrder: 5 },
        { label: 'Claims', endpoint: `/api/claims`, id: 'claims', icon: <ClaimsIcon />, tabOrder: 6 }
    ];

    // UX / BE defined allowed views
    const viewRights: TabPerRole[] = [
        { role: 'superadmin', ids: ['tenants', 'users'] },
        { role: 'admin', ids: ['mails', 'users', 'carriers', 'clients', 'warehouses'] },
        { role: 'operator', ids: ['claims'] },
    ];

    const ids: string[] = [];
    user.roles.forEach( (role: string) => {
        const rights: TabPerRole | undefined = viewRights.find( x => role === x.role);
        if (rights) {
            rights.ids.forEach( y => !ids.includes(y) ? ids.push(y) : null);
        }
    });

    const tabData: TabData[] = [];
    ids.forEach( (x) => {
        const tab: TabData  | undefined = pool.find( p => p.id === x);
        if (tab) {
            tabData.push(tab);
        }
    });

    return tabData.sort((a, b) => a.tabOrder - b.tabOrder);
}

// session storage readonly keys
const SS_KEY: string = "tabId";
const MAILBOX_ID: number = 99;
const REPORTS_ID: number = 100;

const DashboardAdmin: React.FC<DashboardAdminProps> = ({user}) => {
    // model
    const tabData: TabData[] = defineViewTabsByUserRoles(user);

    // case: page reload - load last view / selected tab
    const idx: string | null = sessionStorage.getItem(SS_KEY);
    let lastActiveIdx: number = 0;
    if (idx) {
        lastActiveIdx = JSON.parse(idx);
    }

    // hooks
    const { t } = useTranslation();
    const getAccessHeader = useAuthenticatedFetch();

    // state hooks
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [data, setData] = useState<rowData[]>([]);
    const [columnData, setColumnData] = useState<ColumnData[]>([]);
    const [selectedTab, setSelectedTab] = useState<any>(lastActiveIdx < tabData.length ? lastActiveIdx : false);
    const [fetchError, setFetchError] = useState(false);
    const [showMailsView, setShowMailsView] = useState(lastActiveIdx === MAILBOX_ID);
    const [showReportsView, setReportsView] = useState(lastActiveIdx === REPORTS_ID);

    const getButtonStyles = (isActive: boolean) => ({
        backgroundColor: 'transparent',
        marginLeft: '16px',
        color: isActive
            ? (theme.palette.mode === 'light' ? primary[500] : primary[600])
            : neutral[300],
        borderColor: isActive
            ? (theme.palette.mode === 'light' ? primary[500] : primary[600])
            : neutral[300],
        '&:hover': {
            borderColor: isActive
                ? (theme.palette.mode === 'light' ? primary[700] : primary[700])
                : neutral[200],
            color: isActive
                ? (theme.palette.mode === 'light' ? primary[700] : primary[700])
                : neutral[600],
            backgroundColor: 'transparent',
        },
    });

    useEffect(() => {
        const fetchData = async () => {
            // Mails or Reports view will fetch its own data
            if (selectedTab === false) {
                return;
            }

            try {
                setLoadingSpinner(true);
                setFetchError(false);

                // claims list need other endpoint
                const schemaEndpoint = tabData[selectedTab].id === 'claims' ? `/api/claims/schema`
                    : tabData[selectedTab].endpoint + `/schema`;

                // fetch schema and data for selected tab
                const [dataResponse, columnResponse] = await Promise.all([
                    fetch(tabData[selectedTab].endpoint, {
                        method: 'GET',
                        headers: await getAccessHeader(false),
                    }),
                    fetch(schemaEndpoint, {
                        method: 'GET',
                        headers: await getAccessHeader(false),
                    })
                ]);

                if (!dataResponse.ok || !columnResponse.ok) {
                    console.error('Failed to fetch data or column data.');
                    setFetchError(true);
                }

                const [dataResult, columnResult] = await Promise.all([
                    dataResponse.json(),
                    columnResponse.json()
                ]);

                setData(dataResult);
                setColumnData(columnResult);
                setLoadingSpinner(false);
                
            } catch (error) {
                setFetchError(true);
                setLoadingSpinner(false);
            }
        };

        fetchData().then();
        // eslint-disable-next-line
    }, [selectedTab]);

    const handleMailsView = () => {
        setShowMailsView(true);
        setReportsView(false);
        // unselect the tab
        setSelectedTab(false);
        sessionStorage.setItem(SS_KEY, MAILBOX_ID.toString());
    }

    const handleChange = (event: React.SyntheticEvent<{}>, newValue: number) => {
        setShowMailsView(false);
        setReportsView(false);
        // select the tab
        setSelectedTab(newValue);
        sessionStorage.setItem(SS_KEY, newValue.toString());
    };

    const handleReportsView = () => {
        setShowMailsView(false);
        setReportsView(true);
        // unselect the tab
        setSelectedTab(false);
        sessionStorage.setItem(SS_KEY, REPORTS_ID.toString());
    }

    return (
        <Box sx={{ width: '100%', marginBottom: "96px" }}>
            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="wrapper">
                    <Tabs value={selectedTab} onChange={handleChange} aria-label="tabs" variant="scrollable" scrollButtons allowScrollButtonsMobile selectionFollowsFocus sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        {tabData.map((tab, index) => (
                            <Tab key={index}
                                label={tab.label}
                                icon={tab.icon}
                                iconPosition="start"
                                disabled={loadingSpinner && index !== selectedTab && !fetchError} />
                        ))}
                    </Tabs>
                    { user.isAdmin &&
                        <Box sx={{ display: "inline-flex"}}>
                            <Button
                                sx={getButtonStyles(showReportsView)}
                                variant="outlined"
                                startIcon={<ReportIcon />}
                                onClick={handleReportsView}
                                size="small"
                            >
                                {t('ADMIN.REPORTS.HEADLINE')}
                            </Button>
                            {/*ToDo:Badge will be done, once we have push notifications from server*/}
                            {/*<Badge badgeContent={numUnreadMails} color="primary" max={999}>*/}
                                <Button
                                    sx={getButtonStyles(showMailsView)}
                                    variant="outlined"
                                    onClick={handleMailsView}
                                    startIcon={<MailIcon />}
                                    size="small"
                                >
                                    {t('ADMIN.MAILS.BUTTON_LABEL')}
                                </Button>
                            {/*</Badge>*/}
                        </Box>
                    }
                </Box>

                {
                    loadingSpinner ? (
                        <Box sx={{ height: '100%', width: '100%', mt: '100px', textAlign: 'center' }}>
                            <CircularProgress />
                        </Box>
                    ) :
                        <Box className="wrapper" sx={{ paddingTop: "16px" }}>
                            {fetchError ?
                                (
                                    <Box sx={{ m: 5, textAlign: 'center' }}>{t("ADMIN.NO_DATA")}</Box>
                                )
                                : (
                                    <>
                                        {showMailsView ? <AdminMail /> :
                                            showReportsView ? <AdminReports /> :
                                                <Table initialData={data}
                                                       columnData={columnData}
                                                       endpoint={tabData[selectedTab].endpoint}
                                                       showCreateNewButton={true}
                                                       showExtraActions={tabData[selectedTab].id === 'claims'}
                                                       id={tabData[selectedTab].id}></Table>
                                        }
                                    </>
                                )
                            }
                        </Box>
                }
            </Box>
        </Box>
    );
}

export default DashboardAdmin;
