import {
  Alert,
  Box,
  CircularProgress,
  MenuItem,
  Select,
  Snackbar,
  Typography,
  styled,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AUTO_HIDE_DURATION_ERROR,
  AUTO_HIDE_DURATION_SUCCESS,
} from "../../../Common";
import { ReactComponent as DownloadIcon } from "../../../assets/attachment/download.svg";
import { ReactComponent as PreviewIcon } from "../../../assets/attachment/preview.svg";
import { ReactComponent as CheckCircleIcon } from "../../../assets/check-circle.svg";
import { ReactComponent as CloseIconWhite } from "../../../assets/close-white.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/minus-circle.svg";
import useAuthenticatedFetch from "../../../auth/authenticated";
import { useThemeContext } from "../../../theme/ThemeContextProvider";
import { common, neutral } from "../../../theme/colors";
import { FEEDBACK } from "../../../models/Feedback";
import IconComponent from "../../ui/IconComponent";
import AttachmentPreviewModal from "../modals/AttachmentPreviewModal";
import {ActionMailAttachment} from "../../../models/Action";

// MUI style overrides
export const CardContentFrame = styled(Box)(() => ({
  borderRadius: "8px",
  padding: "8px 16px",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "16px",
  marginRight: "16px",
  width: "320px",
  display: "inline-flex",
  mr: 2,
}));

interface ClaimActionMailAttachmentProps {
  attachment: ActionMailAttachment;
  /**
   * Pass null if the business type is readonly and should not be changeable
   */
  attachmentSchema: any | null;
  /**
   * Displays an error inside the box
   */
  error: boolean;
}

const ClaimActionMailAttachment: React.FC<ClaimActionMailAttachmentProps> = ({ attachment, attachmentSchema, error }) => {
  // context hooks
  const { mode } = useThemeContext();
  const { t } = useTranslation();
  // state hooks
  const [signedUrls, setSignedUrls] = useState({});
  const [previewAttachment, setPreviewAttachment] = useState({
    open: false,
    attachment: null,
    mimeType: null,
    filename: null,
  });
  const [isLoading, setIsLoading] = useState({
    attachment: false,
    businessType: false,
  });
  const [businessType, setBusinessType] = useState(attachment.business_type);
  const [showFeedback, setShowFeedback] = useState<FEEDBACK>(FEEDBACK.NONE);
  const getAccessHeader = useAuthenticatedFetch();

  // attachment functions
  const FileSizeTypography = ({ fileSize }) => (
    <Typography
      variant="bodySm"
      sx={{ color: mode === "light" ? neutral[700] : neutral[200] }}
    >
      {formatFileSize(fileSize)}
    </Typography>
  );

  const AttachmentTypeSelect = styled(Select)(() => ({
    "& .MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input":
      {
        paddingRight: "8px",
        paddingBottom: 0,
        paddingTop: 0,
      },
  }));

  const formatFileSize = (sizeInBytes: number) => {
    const sizeInKB = sizeInBytes / 1024;
    if (sizeInKB < 1024) {
      return `${sizeInKB.toFixed(2)} kB`;
    } else {
      const sizeInMB = sizeInKB / 1024;
      return `${sizeInMB.toFixed(2)} MB`;
    }
  };

  const getSignedUrl = async (attachmentId) => {
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    const urlInfo = signedUrls[attachmentId];

    if (urlInfo && urlInfo.timestamp + urlInfo.validityPeriod > currentTime) {
      return urlInfo.url;
    } else {
      try {
        const response = await fetch(
          `/api/attachments/${attachmentId}/signed-url`,
          {
            method: "GET",
            headers: await getAccessHeader(false),
          },
        );

        const data = await response.json();

        // Store the new signed URL, its validity period and timestamp in the state
        setSignedUrls((prevState) => ({
          ...prevState,
          [attachmentId]: {
            url: data.signedUrl,
            validityPeriod: data.validityPeriod,
            timestamp: data.timestamp,
          },
        }));

        return data.signedUrl;
      } catch (error) {
        console.error("Error:", error);
        setShowFeedback(FEEDBACK.ERROR);
      }
    }
  };

  // callback handlers
  const handleDownload = async (attachmentId) => {
    setIsLoading((prevState) => ({ ...prevState, attachment: true }));
    const signedUrl = await getSignedUrl(attachmentId);
    const link = document.createElement("a");
    link.href = signedUrl;
    link.download = "file";
    link.click();
    setIsLoading((prevState) => ({ ...prevState, attachment: false }));
  };

  const handlePreview = async (attachmentId, mimeType, filename) => {
    setIsLoading((prevState) => ({ ...prevState, attachment: true }));
    const attachmentUrl = await getSignedUrl(attachmentId);

    setPreviewAttachment({
      open: true,
      attachment: attachmentUrl,
      mimeType,
      filename,
    });
    setIsLoading((prevState) => ({ ...prevState, attachment: false }));
  };

  const handleClosePreview = () => {
    setPreviewAttachment({
      open: false,
      attachment: null,
      mimeType: null,
      filename: null,
    });
  };

  const handleFeedbackClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    // ignore clicks that are not on the close icon of the alert
    if (reason === "clickaway") {
      return;
    }

    setShowFeedback(FEEDBACK.NONE);
  };

  const changeBusinessType = async (
    attachmentId: string,
    newBusinessType: any,
  ) => {
    setIsLoading((prevState) => ({ ...prevState, businessType: true }));

    try {
      const response = await fetch(`/api/attachments/${attachmentId}`, {
        method: "PUT",
        headers: await getAccessHeader(),
        body: JSON.stringify({
          business_type: newBusinessType,
        }),
      });

      const result = await response.json();

      if (result.success) {
        setBusinessType(newBusinessType);
        setShowFeedback(FEEDBACK.SUCCESS);
      }
    } catch (error: any) {
      console.error("Error:", error.message);
      setShowFeedback(FEEDBACK.ERROR);
    }

    setIsLoading((prevState) => ({ ...prevState, businessType: false }));
  };

  return (
    <Box>
      <CardContentFrame
        sx={{
          border: `1px solid ${mode === "light" ? neutral[200] : neutral[600]}`,
        }}
      >
        {error ? (
          <Box>{t("GENERAL.ERROR")}</Box>
        ) : (
          <>
            <Box sx={{ display: "inline-flex" }}>
              {!attachmentSchema && (
                <IconComponent
                  iconName={businessType}
                  folderPath="business_types"
                  mode="default"
                  extension="svg"
                />
              )}

              {/* Interactive business type */}
              {attachmentSchema && (
                <AttachmentTypeSelect
                  id="mail_attachment_type"
                  disabled={isLoading.businessType}
                  disableUnderline
                  variant="standard"
                  value={businessType || ""}
                  title={t(
                    "DASHBOARD.ACTIONS.ATTACHMENTS." +
                      businessType.toUpperCase(),
                  )}
                  onChange={(event) =>
                    changeBusinessType(attachment.id, event.target.value)
                  }
                  inputProps={{ IconComponent: () => null }}
                  renderValue={() =>
                    isLoading.businessType ? (
                      <CircularProgress size="24px" />
                    ) : (
                      <IconComponent
                        iconName={businessType}
                        folderPath="business_types"
                        mode="default"
                        extension="svg"
                      />
                    )
                  }
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    PaperProps: {
                      style: {
                        border: `1px solid ${mode === "light" ? neutral[200] : neutral[600]}`,
                        borderRadius: "8px",
                      },
                    },
                    style: {
                      left: "-10px",
                    },
                  }}
                >
                  {attachmentSchema.business_type.constraints.options.map(
                    (businessType: string) => (
                      <MenuItem value={businessType} key={businessType}>
                        <IconComponent
                          style={{ width: "20px" }}
                          iconName={businessType}
                          folderPath="business_types"
                          mode="default"
                          extension="svg"
                        />
                        <Typography
                          sx={{ marginLeft: "14px" }}
                          variant="bodyMd"
                        >
                          {t(
                            "DASHBOARD.ACTIONS.ATTACHMENTS." +
                              businessType.toUpperCase(),
                          )}
                        </Typography>
                      </MenuItem>
                    ),
                  )}
                </AttachmentTypeSelect>
              )}

              <Box
                sx={{ display: "inline-flex", flexDirection: "column", ml: 1 }}
              >
                <Typography
                  title={attachment.filename}
                  variant="titleLg"
                  sx={{
                    display: "block",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "170px",
                  }}
                >
                  {attachment.filename}
                </Typography>
                <FileSizeTypography fileSize={attachment.size} />
              </Box>
            </Box>

            <Box sx={{ display: "inline-flex", alignItems: "center" }}>
              {isLoading.attachment ? (
                <CircularProgress size="24px" />
              ) : (
                <IconButton
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handlePreview(
                      attachment.id,
                      attachment.mimetype,
                      attachment.filename,
                    )
                  }
                >
                  <PreviewIcon style={{ fill: "#9FA6AD" }} />
                </IconButton>
              )}
              <IconButton
                style={{ cursor: "pointer" }}
                onClick={() => handleDownload(attachment.id)}
              >
                <DownloadIcon />
              </IconButton>
            </Box>
            {previewAttachment.open &&
              previewAttachment.attachment &&
              previewAttachment.mimeType &&
              previewAttachment.filename && (
                <AttachmentPreviewModal
                  attachment={previewAttachment.attachment}
                  mimeType={previewAttachment.mimeType}
                  filename={previewAttachment.filename}
                  onClose={handleClosePreview}
                />
              )}
          </>
        )}
      </CardContentFrame>

      {/* Request Feedback */}
      {showFeedback !== FEEDBACK.NONE && (
        <Snackbar
          open={true}
          autoHideDuration={
            showFeedback === FEEDBACK.SUCCESS
              ? AUTO_HIDE_DURATION_SUCCESS
              : AUTO_HIDE_DURATION_ERROR
          }
          onClose={handleFeedbackClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            variant="filled"
            severity={showFeedback === FEEDBACK.SUCCESS ? "success" : "error"}
            icon={
              showFeedback === FEEDBACK.SUCCESS ? (
                <CheckCircleIcon />
              ) : (
                <ErrorIcon />
              )
            }
            sx={{ color: common["white"] }}
            action={
              <IconButton sx={{ pt: "5px" }} onClick={handleFeedbackClose}>
                <CloseIconWhite />
              </IconButton>
            }
          >
            {showFeedback === FEEDBACK.ERROR
              ? t("GENERAL.ERROR")
              : t("GENERAL.SAVED")}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default ClaimActionMailAttachment;
