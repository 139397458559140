import {neutral} from "./colors";

export const primaryFont = '"Roboto", "Helvetica", "Arial", sans-serif';
export const secondaryFont = '"Inter", sans-serif';
export const fontWeightRegular = 400;
export const fontWeightSemiBold = 600;
export const fontWeightBold = 700;
export const fontWeightLight = 300;

// ----------------------------------------------------------------------

export const typography = {
    fontFamily: primaryFont,
    fontSecondaryFamily: secondaryFont,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightLight: 300,
    h1: {
        fontWeight: fontWeightBold,
        lineHeight: 80 / 64,
        fontSize: 62,
        '@media (max-width:1023px)': {
            fontSize: 46,
        },
    },
    h2: {
        fontWeight: fontWeightLight,
        lineHeight: 64 / 48,
        fontSize: 62,
        '@media (max-width:1023px)': {
            fontSize: 46,
        },
    },
    h3: {
        fontWeight: fontWeightLight,
        lineHeight: 1.5,
        fontSize: 34
    },
    h4: {
        fontFamily: secondaryFont,
        fontWeight: fontWeightBold,
        lineHeight: 1.5,
        fontSize: 20
    },
    h5: {
       fontFamily: secondaryFont,
       fontWeight: fontWeightBold,
       lineHeight: 1.55,
       fontSize: 20
    },
    titleLg: {
        fontFamily: secondaryFont,
        fontWeight: fontWeightBold,
        lineHeight: 1.5,
        fontSize: 18,
    },
    titleMd: {
        fontFamily: secondaryFont,
        fontWeight: fontWeightBold,
        lineHeight: 1.5,
        fontSize: 16,
    },
    titleSm: {
        fontFamily: secondaryFont,
        fontWeight: fontWeightBold,
        lineHeight: 1.5,
        fontSize: 14,
    },
    bodyLg: {
        fontFamily: secondaryFont,
        fontWeight: fontWeightRegular,
        lineHeight: 1.5,
        fontSize: 18
    },
    bodyMd: {
        fontFamily: secondaryFont,
        fontWeight: fontWeightRegular,
        lineHeight: 1.5,
        fontSize: 16
    },
    bodySm: {
        fontFamily: secondaryFont,
        fontWeight: fontWeightRegular,
        lineHeight: 1.42,
        fontSize: 14
    },
    bodyXs: {
        fontFamily: secondaryFont,
        fontWeight: fontWeightSemiBold,
        lineHeight: 1,
        fontSize: 12,
        color: neutral[700]
    },
    buttonLg: {
        fontFamily: secondaryFont,
        fontWeight: fontWeightBold,
        lineHeight: 1.5,
        fontSize: 16
    },
    special: {
        fontFamily: primaryFont,
        fontWeight: fontWeightSemiBold,
        lineHeight: 1.5,
        fontSize: 46,
    }
};
