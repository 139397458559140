import { createTheme, Theme } from "@mui/material";
import { deepmerge } from '@mui/utils';
import React, {createContext, FC, PropsWithChildren, useContext, useEffect, useState, useMemo} from 'react';
import { getDesignTokens, getThemedComponents } from "./theme";

type ThemeContextType = {
    mode: "dark" | "light";
    toggleColorMode: () => void;
    theme: Theme;
};

export const ThemeContext = createContext<ThemeContextType>({
    mode: "dark",
    toggleColorMode: () => { },
    theme: createTheme(),
});

export const ThemeContextProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
    const value = useColorTheme();
    return (
        <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
    );
};

export const useThemeContext = () => {
    return useContext(ThemeContext);
};

export const useColorTheme = () => {
    // Dark theme is the default mode on first start
    const [mode, setMode] = useState<'dark' | 'light'>(localStorage.getItem('theme') === 'light' ? 'light' : 'dark');

    useEffect(() => {
        // save mode on changes
        localStorage.setItem('theme', mode);
    }, [mode]);

    const toggleColorMode = () =>
        setMode((prevMode) => (prevMode === "dark" ? "light" : "dark"));

    const modifiedTheme = useMemo(
        () => createTheme(deepmerge(getDesignTokens(mode), getThemedComponents(mode))),
        [mode]
    );

    return {
        theme: modifiedTheme,
        mode,
        toggleColorMode,
    };
};
