import React  from "react";
import {Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {useThemeContext} from "../theme/ThemeContextProvider";
import LightIcon from '../assets/Visual404Light.png';
import DarkIcon from '../assets/Visual404Dark.png';
import AstronautIcon from '../assets/astronaut.png';
import {User} from "../models/User";

interface PageNotFoundProps {
    user: User
}

const PageNotFound: React.FC<PageNotFoundProps> = ({user}) => {
    const navigate = useNavigate();
    const { mode } = useThemeContext();
    const { t } = useTranslation();

    return (
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", height:"inherit" , padding: '0 16px'}}>
            <img src={mode === 'dark' ? DarkIcon : LightIcon} alt='404 logo'
                 style={{width: 'calc((100% / 12) *5)'}}/>
            <div style={{display: "block", width: '50%'}}>
                <img src={AstronautIcon} alt='astronaut icon' style={{width: '46px', height: '61px'}}/>
                <Typography variant="h1">{t("PAGE_NOT_FOUND.TITLE")}</Typography>
                <Typography variant="h2">{t("PAGE_NOT_FOUND.TEXT")}</Typography>

                {user.isOperator &&
                    <Button variant="contained"
                        color="primary"
                        sx={{ mt: '24px', color: 'white' }}
                        onClick={() => navigate('/claim', {replace: true})}>
                        {t("PAGE_NOT_FOUND.BUTTON_OPERATOR")}
                    </Button>
                }

                {user.isAdmin &&
                    <Button variant="contained"
                        color="primary"
                        sx={{ mt: '24px', ml: '24px', color: 'white' }}
                        onClick={() => navigate('/admin', {replace: true})}>
                        {t("PAGE_NOT_FOUND.BUTTON_ADMIN")}
                    </Button>
                }
            </div>
        </div>
    );
}

export default PageNotFound;
