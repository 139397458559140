import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Box,
  Typography,
  CardContent,
  IconButton,
  Card,
  CardHeader,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { secondaryFont } from "../../../theme/typography";
import { neutral } from "../../../theme/colors";
import { useThemeContext } from "../../../theme/ThemeContextProvider";
import { MailType, SelectedMailType } from "../../../models/SendMail";
import useAuthenticatedFetch from "../../../auth/authenticated";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { modalBoxStyle, modalCardStyle } from "../../../theme/theme";
import { styled } from "@mui/material/styles";

interface NewMailModalProps {
  isOpen: boolean;
  /**
   * Callback function to handle modal close
   * @param event - Event object from the close event
   */
  onClose: (event?: any) => void;
  claimId: string;
}

const NewMailModal: React.FC<NewMailModalProps> = ({ isOpen, onClose, claimId }) => {
  const getAccessHeader = useAuthenticatedFetch();
  const { mode } = useThemeContext();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mailData, setMailData] = useState<MailType>({
    mailType: [],
    mailCounterpart: [],
    mailTopic: [],
  });

  const [mailType, setMailType] = useState<SelectedMailType>({
    mailCounterpart: "",
    mailTopic: "",
    mailType: "",
  });

  // styles
  const CustomSelect = styled(Select)(({ value }) => ({
    "&.MuiInputBase-root.MuiInput-underline::before": {
      borderBottom: value === "" ? "1px dashed" : "1px solid",
      borderBottomColor: mode === "light" ? neutral[200] : neutral[600],
    },
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: 1.5,
    fontFamily: secondaryFont,
    color: mode === "light" ? neutral[950] : neutral[50],
  }));

  const labelStyle = {
    display: "block",
    color: mode === "light" ? neutral[700] : neutral[200],
  };

  const textStyle = { color: mode === "light" ? neutral[950] : neutral[50] };

  useEffect(() => {
    const fetchMailSchema = async () => {
      setIsLoading(true);

      try {
        const response = await fetch("/api/mail_templates/schema", {
          method: "GET",
          headers: await getAccessHeader(),
        });

        if (response.ok) {
          response.json().then((result) => {
            const data: MailType = {
              mailTopic: result.mail_topic.constraints.options,
              mailCounterpart: result.mail_counterpart.constraints.options,
              mailType: result.mail_type.constraints.options,
            };

            setMailData(data);
            setIsLoading(false);
          });
        } else {
          console.error("Network response was not ok");
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (isOpen) {
      fetchMailSchema().then();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  /**
   * Dropdown selection callback
   * @param property
   * @param val
   */
  const handleChange = (property: string, val: string) => {
    setMailType((prevState: SelectedMailType) => ({
      ...prevState,
      [property]: val,
    }));
  };

  /**
   *  Reset internal state when modal closes
   */
  const resetAndClose = () => {
    setMailType({ mailType: "", mailTopic: "", mailCounterpart: "" });
    onClose();
  };

  const handleNewEmail = () => {
    resetAndClose();
    window.open(`/claim/newmail?id=${claimId}&counterpart=${mailType.mailCounterpart}&mailType=${mailType.mailType}&topic=${mailType.mailTopic}`, '_blank' );
  };

  /**
   * Helper function to sort the items respecting their translation
   * @param data
   */
  const sortTranslated = (data: string[]) : string[] => {
    return data.sort((a, b) => t("ADMIN.MAIL_TYPE." + a).localeCompare(t("ADMIN.MAIL_TYPE." + b)))
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalBoxStyle}>
        <Card sx={modalCardStyle}>
          <CardHeader
            sx={{ paddingBottom: "8px" }}
            title={
              <Typography variant="h5" sx={textStyle}>
                {t("NEW_MAIL.MODAL.HEADLINE")}
              </Typography>
            }
            action={
              <IconButton onClick={resetAndClose}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent sx={{ paddingTop: 0 }}>
            {isLoading ? (
              <CircularProgress size={25} />
            ) : (
              <>
                <Typography variant="bodyMd" sx={textStyle}>
                  {t("NEW_MAIL.MODAL.DESCRIPTION")}
                </Typography>

                <FormControl variant="standard" fullWidth sx={{ mt: "25px" }}>
                  <InputLabel id="Recipient">
                      <Typography sx={labelStyle}>
                        {t("NEW_MAIL.MODAL.RECIPIENT")}
                      </Typography>
                  </InputLabel>
                  <CustomSelect
                    onChange={(event) =>
                      handleChange("mailCounterpart", event.target.value as string)
                    }
                    value={mailType.mailCounterpart}
                    IconComponent={ArrowDownIcon}
                  >
                    {sortTranslated(mailData.mailCounterpart)
                      .filter((item) => item !== "UNKNOWN")
                      .map((item) => (
                        <MenuItem key={"Recipient_" + item} value={item}>
                          {t("ADMIN.MAIL_TYPE." + item)}
                        </MenuItem>
                      ))}
                  </CustomSelect>
                </FormControl>

                <FormControl variant="standard" fullWidth sx={{ mt: "25px" }}>
                  <InputLabel id="MailType">
                      <Typography sx={labelStyle}>
                        {t("NEW_MAIL.MODAL.MAIL_TYPE")}
                      </Typography>
                  </InputLabel>
                  <CustomSelect
                    onChange={(event) =>
                      handleChange("mailType", event.target.value as string)
                    }
                    value={mailType.mailType}
                    IconComponent={ArrowDownIcon}
                  >
                    {sortTranslated(mailData.mailType).map((item) => (
                      <MenuItem key={"MailType_" + item} value={item}>
                        {t("ADMIN.MAIL_TYPE." + item)}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </FormControl>

                <FormControl variant="standard" fullWidth sx={{ mt: "25px" }}>
                  <InputLabel id="Topic">
                      <Typography sx={labelStyle}>
                        {t("NEW_MAIL.MODAL.TOPIC")}
                      </Typography>
                  </InputLabel>
                  <CustomSelect
                    onChange={(event) =>
                      handleChange("mailTopic", event.target.value as string)
                    }
                    value={mailType.mailTopic}
                    IconComponent={ArrowDownIcon}
                  >
                    {sortTranslated(mailData.mailTopic).map((item) => (
                      <MenuItem key={"MailTopic_" + item} value={item}>
                        {t("ADMIN.MAIL_TYPE." + item)}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </FormControl>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                      width: "267px",
                      height: "48px",
                      color: mode === "light" ? neutral[700] : neutral[200],
                      backgroundColor:
                        mode === "light" ? neutral[100] : neutral[800],
                      border: "none",
                    }}
                    onClick={resetAndClose}
                  >
                    {t("GENERAL.CANCEL")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      width: "267px",
                      height: "48px"
                    }}
                    onClick={handleNewEmail}
                    disabled={
                      mailType.mailTopic === "" ||
                      mailType.mailType === "" ||
                      mailType.mailCounterpart === ""
                    }
                  >
                    {t("NEW_MAIL.MODAL.WRITE_MAIL")}
                  </Button>
                </Box>
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

export default NewMailModal;
