import { Box, Button, Typography } from "@mui/material";
import { MailFromChip } from "../claim/actions/MailFromChip";
import { formatDate } from "../../Common";
import ClaimActionMailHeader from "../claim/actions/ClaimActionMailHeader";
import ClaimActionMailAttachment, {
  CardContentFrame,
} from "../claim/actions/ClaimActionMailAttachment";
import { neutral, primary } from "../../theme/colors";
import { typography } from "../../theme/typography";
import React, { useState } from "react";
import { useThemeContext } from "../../theme/ThemeContextProvider";
import { ReactComponent as PreviewIcon } from "../../assets/attachment/preview.svg";
import { useTranslation } from "react-i18next";
import { ActionMail } from "../../models/Action";

interface AdminMailDetailsContentProps {
    /**
     * Model data
     */
    mailDetails: ActionMail;
    attachmentSchema: any;
}

const AdminMailDetailsContent: React.FC<AdminMailDetailsContentProps> = ({ mailDetails, attachmentSchema }) => {
  const { mode } = useThemeContext();
  const { t } = useTranslation();

  const [showAllAttachments, setShowAllAttachments] = useState(false);

  return (
    <>
      <Box
        sx={{
          marginBottom: "16px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        {/* Left side */}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <MailFromChip
            size="small"
            mode={mode}
            type="CARRIER"
            label={t("ADMIN.MAIL_TYPE." + mailDetails.counterpart)}
          />
          <Typography variant="titleLg">{mailDetails.subject}</Typography>
        </Box>

        {/* Right side */}
        <Typography variant="bodyLg" sx={{ whiteSpace: "nowrap" }}>
          {formatDate(mailDetails.timestamp)}
        </Typography>
      </Box>
      <Box>
        <ClaimActionMailHeader a={mailDetails} />
        {mailDetails?.attachments?.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {showAllAttachments || mailDetails.attachments.length < 7 ? (
              <>
                {mailDetails.attachments.map((attachment, attachmentIndex) => (
                  <ClaimActionMailAttachment
                    attachmentSchema={attachmentSchema}
                    key={attachmentIndex}
                    attachment={attachment}
                    error={false}
                  />
                ))}
              </>
            ) : (
              <>
                {mailDetails.attachments
                  .slice(0, 5)
                  .map((attachment, attachmentIndex) => (
                    <ClaimActionMailAttachment
                      attachmentSchema={attachmentSchema}
                      key={attachmentIndex}
                      attachment={attachment}
                      error={false}
                    />
                  ))}
                <CardContentFrame
                  sx={{
                    border: `1px solid ${mode === "light" ? neutral[200] : neutral[600]}`,
                    height: "66px",
                    padding: 0,
                  }}
                >
                  <Button
                    variant="text"
                    style={{ width: "100%", height: "inherit" }}
                    onClick={() => setShowAllAttachments(true)}
                  >
                    <PreviewIcon
                      style={{
                        verticalAlign: "middle",
                        marginRight: "8px",
                        fill: primary[600],
                      }}
                    />
                    <p
                      style={{
                        ...typography.bodySm,
                        fontWeight: "700",
                        color: primary[500],
                      }}
                    >
                      {t("DASHBOARD.ACTIONS.ATTACHMENTS.SHOW_ALL")}
                    </p>
                  </Button>
                </CardContentFrame>
              </>
            )}
          </Box>
        )}
        <Typography
          variant="bodyLg"
          dangerouslySetInnerHTML={{
            __html: mailDetails.text
          }}
        />
      </Box>
    </>
  );
};

export default AdminMailDetailsContent;
