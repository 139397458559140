import { timelineItemClasses } from "@mui/lab";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Snackbar,
} from "@mui/material";
import theme from "../../theme/MuiTheme";
import ClaimAction from "./actions/ClaimAction";
import React, { useEffect, useState } from "react";
import NewMailModal from "./modals/NewMailModal";
import { useThemeContext } from "../../theme/ThemeContextProvider";
import { common, neutral, primary } from "../../theme/colors";
import { useTranslation } from "react-i18next";
import { typography } from "../../theme/typography";
import { ReactComponent as PlusIcon } from "../../assets/claim/plus.svg";
import { ReactComponent as CheckCircleIcon } from "../../assets/check-circle.svg";
import { ReactComponent as CloseIconWhite } from "../../assets/close-white.svg";
import { ReactComponent as ErrorIcon } from "../../assets/minus-circle.svg";
import useAuthenticatedFetch from "../../auth/authenticated";
import { Action } from "../../models/Action";
import { FEEDBACK } from "../../models/Feedback";
import { AUTO_HIDE_DURATION_ERROR } from "../../Common";
import { User } from "../../models/User";

interface ClaimActionsProps {
  user: User;
  claimId: string;
}

const ClaimActions: React.FC<ClaimActionsProps> = ({ user, claimId }) => {
  const { t } = useTranslation();
  const { mode } = useThemeContext();
  const getAccessHeader = useAuthenticatedFetch();

  // state hooks
  const [openNewMailModal, setNewMailModalOpen] = useState(false);
  const [schemaError, setSchemaError] = useState(false);
  const [attachmentSchema, setAttachmentSchema] = useState();
  const [actionsError, setActionsError] = useState(false);
  const [actions, setActions] = useState<Action[]>([]);
  const [showFeedback, setShowFeedback] = useState<FEEDBACK>(FEEDBACK.NONE);
  const [feedbackSuccessText, setFeedbackSuccessText] = useState<string>(t("GENERAL.SAVED"));

  const styling = {
    color: mode === "light" ? neutral[200] : neutral[600],
  };

  useEffect(() => {
    const fetchActions = async () => {
      const response = await fetch(`/api/claims/${claimId}/actions`, {
        method: "GET",
        headers: await getAccessHeader(false),
      });

      if (!response.ok) {
        console.error("Network response was not ok");
        setActionsError(true);
        setShowFeedback(FEEDBACK.ERROR);
      } else {
        const data = await response.json();
        setActions(data.actions);
      }
    };

    const fetchAttachmentsSchema = async () => {
      const response = await fetch(`/api/attachments/schema`, {
        method: "GET",
        headers: await getAccessHeader(false),
      });

      if (!response.ok) {
        console.error("Network response was not ok");
        setSchemaError(true);
        setShowFeedback(FEEDBACK.ERROR);
      } else {
        const data = await response.json();
        setAttachmentSchema(data);
      }
    };

    fetchActions().then();
    fetchAttachmentsSchema().then();
    // eslint-disable-next-line
  }, []);

  const handleDeleteEmail = async (actionId: string) => {
    const response = await fetch(`/api/actions/${actionId}`, {
      method: "DELETE",
      headers: await getAccessHeader(),
    });

    if (!response.ok) {
      console.error("Network response was not ok");
      setActionsError(true);
      setShowFeedback(FEEDBACK.ERROR);
    } else {
      // remove action from view
      setActions((prevActions) =>
        prevActions.filter((action) => action.id !== actionId),
      );
      triggerFeedback(true, 'DASHBOARD.ACTIONS.DELETE_MAIL_SUCCESS');
    }
  };

  const handleFeedbackClose = (
      event?: React.SyntheticEvent | Event,
      reason?: string,
  ) => {
      // ignore clicks that are not on the close icon of the alert
      if (reason === "clickaway") {
        return;
      }

      setShowFeedback(FEEDBACK.NONE);
  };

  /**
   * Feedback callback for child components
   * @param success - true for success / false for error
   * @param successTextToken - optional success text token. Default is 'GENERAL.SAVED'.
   */
  const triggerFeedback = (success: boolean, successTextToken: string = 'GENERAL.SAVED') => {
    setFeedbackSuccessText(t(successTextToken));
    setShowFeedback(success ? FEEDBACK.SUCCESS : FEEDBACK.ERROR);
  };

  return (
    <Box
      flex={8}
      sx={{
        pt: "24px",
        height: "100vh",
        overflowY: "scroll",
        scrollbarWidth: "none" /* Firefox */,
        "&::-webkit-scrollbar": { display: "none" },
        [theme.breakpoints.down("lg")]: {
          overflow: "visible",
          height: "100%",
        },
      }}
    >
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
          paddingRight: 0,
          paddingTop: 0,
          paddingLeft: 0,
          margin: 0,
        }}
      >
        {/* New Mail */}
        <TimelineItem sx={{ marginBottom: "24px" }}>
          <TimelineSeparator>
            <TimelineDot
              variant="outlined"
              sx={{
                borderColor: styling.color,
                marginTop: 0,
                marginBottom: "8px",
              }}
            />
            <TimelineConnector
              sx={{
                borderLeftStyle: "dashed",
                borderLeftWidth: "2px",
                backgroundColor: "transparent",
                color: styling.color,
              }}
            />
          </TimelineSeparator>
          <TimelineContent
            sx={{
              p: 0,
              paddingLeft: 2,
            }}
          >
            <Card style={{ height: "56px" }}>
              <Button
                style={{ width: "100%", height: "inherit" }}
                onClick={() => setNewMailModalOpen(true)}
              >
                <PlusIcon
                  style={{ verticalAlign: "middle", marginRight: "8px" }}
                />
                <p style={{ ...typography.buttonLg, color: primary[600] }}>
                  {t("DASHBOARD.ACTIONS.NEW_MAIL")}
                </p>
              </Button>
            </Card>
          </TimelineContent>
        </TimelineItem>

        {/* N Mails */}
        {actionsError ? (
          <Box sx={{ pt: 3, m: 5, textAlign: "center" }}>
            {t("GENERAL.ERROR")}
          </Box>
        ) : (
          <>
            {actions.length === 0 && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <CircularProgress />
              </Box>
            )}

            {actions.length > 0 &&
              actions.map((a, index) => {
                return (
                  <React.Fragment key={`item-${index}`}>
                    <TimelineItem
                      key={a.timestamp}
                      sx={{ marginBottom: "24px" }}
                    >
                      <TimelineSeparator>
                        <TimelineDot
                          variant="outlined"
                          sx={{
                            borderColor: styling.color,
                            marginTop: 0,
                          }}
                        />
                        <TimelineConnector
                          sx={{ backgroundColor: styling.color }}
                        />
                      </TimelineSeparator>
                      <TimelineContent
                        sx={{
                          p: 0,
                          paddingLeft: 2,
                        }}
                      >
                        <ClaimAction
                          action={a}
                          key={index}
                          attachmentSchema={attachmentSchema}
                          error={schemaError}
                          isOpen={index === 0}
                          user={user}
                          handleDeleteEmail={handleDeleteEmail}
                          triggerFeedback={triggerFeedback}
                        />
                      </TimelineContent>
                    </TimelineItem>
                  </React.Fragment>
                );
              })}
          </>
        )}
      </Timeline>

      {/* Modals */}
      <NewMailModal
        isOpen={openNewMailModal}
        onClose={() => setNewMailModalOpen(false)}
        claimId={claimId}
      />

      {/* Request Feedback */}
      {showFeedback !== FEEDBACK.NONE && (
        <Snackbar
          open={true}
          autoHideDuration={showFeedback === FEEDBACK.SUCCESS ? 5000 : AUTO_HIDE_DURATION_ERROR}
          onClose={handleFeedbackClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            variant="filled"
            severity={showFeedback === FEEDBACK.SUCCESS ? "success" : "error"}
            icon={showFeedback === FEEDBACK.SUCCESS ? <CheckCircleIcon /> : <ErrorIcon />}
            sx={{ color: common["white"] }}
            action={
              <IconButton sx={{ pt: "5px" }} onClick={handleFeedbackClose}>
                <CloseIconWhite />
              </IconButton>
            }
          >
            {showFeedback === FEEDBACK.ERROR ? t("GENERAL.ERROR") : feedbackSuccessText}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default ClaimActions;
