import { useThemeContext } from "../../theme/ThemeContextProvider";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import { MailFromChip } from "../claim/actions/MailFromChip";
import { formatDate } from "../../Common";
import ClaimActionMailHeader from "../claim/actions/ClaimActionMailHeader";
import ClaimActionMailAttachment from "../claim/actions/ClaimActionMailAttachment";
import React from "react";
import { useTranslation } from "react-i18next";
import { ActionMail } from "../../models/Action";

interface SearchMailContentProps {
    mailDetails: ActionMail;
    isLoadingData: boolean;
}

const SearchMailContent: React.FC<SearchMailContentProps> = ({ mailDetails, isLoadingData }) => {
  const { mode } = useThemeContext();
  const { t } = useTranslation();

  return (
      <Card sx={{ flex: 2,  maxHeight: "1000px", overflow: "scroll" }}>
        <CardContent sx={{ p: "24px" }}>
          {isLoadingData ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: "400px" }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  marginBottom: "16px",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                {/* Left side */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <MailFromChip
                    size="small"
                    mode={mode}
                    type="CARRIER"
                    label={t("ADMIN.MAIL_TYPE." + mailDetails.counterpart)}
                  />
                  <Typography variant="titleLg">
                    {mailDetails.subject}
                  </Typography>
                </Box>

                {/* Right side */}
                <Typography variant="bodyLg" sx={{ whiteSpace: "nowrap" }}>
                  {formatDate(mailDetails.timestamp)}
                </Typography>
              </Box>
              <Box sx={{ wordBreak: "break-all" }}>
                <ClaimActionMailHeader a={mailDetails} />
                {mailDetails.attachments.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {mailDetails.attachments.map(
                      (attachment, attachmentIndex) => (
                        <ClaimActionMailAttachment
                          attachmentSchema={null}
                          key={attachmentIndex}
                          attachment={attachment}
                          error={false}
                        />
                      ),
                    )}
                  </Box>
                )}
                <Typography
                  variant="bodyLg"
                  dangerouslySetInnerHTML={{
                    __html: mailDetails.text
                  }}
                />
              </Box>
            </>
          )}
        </CardContent>
      </Card>
  );
};

export default SearchMailContent;
