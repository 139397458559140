import { neutral, secondary } from "../../theme/colors";
import { Button, styled, Typography } from "@mui/material";
import { typography } from "../../theme/typography";
import { useThemeContext } from "../../theme/ThemeContextProvider";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

const FooterBar = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  height: "72px",
  alignItems: "center",
  "@media (max-width: 1024px)": {
    display: "block",
    paddingRight: 0,
    height: "88px",
  },
}));

const FooterLeft = styled("div")(() => ({
  width: "50%",
  display: "inline-flex",
  verticalAlign: "middle",
  "@media (max-width: 1024px)": {
    width: "100%",
    marginTop: "8px",
    justifyContent: "center",
  },
}));

const FooterRight = styled("div")(() => ({
  width: "50%",
  height: "auto",
  textAlign: "right",
  verticalAlign: "middle",
  "@media (max-width: 1024px)": {
    width: "100%",
    textAlign: "center",
  },
}));

export default function Footer() {
  const { t }: { t: TFunction } = useTranslation();
  const { mode } = useThemeContext();

  const StyledButton = styled(Button)({
    ...typography.titleSm,
    height: "40px",
    padding: "4px 16px 4px 16px",

    color: mode === "light" ? secondary["dark"] : secondary["light"],
    "&:hover": {
      backgroundColor: mode === "light" ? neutral[200] : neutral[800],
    },
    "@media (max-width: 1024px)": {
      padding: "13px 16px 13px 16px",
    },
  });

  return (
    <div
      style={{
        backgroundColor: mode === "light" ? neutral[100] : neutral[900]
      }}
      className="wrapper"
    >
      <FooterBar>
        <FooterLeft>
          <StyledButton onClick={() => window.open('https://www.fiege.com/de/datenschutzerklaerung')}>{t("FOOTER.PRIVACY_POLICY")}</StyledButton>
          <StyledButton onClick={() => window.open('https://www.fiege.com/de/impressum')}>{t("FOOTER.IMPRESSUM")}</StyledButton>
        </FooterLeft>
        <FooterRight>
          <Typography
            style={{
              color: mode === "light" ? secondary["dark"] : secondary["light"],
              ...typography.bodyMd,
            }}
          >
            {'© ' + new Date().getFullYear() + ' ' + t("FOOTER.TENANT")}
          </Typography>
        </FooterRight>
      </FooterBar>
    </div>
  );
}
