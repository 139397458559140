import { Box, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAuthenticatedFetch from "../auth/authenticated";
import ClaimActions from "../components/claim/ClaimActions";
import ClaimDetails from "../components/claim/ClaimDetails";
import ClaimHeader from "../components/claim/ClaimHeader";
import LoadingScreen from "../components/claim/LoadingScreen";
import "../css/App.css";
import NoNewClaims from "./NoNewClaims";
import { Claim } from "../models/Claim";
import { User } from "../models/User";

const StackWrapper = styled(Box)(({ theme }) => ({
  display: "block",

  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

interface DashboardProps {
  user: User;
}

const Dashboard: React.FC<DashboardProps> = ({ user }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const getAccessHeader = useAuthenticatedFetch();

  // state hooks
  const [claimId, setClaimId] = useState<string | null>(null);
  const [claim, setClaim] = useState<Claim | null>(null);
  const [claimSchema, setClaimSchema] = useState<any>();
  const [error, setError] = useState(false);
  const [noMoreClaims, setNoMoreClaims] = useState(false);

  /**
   * Entry via deeplink from Claims List or via queue after login
   */
  useEffect(() => {
    const getClaimId = async () => {
      try {
        if (id) {
          setClaimId(id);
        } else {
          const response = await fetch(`/api/claims/queue`, {
            method: "GET",
            headers: await getAccessHeader(false),
          });

          // case: "No new claim that needs to be processed found in DB"
          if (response.status === 404) {
            setNoMoreClaims(true);
          } else if (!response.ok) {
            setError(true);
            console.error("Network response was not ok");
          } else {
            const data = await response.json();
            setClaimId(data.id);
          }
        }
      } catch (error) {
        console.error("Error fetching claimId:", error);
        setError(true);
      }
    };

    getClaimId().then();
    // eslint-disable-next-line
  }, [id]);

  /**
   * Fetch schema and claim data when claim id is known
   */
  useEffect(() => {
    const fetchClaimData = async () => {
      setError(false);
      setNoMoreClaims(false);
      const url = `/api/claims/${claimId}`;

      fetch(url, {
        method: "GET",
        headers: await getAccessHeader(false),
      })
        .then((response) => {
          if (!response.ok) {
            console.error("Network response was not ok");
            setError(true);
          }
          return response.json();
        })
        .then((data) => {
          data.client.logo = JSON.parse(data.client.logo);
          data.carrier.logo = JSON.parse(data.carrier.logo);
          setClaim(data as Claim);
        })
        .catch((error) => {
          console.error("Error fetching claim data:", error);
          setError(true);
        });
    };

    const fetchClaimSchema = async () => {
      const url = `/api/claims/schema`;

      fetch(url, {
        method: "GET",
        headers: await getAccessHeader(false),
      })
        .then((response) => {
          if (!response.ok) {
            console.error("Network response was not ok");
            setError(true);
          }
          return response.json();
        })
        .then((data) => {
          setClaimSchema(data);
        })
        .catch((error) => {
          console.error("Error fetching claim schema data:", error);
          setError(true);
        });
    };

    if (claimId) {
      fetchClaimSchema().then();
      fetchClaimData().then();
    }
    // eslint-disable-next-line
  }, [claimId]);

  return noMoreClaims ? (
    <NoNewClaims />
  ) : error ? (
    <Box sx={{ m: "auto" }}>{t("GENERAL.ERROR")}</Box>
  ) : claim && claimSchema && claimId ? (
    <Box sx={{ width: "100%", marginBottom: "96px" }}>
      <ClaimHeader
        claim={claim}
        updateClaim={setClaim}
        schema={claimSchema}
        id={claimId}
      />
      <StackWrapper className="wrapper">
        <ClaimDetails
          claim={claim}
          updateClaim={setClaim}
          schema={claimSchema}
        />
        <ClaimActions user={user} claimId={claimId} />
      </StackWrapper>
    </Box>
  ) : (
    <LoadingScreen />
  );
};

export default Dashboard;
