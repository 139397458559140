import {
    Box,
    Button,
    Typography,
    Modal,
    Card,
    CardHeader,
    CardContent,
    IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {danger, neutral} from "../../../theme/colors";
import { useThemeContext } from "../../../theme/ThemeContextProvider";
import CloseIcon from "@mui/icons-material/Close";
import {modalBoxStyle, modalCardStyle} from "../../../theme/theme";
import React from "react";

const buttonStyles = {
    width: "267px",
    height: "48px",
    backgroundColor: danger[700],
    color: "white",
    "&:hover": {
        backgroundColor: danger[800],
    }
};

interface DeleteActionEmailModalProps {
    isOpen: boolean;
    /**
     * Callback function to handle modal close
     * @param event - Event object from the close event
     */
    onClose: (event?: any) => void;
    /**
     * Callback function to handle delete
     */
    handleDeleteEmail: () => void;
}

const DeleteActionEmailModal: React.FC<DeleteActionEmailModalProps> = ({ isOpen, onClose, handleDeleteEmail }) => {
    const { t } = useTranslation();
    const { mode } = useThemeContext();

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box sx={modalBoxStyle}>
                <Card sx={modalCardStyle}>
                    <CardHeader
                        sx={{ paddingBottom: "8px" }}
                        title={
                            <Typography variant="h5">{t("DASHBOARD.ACTIONS.DELETE_ACTION_MODAL.HEADLINE")}</Typography>
                        }
                        action={
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        }
                    ></CardHeader>

                    <CardContent>
                        <Typography variant="bodyLg">
                            {t("DASHBOARD.ACTIONS.DELETE_ACTION_MODAL.DESCRIPTION")}
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: 4,
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{
                                    width: "267px",
                                    height: "48px",
                                    color: mode === "light" ? neutral[700] : neutral[200],
                                }}
                                onClick={onClose}
                            >
                                {t("GENERAL.CANCEL")}
                            </Button>
                            <Button
                                variant="contained"
                                sx={buttonStyles}
                                onClick={handleDeleteEmail}
                            >
                                {t("GENERAL.DELETE")}
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Modal>
    );
};

export default DeleteActionEmailModal;
