import { createRoot } from "react-dom/client";
// import { StrictMode } from "react";

import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ThemeContextProvider } from "./theme/ThemeContextProvider";

import './locales/i18n';
import "./css/index.css";

// MS auth
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './auth/authConfig';

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);
const root = createRoot(document.getElementById('root'));

root.render(
    // <StrictMode>
        <ThemeContextProvider>
            <BrowserRouter>
                <MsalProvider instance={msalInstance}>
                    <App />
                </MsalProvider>
            </BrowserRouter>
        </ThemeContextProvider>
    // </StrictMode>
);
